import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {createBodyOld} from '../createBodyPostAndPatch/createBodyAuction'

import Sidebar from '../../../auctionEditNew/components/sidebar/sidebar';

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    minNumberOfQualifiedBids,
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../../../redux/actions.js';

import {
    changeLoader,
} from '../../../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    bankAccountGet,
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../../../redux/actions.js';

/////////////////////////////////////
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import 'date-fns';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputDate from '../../../../elements/inputs/inputDate';

import WindowAuctionSelfNumber from '../../windowAuctionSelfNumber.js';
import WindowAuctionTitle from '../../windowAuctionTitle.js';
import WindowAuctionDescription from '../../windowAuctionDescription.js';
import WindowAuctionTenderAttempt from '../../windowAuctionTenderAttempt.js';
import WindowAuctionAccessDetails from '../../windowAuctionAccessDetails.js';
import WindowAuctionDocumentRequirements from '../../windowAuctionDocumentRequirements.js';
import WindowAuctionAdditionalInformation from '../../windowAuctionAdditionalInformation.js';
import WindowAuctionType from '../../auctionsCommon/windowAuctionType/windowAuctionType.js';
import WindowAuctionCost from '../../windowAuctionCost.js';

import ValueAddedTaxCharged from '../../valueAddedTaxCharged.js';
import IsPerishable  from '../../isPerishable.js';

import WindowAuctionGaranty from '../../windowAuctionGaranty.js';
import WindowAuctionMinimalStep from '../../windowAuctionMinimalStep.js';
import WindowAuctionDiscount from '../../windowAuctionDiscount.js';

import WindowAuctionDutchSteps from '../../windowAuctionDutchSteps.js';
import MinNumberOfQualifiedBids from '../../minNumberOfQualifiedBids'

import WindowAuctionDgf from '../../dgf.js';
import LandRental from '../../landRental'
import WindowAuctionZU from '../../zu.js';
import WindowAuctionBaseSellBankAccounts from '../../WindowAuctionBaseSellBankAccountsMonga'

import ReturnButton from '../../../returnButtonMonga'

import LegitimatePropertyLeaseLeaseDuration from '../legitimatePropertyLeaseLeaseDuration'
import RailWayCargoDutchMinNumberOfQualifiedBids from '../railWayCargoDutchMinNumberOfQualifiedBids'

import RegistrationFee from '../../windowAuctionRegistrationFee'

import {disabledElement, onOffCurrentTenat} from '../../../disabled'

import LandRentalLeaseType from '../../landRental_leaseType'

import {checkingFilledFields} from '../checkingFilledFields'
import { checkProp } from '../../../../../redux/check';
import procedure from "../../../../../redux/procedure";

const WindowAuction = ( props ) => {
    let tmp = null;
    let tmpBank = null
    let tmpBankSelect = null
    let tmpRegistrationFee = null
    let tmpLandRentalLeaseType = null

    function setBaseUrl(){
        if(window.location.pathname.indexOf('/editNew/') !== -1){
            return window.location.pathname.split('/editNew/');
        }
        return null
    }

    function setPageId(){
        let tmp = setBaseUrl()
        if(tmp !== null){
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`
        //TODO: Нерабочий АПИ 20211018
        props.bankAccountGet(dataBank, header)


        /////////////////////////
        if(props.auctionsMy.hasOwnProperty('auctionPeriod') === true){
            if(props.auctionsMy.auctionPeriod !== null){
                if(props.auctionsMy.auctionPeriod.hasOwnProperty('startDate') === true){
                    if(props.auctionsMy.auctionPeriod.startDate !== null){
                        props.changeCreateAuctionDateStart(props.auctionsMy.auctionPeriod.startDate)
                    }
                }
            }
        }
    }, [])

    if(props.auctionsMy !== null){
        switch (true) {
            case procedure.isLSE(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case procedure.isLRE(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case procedure.isLSP(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case procedure.isBSE(props.auctionsMy.sellingMethod):
            case procedure.isBSD(props.auctionsMy.sellingMethod):
            case procedure.isALE(props.auctionsMy.sellingMethod):
            case procedure.isCSE(props.auctionsMy.sellingMethod):
            case procedure.isCSD(props.auctionsMy.sellingMethod):
            case procedure.isNLE(props.auctionsMy.sellingMethod):
            case procedure.isNLD(props.auctionsMy.sellingMethod):
            case procedure.isBRE(props.auctionsMy.sellingMethod):
            case procedure.isBRD(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                break;
        }
    }

    if(props.auctionsMy !== null){
        if(
            procedure.isBSE(props.auctionsMy.sellingMethod) || procedure.isBSD(props.auctionsMy.sellingMethod) ||
            procedure.isALE(props.auctionsMy.sellingMethod) ||
            procedure.isCSE(props.auctionsMy.sellingMethod) || procedure.isCSD(props.auctionsMy.sellingMethod) ||
            procedure.isBRE(props.auctionsMy.sellingMethod) || procedure.isBRD(props.auctionsMy.sellingMethod)
        ){
            tmpBank = <WindowAuctionBaseSellBankAccounts />
        }
    }

    if(props.auctionsMy !== null){

        if(props.auctionsMy.hasOwnProperty('minimalStep') === true){
            if(props.auctionsMy.minimalStep !== null){
                if(props.auctionsMy.minimalStep.hasOwnProperty("currency") === true){
                    if(props.auctionsMy.minimalStep.currency !== null){
                    }
                }
                if(props.auctionsMy.minimalStep.hasOwnProperty("amount") === true){
                    if(props.auctionsMy.minimalStep.amount !== null){
                    }
                }
            }
        }

        if(props.auctionsMy.hasOwnProperty('bankAccounts') === true){
            if(props.auctionsMy.bankAccounts.length !== null){
                if(props.auctionsMy.bankAccounts.length > 0){
                    props.setPopBankAccountId(props.auctionsMy.bankAccounts[0].id)
                }
            }
        }

        tmp = <div className={styles.mainWrap}>
            <Sidebar />
            <div className={styles.wrapBlock}>
                    {viewIdElements(props)}
                    <WindowAuctionType typeElem={1} />
                    <WindowAuctionSelfNumber typeElem={1} />
                    <WindowAuctionTitle typeElem={1} />
                    <WindowAuctionDescription typeElem={1} />
                    <WindowAuctionTenderAttempt typeElem={1} />
                    <WindowAuctionAccessDetails typeElem={1} />
                    <WindowAuctionDocumentRequirements typeElem={1} />
                    <WindowAuctionAdditionalInformation typeElem={1} />
                    {viewMinNumberOfQualifiedBids(props, 1)}
                    {viewRailWayCargoDutch(props, 1)}
                    <IsPerishable />
                    <div className={styles.costBlock}>
                        <WindowAuctionCost typeElem={1} />
                        <WindowAuctionGaranty typeElem={1} />
                        <WindowAuctionMinimalStep typeElem={1} />
                        {tmpRegistrationFee}
                        {tmpLandRentalLeaseType}
                        <ValueAddedTaxCharged />
                    </div>
                    <WindowAuctionDiscount typeElem={1} />
                        {closeClock(props)}
                    <div className={styles.group}>

                        {viewSubsoil(props)}
                        {viewLegitimatePropertyLease()}
                        {viewWagonSelected(props)}
                        {bankAccountView(props, tmpBank, tmpBankSelect)}
                </div>
                <div className={styles.bottonBlock}>
                    {createOrEdit(props)}
                </div>
                <div className={styles.bottonBlock}>
                    <ReturnButton />
                </div>
                <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
            </div>
        </div>
    }
    return tmp
}

function closeClock(props){
    if(props.auctionsMy.isPerishable === true || props.auctionsMy.isPerishable === 'true'){
        return (
            <div className={styles.startDate}>
            <div  className={styles.costBlockTitle}>Дата аукціону</div>
            {dateStartAuction(props, 'disabled')}
            </div>
        )
    }
    return (
        <div className={styles.startDate}>
            <div className={styles.costBlockTitle}>Дата аукціону</div>
            {dateStartAuction(props, null)}
        </div>
    )
}

function bankAccountView(props, tmpBank, tmpBankSelect){
    if (props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards')) {
        return
    }
    return (
        <>
            <div className={styles.groupElem + ' ' + styles.bankAccountElements}>
                <div className={styles.bankAccountTitle}>Банківські реквізити</div>
                {tmpBank}
            </div>
            <div className={styles.groupElem}>
                <div>
                    {tmpBankSelect}
                    <div className={styles.bottonBlock}>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    //props.changePopUpBankAccount(true)
                                    props.openBankWindows(true)
                                }
                            }
                        >
                            Створити та редагувати банківський рахунок
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

function zuDutchStep(props){
    if (
        props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('railwayCargo-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('basicSell-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('commercialSell-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('bankRuptcy-dutch')
    ) {
        return <WindowAuctionDutchSteps/>
    }
}

function viewMinNumberOfQualifiedBids(props, type){
    if(props.auctionsMy !== null){
        switch(props.auctionsMy.sellingMethod){
            case props.auctionsMy.sellingMethod.startsWith('railwayCargo-dutch'):
                return <>
                    {zuDutchStep(props)}
                </>
            case props.auctionsMy.sellingMethod.startsWith('dgf-english'):
            case props.auctionsMy.sellingMethod.startsWith('dgf-dutch'):
                return <WindowAuctionDgf typeElem={type} />
            case props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-english'):
            case props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-dutch'):
            case props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-priorityEnglish'):
                return <>
                    {zuDutchStep(props)}
                    <WindowAuctionZU typeElem={type} />
                    </>
            case props.auctionsMy.sellingMethod.startsWith('landRental-english'):
                return <LandRental typeElem={type} />
            case props.auctionsMy.sellingMethod.startsWith('landSell-english'):
            case props.auctionsMy.sellingMethod.startsWith('landSell-priorityEnglish'):
                return <LandRental typeElem={type} />
            case props.auctionsMy.sellingMethod.startsWith('basicSell-english'):
            case props.auctionsMy.sellingMethod.startsWith('alienation-english'):
            case props.auctionsMy.sellingMethod.startsWith('commercialSell-english'):
            case props.auctionsMy.sellingMethod.startsWith('bankRuptcy-english'):
                return <MinNumberOfQualifiedBids typeElem={type} />
            case props.auctionsMy.sellingMethod.startsWith('basicSell-dutch'):
            case props.auctionsMy.sellingMethod.startsWith('commercialSell-dutch'):
            case props.auctionsMy.sellingMethod.startsWith('bankRuptcy-dutch'):
                return zuDutchStep(props)
            case props.auctionsMy.sellingMethod.startsWith('arrestedAssets-dutch'):
                return <>
                    <MinNumberOfQualifiedBids typeElem={type} />
                    {zuDutchStep(props)}
                </>
            default:
                return null
        }
    }
}

function viewRailWayCargoDutch(){
    return <RailWayCargoDutchMinNumberOfQualifiedBids />
}

function viewSubsoil(props){
    let tmp = null;
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("subsoil-english"):
        case props.auctionsMy.sellingMethod.startsWith("subsoil-dutch"):
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість геологічної інформації"
                            value={viewGeologivDataValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemGeologivDataValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Вартість геологічної інформації"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість пакета аукціонної документації"
                            value={viewDocumentationValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemDocumentationValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Вартість пакета аукціонної документації, що сплачується переможцем"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    Строк, на який надається дозвіл
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                handleDate(props, event.target.value, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 13){
                                    handleDate(props, event.target.value, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function viewLegitimatePropertyLease(){
    return <LegitimatePropertyLeaseLeaseDuration />
}

function handleViewDate(props, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(tmpArr1[0] !== '0'){
                tmp = tmpArr1[0]
            }else{
                tmp = ''
            }
        }else if(type === 2){
            if(tmpArr2[0] !== '0'){
                tmp = tmpArr2[0]
            }else{
                tmp = ''
            }
        }else if(type === 3){
            if(tmpArr3[0] !== '0'){
                tmp = tmpArr3[0]
            }else{
                tmp = ''
            }
        }
    }else{
        if(type === 1){
            tmp = ``
        }else if(type === 2){
            tmp = ``
        }else if(type === 3){
            tmp = ``
        }
    }
    return tmp
}

function handleDate(props, date, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(date === ''){
                tmp = `P0Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }else{
                tmp = `P${date}Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }
        }else if(type === 2){
            tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y0M${tmpArr3[0]}D`
            }else{
                tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            }
        }
        else if(type === 3){
            tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M0D`
            }else{
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            }
        }
    }else{
        if(type === 1){
            tmp = `P${date}Y0M0D`
        }else if(type === 2){
            tmp = `P0Y${date}M0D`
        }else if(type === 3){
            tmp = `P0Y0M${date}D`
        }

    }
    props.changeCreateItemDateUse(tmp)
}

function viewGeologivDataValue(props){
    let tmp = null;
    if(checkProp(props.auctionsMy, 'geologicDataValue')){
        tmp = props.auctionsMy.geologicDataValue.amount
    }
    return tmp
}

function viewDocumentationValue(props){
    let tmp = null;
    if(props.auctionsMy.documentationValue !== null){
        tmp = props.auctionsMy.documentationValue.amount
    }
    return tmp
}

function viewIdElements(props){
    let tmp = null;
    let tmpIdAuction = null;
    let tmpIdProcedure = null;
    if(props.auctionsMy){
        if(props.auctionsMy !== null){
            if(props.auctionsMy.auctionId){
                tmpIdAuction = <div>ID аукціону Prozorro: <span>{props.auctionsMy.auctionId}</span></div>
            }
            if(props.auctionsMy.procedureId){
                tmpIdProcedure = <div>ID процедури: <span>{props.auctionsMy.procedureId}</span></div>
            }
            tmp = <div>
                {tmpIdAuction}
                {tmpIdProcedure}
            </div>
        }
        tmp = <div style={{margin: '10px 0 20px'}}>
            {tmpIdAuction}
            {tmpIdProcedure}
        </div>
    }
    return tmp
}

function viewWagonSelected(props){
    let tmp = null;
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
            tmp = <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            id="outlined-select-currency-native"
                            select
                            label="Тип аукціону"
                            value={props.auctionsMy.carryType}
                            onChange={(event)=>{
                                props.setCarryType(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати тип аукціону"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {wagonTypeOption(props)}
                        </TextField>
                    </div>
                </div>
            break;
        default:
            break;
    }
    return tmp
}

function wagonTypeOption(props){
    let tmp = null;
    let tmpArr = null;
    if(props.carryType !== null){
        tmpArr = props.carryType.map(
            (i) => {
                //return <option value={i.classificationId}>{i.description}</option>
                return <option value={i.name}>{i.description.uk_UA}</option>
            }
        )
    }
    switch(true) {
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
            tmp = tmpArr
            break;
        default:
            break;
    }
    return tmp
}

function elementDisabledCreate(props){
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            let tmpStartData = null
            let tmpEndData = null
            //for(let tt = 0; tt < props.auctionsMy.auctionPeriods.length; tt++){
                //if(props.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                if(props.auctionsMy.rectificationPeriod !== null){
                    tmpStartData = new Date(props.auctionsMy.rectificationPeriod.startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.rectificationPeriod.endDate).getTime()
                }
            //}
            if(
                (procedure.isBSE(props.auctionsMy.sellingMethod) || procedure.isBSD(props.auctionsMy.sellingMethod) ||
                procedure.isALE(props.auctionsMy.sellingMethod) ||
                procedure.isCSE(props.auctionsMy.sellingMethod) || procedure.isCSD(props.auctionsMy.sellingMethod) ||
                procedure.isBRE(props.auctionsMy.sellingMethod) || procedure.isBRD(props.auctionsMy.sellingMethod)) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                return null
            }else{
                return 'disabled'
            }
        }
    }
    //return tmpStatus
}

function dateStartAuction(props, onOff){
    if(props.auctionsMy.hasOwnProperty('startDate') !== true || props.auctionsMy.startDate === null){
        if(props.auctionsMy.hasOwnProperty('auctionPeriod') === true){
            if(props.auctionsMy.auctionPeriod !== null){
                if(props.auctionsMy.auctionPeriod.hasOwnProperty('startDate') === true){
                    if(props.auctionsMy.auctionPeriod.startDate !== null){
                        props.changeCreateAuctionDateStart(props.auctionsMy.auctionPeriod.startDate)
                    }
                }
            }
        }
    }
    let tmp;
    if(onOff === null){
        if(
            props.auctionsMy.status === null
        ){
            if(props.auctionsMy.procedureId === null){
                tmp = <>
                <InputDate
                    data={{
                        target: props.auctionsMy.startDate,
                        time: true
                    }}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionDateStart(e)
                        }
                    }
                />
            </>
            }else{
                tmp = <>
                <InputDate
                    data={{
                        target: props.auctionsMy.startDate,
                        time: true
                    }}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionDateStart(e)
                        }
                    }
                />
                </>
            }
            if(props.auctionsSwitchWindow === 3){
                tmp = <InputDate
                            data={{
                                target: props.auctionsMy.startDate,
                                time: true
                            }}
                            onChange={
                                (e) => {
                                    props.changeCreateAuctionDateStart(e)
                                }
                            }
                        />
            }
        }else{
            tmp = <InputDate
                        data={{
                            target: props.auctionsMy.startDate,
                            time: true
                        }}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDateStart(e)
                            }
                        }
                    />
        }
    }else{
        tmp = <>
                <InputDate
                    data={{
                        target: props.auctionsMy.startDate,
                        time: true
                    }}
                    onChange={(e) => {
                                    props.changeCreateAuctionDateStart(e)
                    }}
                    disabled='disabled'
                />
                <KeyboardDateTimePicker
                    error = {redBlock(props, props.auctionsMy.startDate)}
                    disabled='disabled'
                    className={styles.dateSearch}
                    value={props.auctionsMy.startDate}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionDateStart(e)
                        }
                    }
                    onError={console.log}
                    minDate={new Date("1900-01-01T00:00")}
                    ampm={false}
                    format="yyyy/MM/dd HH:mm"
                />
            <div>Дата аукціону буде запланована автоматично: через 1 повний робочий день з моменту публікації</div>
        </>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english")||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch")||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
    }
    return null
}

function createOrEdit(props){
    return (
        <Button
            disabled={checkingFilledFields(props, onOffCurrentTenat)}
            variant="contained"
            color="primary"
            onClick={
                () => {
                    if(
                        props.auctionsMy.status === null || props.auctionsMy.status === 'active_rectification'
                    ){
                        props.changeLoader(true)
                        buttonEditHandler(props)
                    }else{
                        if(
                            procedure.isBSE(props.auctionsMy.sellingMethod) || procedure.isBSD(props.auctionsMy.sellingMethod) ||
                            procedure.isALE(props.auctionsMy.sellingMethod) ||
                            procedure.isCSE(props.auctionsMy.sellingMethod) || procedure.isCSD(props.auctionsMy.sellingMethod) ||
                            procedure.isBRE(props.auctionsMy.sellingMethod) || procedure.isBRD(props.auctionsMy.sellingMethod)
                        ){
                            props.changeLoader(true)
                            buttonEditHandler(props)
                        }
                    }
                }
            }
        >
            Зберегти зміни
        </Button>
    )
}

async function buttonEditHandler(props){
    await editAuc(props)
}

async function editAuc(props, ){
    props.changeLoader(true)
    let endPoint = props.auctionsMy.auctionId === null ?
        `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${props.auctionsMy.id}` :
        `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}`

    let answer
    let bodyObj = {
        base: props.auctionsMy,
        bank: props.bankAccountWork,
        bankList: props.bankAccount,
        bankAccountZU: props.bankAccountZU,
        deleteBankGroops: props.deleteBankGroops,
        deleteCurentBank: props.deleteCurentBank,
        discount: props.discount,
    }
    answer = createBodyOld(bodyObj)

    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(answer),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)

    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = null
            if(json.message.hasOwnProperty('documents') === true){
                if(json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"){
                    tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'})
                }
            }
            if(tmp === null){
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        }else{
            let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setUniversalError(tmp)
        }
    }
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}

async function getAucions(props, id){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${id}`;
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                }
            }
        }else{
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
        }
    }
    props.changeLoader(false)
}



 
const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
        deleteBankGroops: state.start.deleteBankGroops,
        deleteCurentBank: state.start.deleteCurentBank
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    minNumberOfQualifiedBids,
    setUniversalError,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    setDataAddress,

    changeLoader,
    openBankWindows,
    bankAccountGet
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)