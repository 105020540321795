import React from 'react';
import style from './popupContractAppruve.module.css';

import {connect} from 'react-redux';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDatePaid,
} from '../../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../../redux/actions/documents/documents.js';

import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputDate from '../../../elements/inputs/inputDate';




import {setDataAddress} from '../../../../redux/createAddress';
import {getUpdateDataAuction} from '../../../product/updateData'
import procedure from '../../../../redux/procedure';
import {getDefaultHeaders} from "../../../_api/bidApi";
import {TranslationsUk} from "../../../../redux/translations.uk";


//Очиска полей при закрытии попапа


const ContractOther = ( props ) => {
    return (
        <>
            <div className={style.wrapMain}>
                <div className={style.wrap}>
                    <div
                        className={style.close}
                        onClick={() => {
                            props.setContractTitle('')
                            props.setContractDescription('')
                            props.setContractContractNumber('')
                            props.setContractDateFrom('')
                            props.setContractDateTill('')
                            props.setContractStartDate('')
                            props.setContractEndDate('')
                            props.setContractCurrency('')
                            props.setContractAmount('')
                            props.setContractDateSigned('')
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeContractApprowePopUpStatus('')
                        }}
                    >✕</div>

                    <div className={style.popup_main_doc}>
                        <div className={style.dataContracts}>
                            <div className={style.dataContractsTitle}>Дані договору</div>
                            {viewDataContract(props)}
                        </div>
                    </div>
                    <div className={style.bottomPart}>
                    </div>
                </div>
            </div>
        </>
    )
}

/////////////////////////////////////////
function viewDataContract(props){
    let tmpButton = null

    switch (true) {
        case(
            procedure.isLLE(props.popUpContractApprowe.sellingMethod) ||
            procedure.isLLD(props.popUpContractApprowe.sellingMethod) ||
            procedure.isLLP(props.popUpContractApprowe.sellingMethod)
        ):
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                props.timberContractPut.contractTime.dateFrom !== '' &&
                props.timberContractPut.contractTime.dateTill !== '' &&
                props.timberContractPut.xValueUAH.amount !== null &&
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case(
            procedure.isBSE(props.popUpContractApprowe.sellingMethod) || procedure.isBSD(props.popUpContractApprowe.sellingMethod) ||
            procedure.isALE(props.popUpContractApprowe.sellingMethod) ||
            procedure.isCSE(props.popUpContractApprowe.sellingMethod) || procedure.isCSD(props.popUpContractApprowe.sellingMethod) ||
            procedure.isBRE(props.popUpContractApprowe.sellingMethod) || procedure.isBRD(props.popUpContractApprowe.sellingMethod) || procedure.isBRW(props.popUpContractApprowe.sellingMethod) ||
            procedure.isRLE(props.popUpContractApprowe.sellingMethod) || procedure.isRLD(props.popUpContractApprowe.sellingMethod) || procedure.isRLP(props.popUpContractApprowe.sellingMethod) ||
            procedure.isCLE(props.popUpContractApprowe.sellingMethod) || procedure.isCLD(props.popUpContractApprowe.sellingMethod) || procedure.isCLP(props.popUpContractApprowe.sellingMethod)
        ):
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                //props.timberContractPut.contractTime.dateFrom !== '' &&
                //props.timberContractPut.contractTime.dateTill !== '' &&
                (props.timberContractPut.xValueUAH.amount !== null || props.timberContractPut.contractTotalValue.amount !== null) &&
                /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case(!procedure.isLSE(props.popUpContractApprowe.sellingMethod) && !procedure.isLSP(props.popUpContractApprowe.sellingMethod)):
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                props.timberContractPut.contractTime.dateFrom !== '' &&
                props.timberContractPut.contractTime.dateTill !== '' &&
                // (props.timberContractPut.xValueUAH.amount !== null || props.timberContractPut.contractTotalValue.amount !== null) &&
                /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case (
            props.timberContractPut.title !== '' &&
            props.timberContractPut.description !== '' &&
            props.timberContractPut.contractNumber !== '' &&
            // props.timberContractPut.xValueUAH.amount !== null &&
            /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
            props.timberContractPut.dateSigned !== ''
        ):
            if (props.auctions.privateData.isOwner !== false) {
                tmpButton = <div
                    className={style.btnDone}
                    onClick={
                        () => {
                            updateContractData(props)
                        }
                    }
                >
                    Зберегти
                </div>
            }
            break
        default:
            break
    }


    let procedureName = props.auctions.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auctions?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }

    let tmpContractTitle = <InputText
                                value={props.timberContractPut.title.hasOwnProperty("uk_UA")
                                    ? props.timberContractPut.title.uk_UA
                                    : props.timberContractPut.title
                                }
                                label={TranslationsUk[props?.auctions?.sellingMethodClear][procedureName]?.contracts?.title?.legalName ?? 'Назва договору'}
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_TITLE')}
                                reqiured={true}
                            />

    let tmpContractDescription = <TextArea
                                    value={props.timberContractPut.description.hasOwnProperty("uk_UA") 
                                        ? props.timberContractPut.description.uk_UA
                                        : props.timberContractPut.description
                                    }
                                    label={TranslationsUk[props?.auctions?.sellingMethodClear][procedureName]?.contracts?.description?.legalName ?? 'Опис договору'}
                                    onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_DESCRIPTION')}
                                    reqiured={true}
                                 />

    let tmpContractNumber = <InputText
                                value={props.timberContractPut.contractNumber}
                                label={TranslationsUk[props?.auctions?.sellingMethodClear][procedureName]?.contracts?.contractNumber?.legalName ?? 'Номер договору'}
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_CONTRACT_NUMBER')}
                                reqiured={true}
                             />
    
    let tmpContractTimeStartDate = null
    let dataContractTimeStartDate = {}
    dataContractTimeStartDate.targetType = 'SET_CONTRACT_START_DATE'
    dataContractTimeStartDate.target = props.timberContractPut.contractTime.dateFrom
    dataContractTimeStartDate.typePopUp = 'contracts'
    dataContractTimeStartDate.functions = props.setContractStartDate
    dataContractTimeStartDate.label = `Строк дії договору Період з`
    if(
        procedure.isBSE(props.popUpContractApprowe.sellingMethod) || procedure.isBSD(props.popUpContractApprowe.sellingMethod) ||
        procedure.isALE(props.popUpContractApprowe.sellingMethod) ||
        procedure.isCSE(props.popUpContractApprowe.sellingMethod) || procedure.isCSD(props.popUpContractApprowe.sellingMethod) ||
        procedure.isBRE(props.popUpContractApprowe.sellingMethod) || procedure.isBRD(props.popUpContractApprowe.sellingMethod) || procedure.isBRW(props.popUpContractApprowe.sellingMethod) ||
        procedure.isRLE(props.popUpContractApprowe.sellingMethod) || procedure.isRLD(props.popUpContractApprowe.sellingMethod) || procedure.isRLP(props.popUpContractApprowe.sellingMethod) ||
        procedure.isCLE(props.popUpContractApprowe.sellingMethod) || procedure.isCLD(props.popUpContractApprowe.sellingMethod) || procedure.isCLP(props.popUpContractApprowe.sellingMethod)
    ){
        dataContractTimeStartDate.necessarily = false
    }else{
        dataContractTimeStartDate.necessarily = true
    }
    dataContractTimeStartDate.time = true
    if(
        !procedure.isLSE(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLSP(props.popUpContractApprowe.sellingMethod)
    ){
        if(props.timberContractPut.contractTime.dateFrom === '' || props.timberContractPut.contractTime.dateFrom === null){
            tmpContractTimeStartDate = <div className={style.groupElem}>
                <InputDate 
                    data={{
                        label: dataContractTimeStartDate.label,
                        target: dataContractTimeStartDate.target,
                        reqiured: dataContractTimeStartDate.necessarily,
                        time: dataContractTimeStartDate.time
                    }}
                    onChange={
                        (e) => {
                            props.setContractStartDate(e)
                        }
                    }
                />
            </div>
        }else{
            let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime()
            if(tmpTimerStart < tmpTimerSigned){
                tmpContractTimeStartDate = <div className={style.groupElem}>
                    <InputDate 
                        data={{
                            label: dataContractTimeStartDate.label,
                            target: dataContractTimeStartDate.target,
                            reqiured: dataContractTimeStartDate.necessarily,
                            time: dataContractTimeStartDate.time
                        }}
                        onChange={(e) => { props.setContractStartDate(e) }
                        }
                     />
                </div>
            }else{
                tmpContractTimeStartDate = <div className={style.groupElem}>
                    <InputDate 
                        data={{
                            label: dataContractTimeStartDate.label,
                            target: dataContractTimeStartDate.target,
                            reqiured: dataContractTimeStartDate.necessarily,
                            time: dataContractTimeStartDate.time
                        }}
                        onChange={(e) => { props.setContractStartDate(e) }
                        }
                    />
                </div>
            }
        }
    }
    let tmpContractTimeEndDate = null
    let dataContractTimeEndDate = {}
    dataContractTimeEndDate.targetType = 'SET_CONTRACT_END_DATE'
    dataContractTimeEndDate.target = props.timberContractPut.contractTime.dateTill
    dataContractTimeEndDate.typePopUp = 'contracts'
    dataContractTimeEndDate.functions = props.setContractEndDate
    dataContractTimeEndDate.label = `Строк дії договору Період по`
    if(
        procedure.isBSE(props.popUpContractApprowe.sellingMethod) || procedure.isBSD(props.popUpContractApprowe.sellingMethod) ||
        procedure.isALE(props.popUpContractApprowe.sellingMethod) ||
        procedure.isCSE(props.popUpContractApprowe.sellingMethod) || procedure.isCSD(props.popUpContractApprowe.sellingMethod) ||
        procedure.isBRE(props.popUpContractApprowe.sellingMethod) || procedure.isBRD(props.popUpContractApprowe.sellingMethod) || procedure.isBRW(props.popUpContractApprowe.sellingMethod) ||
        procedure.isRLE(props.popUpContractApprowe.sellingMethod) || procedure.isRLD(props.popUpContractApprowe.sellingMethod) || procedure.isRLP(props.popUpContractApprowe.sellingMethod) ||
        procedure.isCLE(props.popUpContractApprowe.sellingMethod) || procedure.isCLD(props.popUpContractApprowe.sellingMethod) || procedure.isCLP(props.popUpContractApprowe.sellingMethod)
    ){
        dataContractTimeEndDate.necessarily = false
    }else{
        dataContractTimeEndDate.necessarily = true
    }
    dataContractTimeEndDate.time = true
    if(
        !procedure.isLSE(props.popUpContractApprowe.sellingMethod) && !procedure.isLSP(props.popUpContractApprowe.sellingMethod)
    ){
        if(props.timberContractPut.contractTime.dateTill === '' || props.timberContractPut.contractTime.dateTill === null){
            tmpContractTimeEndDate = <div className={style.groupElem}>
                <InputDate 
                    data={{
                        target: props.timberContractPut.contractTime.dateTill,
                        label: dataContractTimeEndDate.label,
                        reqiured: dataContractTimeEndDate.necessarily,
                        time: dataContractTimeEndDate.time
                    }}
                    onChange={(e) => { props.setContractEndDate(e) }
                    }
                 />
            </div>
        }else{
            let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime()
            if(tmpTimerStart > tmpTimerEnd){
                tmpContractTimeEndDate = <div className={style.groupElem}>
                    <InputDate
                        data={{
                            target: props.timberContractPut.contractTime.dateTill,
                            label: dataContractTimeEndDate.label,
                            reqiured: dataContractTimeEndDate.necessarily,
                            time: dataContractTimeEndDate.time
                        }}
                        onChange={(e) => { props.setContractEndDate(e) }
                        }
                    />
                </div>
            }else{
                tmpContractTimeEndDate = <div className={style.groupElem}>
                    <InputDate
                        data={{
                            target: props.timberContractPut.contractTime.dateTill,
                            label: dataContractTimeEndDate.label,
                            reqiured: dataContractTimeEndDate.necessarily,
                            time: dataContractTimeEndDate.time
                        }}
                        onChange={(e) => { props.setContractEndDate(e) }
                        } 
                    />
                </div>
            }
        }
    }
    let tmpContractTimeDateFrom = null
    let dataContractTimeFromDate = {
        target: props.timberContractPut.contractTime.dateFrom,
        label: `Дата передплати`,
        reqiured: true,
        time: true
    }
    if(
        !procedure.isLLE(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLLD(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLLP(props.popUpContractApprowe.sellingMethod)
    ){
        if(props.timberContractPut.contractTime.dateFrom === '' || props.timberContractPut.contractTime.dateFrom === null){
            tmpContractTimeDateFrom = <div className={style.groupElem}>
                <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
            </div>
        }else{
            let tmpTimerFrom = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime()
            let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime()
            if(tmpTimerFrom < tmpTimerSigned || tmpTimerEnd < tmpTimerFrom){
                tmpContractTimeDateFrom = <div className={style.dateTill}>
                    <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
                </div>
            }else{
                tmpContractTimeDateFrom = <div className={style.groupElem}>
                    <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
                </div>
            }
        }
    }
    let tmpContractTimeDateSigned
    let dataContractTimeSignedDate = {
        target: props.timberContractPut.dateSigned,
        label: `Дата підписання договору`,
        reqiured: true,
        time: true
    }
    if(props.timberContractPut.dateSigned === '' || props.timberContractPut.dateSigned === null){
        tmpContractTimeDateSigned = <div className={style.groupElem}>
            <InputDate data={dataContractTimeSignedDate} onChange={(e) => { props.setContractDateSigned(e) }} />
        </div>
    }else{
        tmpContractTimeDateSigned = <div className={style.groupElem}>
            <InputDate data={dataContractTimeSignedDate} onChange={(e) => { props.setContractDateSigned(e) }} />
        </div>
    }
    let tmpData = null
    if(props.auctions !== null){
        if(props.auctions.value !== null){
            if(props.auctions.value.valueAddedTaxIncluded){
                tmpData = 'Загальна вартість договору в грн., з ПДВ'
            }else{
                tmpData = 'Загальна вартість договору в грн., без ПДВ'
            }
        }
        if(
            procedure.isRLE(props.auctions.sellingMethod) || procedure.isRLD(props.auctions.sellingMethod) || procedure.isRLP(props.auctions.sellingMethod) ||
            procedure.isCLE(props.auctions.sellingMethod) || procedure.isCLD(props.auctions.sellingMethod) || procedure.isCLP(props.auctions.sellingMethod) ||
            procedure.isLSP(props.auctions.sellingMethod) || procedure.isLSE(props.auctions.sellingMethod) || procedure.isLRE(props.auctions.sellingMethod)
        ) {
            tmpData = TranslationsUk[props?.auctions?.sellingMethodClear][procedureName]?.contracts?.contractTotalValue?.legalName ??
                'Заключна вартість договору з урахуванням податків'
        }
        if (
            procedure.isBSE(props.auctions.sellingMethod)
            || procedure.isLLE(props.auctions.sellingMethod)
        ) {
            tmpData = 'Заключна вартість договору'
        }
    }

    let dataContractSumm = {
        target: props.timberContractPut.xValueUAH.amount,
        label: tmpData,
        reqiured: true,
    }
    if(
        procedure.isBSE(props.auctions.sellingMethod) ||
        procedure.isRLE(props.auctions.sellingMethod) || procedure.isRLD(props.auctions.sellingMethod) || procedure.isRLP(props.auctions.sellingMethod) ||
        procedure.isCLE(props.auctions.sellingMethod) || procedure.isCLD(props.auctions.sellingMethod) || procedure.isCLP(props.auctions.sellingMethod) ||
        procedure.isLSP(props.auctions.sellingMethod) || procedure.isLSE(props.auctions.sellingMethod) || procedure.isLRE(props.auctions.sellingMethod)
        || procedure.isLLE(props.auctions.sellingMethod)
    ){
        dataContractSumm = {
            target: props.timberContractPut.contractTotalValue.amount,
            label: tmpData,
            reqiured: true,
        }
    }

    let tmpDogovorSumm = <InputText
                                value={dataContractSumm.target}
                                label={dataContractSumm.label}
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_TOTAL_AMOUNT')}
                                reqiured={dataContractSumm.reqiured}
                             />
    
    let tmpDataValue = null
    if(props.auctions !== null){
        if(props.auctions.value !== null){
            if(props.auctions.value.valueAddedTaxIncluded){
                tmpDataValue = 'Вартість договору в грн., з ПДВ'
            }else{
                tmpDataValue = 'Вартість договору в грн., без ПДВ'
            }
        }
        if(
            procedure.isRLE(props.auctions.sellingMethod) || procedure.isRLD(props.auctions.sellingMethod) || procedure.isRLP(props.auctions.sellingMethod) ||
            procedure.isCLE(props.auctions.sellingMethod) || procedure.isCLD(props.auctions.sellingMethod) || procedure.isCLP(props.auctions.sellingMethod) ||
            procedure.isLRE(props.auctions.sellingMethod) || procedure.isLSP(props.auctions.sellingMethod) || procedure.isLSE(props.auctions.sellingMethod)
        ){
            tmpDataValue = 'Вартість договору'
        }
    }
    let dataContractValue = {
        targetType: 'SET_CONTRACT_VALUE_AMOUNT',
        target: props.timberContractPut.value.amount,
        functions: props.setContractAmount,
        label: tmpDataValue,
        reqiured: false,
        disabledElem: true
    }
    let tmpDogovorValue = <InputText 
                                value={dataContractValue.target}
                                label={dataContractValue.label}
                                onChange={(e)=> props.setDataAddress(e, dataContractValue.targetType)}
                                reqiured={dataContractValue.reqiured}
                                disabled={dataContractValue.disabledElem}
                            />
    //let tmpDogovorValue = null

    let tmpForm
    switch (true) {
        case(
            !procedure.isLSE(props.popUpContractApprowe.sellingMethod) &&
            !procedure.isLSP(props.popUpContractApprowe.sellingMethod)
        ):
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpContractNumber}
                            {tmpDogovorValue}
                            {tmpDogovorSumm}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpContractTimeDateSigned}
                            {tmpContractTimeStartDate}
                            {tmpContractTimeEndDate}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>

            break
        case procedure.isLSE(props.popUpContractApprowe.sellingMethod) :
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractNumber}
                            {tmpContractTimeDateSigned}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeStartDate}
                            {tmpContractTimeEndDate}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
            break

        case procedure.isLSP(props.popUpContractApprowe.sellingMethod) :
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractNumber}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeDateSigned}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
            break

        default:
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractTimeDateFrom}
                            {tmpContractNumber}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeDateSigned}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
    }
    return tmpForm
}


function handleSubmitDataContract(e, props){
    e.preventDefault();
    updateContractData(props)
}

async function updateContractData(props){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`
    let body = {}
    body.title = props.timberContractPut.title
    body.description = props.timberContractPut.description
    body.contractNumber = props.timberContractPut.contractNumber
    if(
        !procedure.isTIE(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLRE(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLSE(props.popUpContractApprowe.sellingMethod) && !procedure.isLSP(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isLLE(props.popUpContractApprowe.sellingMethod) && !procedure.isLLD(props.popUpContractApprowe.sellingMethod) && !procedure.isLLP(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isBSE(props.popUpContractApprowe.sellingMethod) && !procedure.isBSD(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isALE(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isCSE(props.popUpContractApprowe.sellingMethod) && !procedure.isCSD(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isBRE(props.popUpContractApprowe.sellingMethod) && !procedure.isBRD(props.popUpContractApprowe.sellingMethod) && !procedure.isBRW(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isRLE(props.popUpContractApprowe.sellingMethod) && !procedure.isRLP(props.popUpContractApprowe.sellingMethod) && !procedure.isRLD(props.popUpContractApprowe.sellingMethod) &&
        !procedure.isCLE(props.popUpContractApprowe.sellingMethod) && !procedure.isCLP(props.popUpContractApprowe.sellingMethod) && !procedure.isCLD(props.popUpContractApprowe.sellingMethod)
    ){
        body.x_prepaymentDetailsDate = props.timberContractPut.contractTime.dateFrom
        console.log(props?.timberContractPut?.xValueUAH)
        if(props?.timberContractPut?.xValueUAH?.amount?.indexOf(',') !== -1){
            props.timberContractPut.xValueUAH.amount.replace(',', '.')
        }
        body.x_prepaymentDetailsValue = {
            'currency': 'UAH',
            'amount': Number(props.timberContractPut.xValueUAH.stramount)
        }
    }else{
        if(
            procedure.isTIE(props.popUpContractApprowe.sellingMethod) || //Правка для запуска леса если ок убрать 03022022
            procedure.isBSD(props.popUpContractApprowe.sellingMethod)
            || procedure.isALE(props.popUpContractApprowe.sellingMethod) ||
            procedure.isCSE(props.popUpContractApprowe.sellingMethod) || procedure.isCSD(props.popUpContractApprowe.sellingMethod) ||
            procedure.isBRE(props.popUpContractApprowe.sellingMethod) || procedure.isBRD(props.popUpContractApprowe.sellingMethod) || procedure.isBRW(props.popUpContractApprowe.sellingMethod) ||
            procedure.isRLE(props.popUpContractApprowe.sellingMethod) || procedure.isRLD(props.popUpContractApprowe.sellingMethod) ||
            procedure.isCLE(props.popUpContractApprowe.sellingMethod) || procedure.isCLD(props.popUpContractApprowe.sellingMethod)
        ){
            let stramount
            if(
                props.timberContractPut.xValueUAH !== null &&
                props.timberContractPut.xValueUAH.hasOwnProperty('amount') &&
                props.timberContractPut.xValueUAH.amount !== null
            ){
                if(String(props.timberContractPut.xValueUAH.amount).indexOf(',') !== -1){
                    stramount = String(props.timberContractPut.xValueUAH.amount).replace(',', '.')
                }else{
                    stramount = props.timberContractPut.xValueUAH.amount
                }
            }else{
                stramount = props.timberContractPut.xValueUAH.amount
            }
            body.contractTotalValue = {
                'currency': 'UAH',
                'amount': Number(stramount),
                /*'valueAddedTaxIncluded': true*/
            }
        }else{
            let stramount
            if(String(props.timberContractPut.contractTotalValue.amount).indexOf(',') !== -1){
                stramount = String(props.timberContractPut.contractTotalValue.amount).replace(',', '.')
            }else{
                stramount = props.timberContractPut.contractTotalValue.amount
            }
            body.contractTotalValue = {
                'currency': 'UAH',
                'amount': Number(stramount)
            }
        }
    }
    if(
        props.popUpContractApprowe.sellingMethod !== 'landSell-english' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast-auction-manual-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-auction' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-auction-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-qualification-prod' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast-auction-manual-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-auction' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-auction-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(
            props.timberContractPut.contractTime.dateFrom !== '' ||
            props.timberContractPut.contractTime.dateFrom !== null ||
            props.timberContractPut.contractTime.dateTill !== '' ||
            props.timberContractPut.contractTime.dateTill !== null
        ){
            if(props.timberContractPut.contractTime.dateFrom !== '' && props.timberContractPut.contractTime.dateFrom !== null){
                body.contractTime = {}
                body.contractTime.dateFrom = props.timberContractPut.contractTime.dateFrom
            }
            if(props.timberContractPut.contractTime.dateTill !== '' && props.timberContractPut.contractTime.dateTill !== null){
                if(body.hasOwnProperty('contractTime' !== true)) body.contractTime = {}
                body.contractTime.dateTill = props.timberContractPut.contractTime.dateTill
            }
        }
    }
    body.dateSigned =  props.timberContractPut.dateSigned
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: getDefaultHeaders(props)

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            if(json.message.hasOwnProperty('x_prepaymentDetails') === true){
                if(json.message.x_prepaymentDetails.hasOwnProperty('date') === true){
                    if(json.message.x_prepaymentDetails.date === 'Value should be greater than auctionPeriod.endDate and less than now'){
                        tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: 'Дата контракту не правильно виставлена'})
                    }
                }
            }
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //Добавить очистку полей//
            props.setContractTitle('')
            props.setContractDescription('')
            props.setContractContractNumber('')
            props.setContractDateFrom('')
            props.setContractDateTill('')
            props.setContractStartDate('')
            props.setContractEndDate('')
            props.setContractCurrency(null)
            props.setContractAmount(null)
            props.setContractDateSigned('')
            props.changeContractApprowePopUpStatus(null)

            await getUpdateDataAuction(props, props.auctions.id)
            await props.changeLoader(false)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,
        popUpContractUniversal: state.start.popUpContractUniversal,
        timberContractPut: state.start.timberContractPut,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,

    setUniversalError,
    changeContractApprowePopUpStatus,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,

    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    
    contractsAuctionIdGet,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeLoader,

    setContractDatePaid,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractOther)
