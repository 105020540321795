import React, {useEffect} from 'react';
import style from './paymentPopUp.module.css';

import {connect} from 'react-redux';

import {paymentPopup1, paymentPopupCreatePdf, paymentPopupSetBank, paymentPopupGetData} from '../../../redux/paymentPopup.js';
import PopUpLogin from '../login/login';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {signImg} from './sign'
import {numberToString, textMonth} from "../../_helpers/helpers";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PaymentPopUp = (props) => {

    useEffect( () => {
        if(props.token !== null){
            getIsFinAuth(props)
        }
    }, [])

    let tmp
    if(props.paymentPopupCreatePDF === false){
        tmp = viewDataBlock(props)
    }else{
        tmp = viewPdfBlock(props)
    }
    //if(isSPD(props.auction.sellingMethod)) return null
    if(props.token !== null && props.userProfiles !== null){
        ///if(props.userProfiles.isFinAuth === true) return <Redirect to='/' />
        if(props.userProfiles.organization.identifier.scheme === "UA-IPN") {
            return (
                <div className={style.popup_wrap}>
                    <div className={style.popup_message}>
                        <div className={style.balance}>
                            <h2>Пройти фінансову ідентифікацію</h2>
                            <div>Для авторизації кабінету Вам необхідно направити на пошту info@dto.com.ua копії паспорту та ІПН</div>
                        </div>
                        <a href="/">
                            <div className={style.popup_message_button}>
                                На головну
                            </div>
                        </a>
                    </div>
                </div>
            )
        }
        return (
            <div className={style.popup_wrap}>
                <div className={style.popup_message}>
                    <div
                        className={style.popUpExit}
                        onClick={
                            ()=>{
                                props.paymentPopup1(null)
                                props.paymentPopupCreatePdf(false)
                                props.paymentPopupSetBank('null')
                            }
                        }
                    >
                        X
                    </div>
                    {startPdf(props)}
                    {tmp}
                </div>
            </div>
        )
    }else{
        return <PopUpLogin />
    }
}

async function getIsFinAuth(props){
    let endPoint
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/finAuth`;

    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true && json.hasOwnProperty('bill') === false){
            let tmp = JSON.stringify({title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = []
            tmpArr[0] = json
            props.paymentPopupGetData(tmpArr)
        }
    }
}

function startPdf(props){
    if(props.paymentPopupGetDataV !== null){
        return (
            <div 
                className={style.popUpTitle}
                onClick={
                    () => {
                        props.paymentPopupCreatePdf(true)
                    }
                }
            >
                Фінансова ідентифікація
            </div>
        )
    }else{
        return (
            <div 
                className={style.popUpTitle}
            >
                Фінансова ідентифікація
            </div>
        )
    }
}

function viewDataBlock(props){
    let tmp = 12
    return (
        <>
            <div className={style.inputWrap}>
                <div>Сума поповнення</div>
                <div>
                    <input 
                        type='text'
                        disabled
                        id='fixSumm'
                        value = {Number(tmp).toFixed(2)}
                    />
                </div>
            </div>
            <div className={style.inputWrap}>
                <div>Обрати банк</div>
                <div>
                    {inputBank(props)}
                </div>
            </div>
            <div
                className={style.popup_message_button}
                onClick={
                    ()=>{
                        if(props.paymentPopupGetDataV !== null){
                            // if(
                            //     // Number(props.paymentPopupSetBanks) === 1 ||
                            //     // Number(props.paymentPopupSetBanks) === 2 ||
                            //     // Number(props.paymentPopupSetBanks) === 3 ||
                            //     // Number(props.paymentPopupSetBanks) === 4
                            // ){
                                props.paymentPopupCreatePdf(true)
                            // }
                        }
                    }
                } 
            >
                Отримати рахунок
            </div>
            <a href="/">
                <div className={style.popup_message_button}>
                    На головну
                </div>
            </a>
        </>
    )
}

function inputBank(props){
    props.paymentPopupSetBank('1')
    return (
        <select
            disabled
            value={props.paymentPopupSetBanks}
            onChange={
                (e) => {
                    props.paymentPopupSetBank(e.target.value)
                }
            }
        >
            <option key={0} value='1'>ПАТ КБ "ПРИВАТБАНК</option>
        </select>
    )
}

function toCalcSumm(props, data){
    if(data !== null){
        return data?.amount
    }else{
        return ''
    }
}

function viewPdfBlock(props){
    return (
        <>
            <div>Для Вас згенеровано документ на суму {toCalcSumm(props, props?.paymentPopupGetDataV[0])} грн. ({props?.paymentPopupGetDataV[0]?.number})</div>
            <div
                className={style.popup_message_button}
                onClick={
                    () => {
                        pdfMake.createPdf(getFistElemJournalPDF(props, props?.paymentPopupGetDataV[0]?.number)).download('name.pdf')
                    }
                }
            >
                Завантажити
            </div>
            <a href="/">
                <div className={style.popup_message_button}>
                    На головну
                </div>
            </a>
        </>
    )
}

function getFistElemJournalPDF(props, data){
    let tmp = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        content: [
            endJournal(props, data)
        ]
    }
    return tmp
}

function bankText(props){
    if(Number(props.paymentPopupSetBanks) === 1){
        return 'Банк: п/р UA443052990000026007006219493 в ПАТ КБ «ПРИВАТБАНК»'
    }else if(Number(props.paymentPopupSetBanks) === 2){
        return 'Банк: п/р UA693803770000026007100660571 в АТ «УКРБУДІНВЕСТБАНК»'
    }else if(Number(props.paymentPopupSetBanks) === 3){
        return 'Банк: п/р UA593348510000000002600226265 в ПАТ «ПУМБ»'
    }else if(Number(props.paymentPopupSetBanks) === 4){
        return 'Банк: п/р UA703001190000026007112431002 в АТ "БАНК АЛЬЯНС"'
    }
}

function dateText(){
    let tmp = new Date();
    return 'від «' + tmp.getDate() + '» ' + textMonth(tmp.getMonth()) + ' ' + tmp.getFullYear() + ' р.'
}

function dateTextEnd(){
    let tmp = new Date();
    let tmpNew = Date.now(tmp) + ((((1000 * 60) * 60) * 24) * 10)
    let tmpEndDate = new Date(tmpNew)
    let day = tmpEndDate.getDate()
    let month = tmpEndDate.getMonth() + 1
    if(Number(day) < 10) day = '0' + day
    if(Number(month) < 10) month = '0' + month
    return day + '.' + month + '.' + tmpEndDate.getFullYear() + ' р.'
}

///////////////////////////////////////////////
//Страница
///////////////////////////////////////////////
function endJournal(props, data){
    let tmpDogovor
    let tmpDogovorDodatok
    if(data !== null){
        tmpDogovor = 'Додаток № ' + props.paymentPopupGetDataV[0].number;
        tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ' + props.paymentPopupGetDataV[0].number;
    }else{
        tmpDogovor = 'Додаток № ';
        tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ';
    }

    let tmpTypeDoc = `Оплата реєстрації згідно з додатком № ${props.paymentPopupGetDataV[0].number}`
    let tmpHelpDoc = ''
    let tmpHelpDoc2 = ''
    let tmpHelpDoc3 = `Увага!!! В призначенні платежу обов'язково необхідно вказати номер додатку: ${props.paymentPopupGetDataV[0].number}`

    let tmpbillNumber
    tmpbillNumber = props.paymentPopupGetDataV[0].number
   
    let tmp = [
        {
            text: tmpDogovor,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpDogovorDodatok,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },


        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Отримувач платежу'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ТОВ "Держзакупівлі.Онлайн"'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ЄДРПОУ: 39008321'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Київ, вул. Воздвиженська, 56, 04071'
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: bankText(props)
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    //text: 'Платник Єдиного податку 3-ї групи в обсязі 2% із звільненням від обов\'язку нарахування та сплати ПДВ та інших платежів.'
                    text: 'Платник податку на прибуток підприємств на загальних підставах.'
                },
            ]
        },

        /*{
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Платник ПДВ. ІПН: 390083226562'
                },
            ]
        },*/
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ' '
                },
                {
                    width: '*',
                    fontSize:9,
                    text: ' '
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Учасник'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: props.userProfiles.organization.name
                },
            ]
        },
        
        {
            text: 'Рахунок-фактура № ' +  tmpbillNumber,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },

        {
            table: {
                width: [50, 350, 50, 100, 100],
                border:0,
                body: [
                    [
                        '№',
                        {
                            alignment:'center',
                            text: 'Назва'
                        },
                        'Кількість',
                        'Ціна без ПДВ',
                        'Сума без ПДВ'
                    ],
                    //['1', tmpTypeDoc, '1', toCalcSummDown(props, data), toCalcSummDown(props, data)]
                    ['1', tmpTypeDoc, '1', '10.00', '10.00']
                ]
            }
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Разом без ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    //text: toCalcSummDown(props, data)
                    text: '10.00'
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    //text: '0.00'
                    text: '2.00'
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Всього:'
                },
                {
                    width: '*',
                    alignment:'right',
                    //text: toCalcSummDown(props, data)
                    text: '12.00'
                },
            ]
        },
        {
            text: numberToString(toCalcSummDown(props, data)),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: 'Рахунок дійсний до сплати до ' + dateTextEnd(),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            columns: [
                {
                    width: 215,
                    text: 'Директор ТОВ "Держзакупівлі.Онлайн"',
                    margin:[5, 50, 5, 5],
                },
                {
                    image: signImg(),
                    width: 200,
                    heighy: 150,
                },
                {
                    width: '*',
                    text: 'В.В.Кольга',
                    margin:[5, 50, 5, 5],
                },
            ]
        },
        {
            text: tmpHelpDoc,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc2,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc3,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
    ];
    return tmp
}

function toCalcSummDown(props, data){
    let tmpAmount = 0
    if(data !== null){
        tmpAmount = props.paymentPopupGetDataV[0].amount
        if(String(tmpAmount).indexOf('.') === true){
            return tmpAmount
        }else if(String(tmpAmount).indexOf(',') === true){
            return Number(tmpAmount).toFixed(2)
        }else{
            return Number(tmpAmount).toFixed(2)
        }
    }else{
        return ''
    }
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        paymentPopup: state.start.paymentPopup,
        paymentPopupCreatePDF: state.start.paymentPopupCreatePDF,
        paymentPopupGetDataV: state.start.paymentPopupGetData,
        paymentPopupSetBanks: state.start.paymentPopupSetBanks,
        userProfiles: state.start.userProfiles,
        token: state.start.token
    }
}

const mapDispatchToProps = {
    paymentPopup1,
    paymentPopupCreatePdf,
    paymentPopupSetBank,
    paymentPopupGetData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopUp)