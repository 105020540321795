import {createBodyLRE} from './createBodyLRE'
import {createBodyLSE} from './createBodyLSE'
import {createBodyLSP} from './createBodyLSP'

import {createBodyLLE} from './createBodyLLE'
import {createBodyLLD} from './createBodyLLD'
import {createBodyLLP} from './createBodyLLP'

import {createBodyBSE} from './createBodyBSE'
import {createBodyBSD} from './createBodyBSD'
import {createBodyALE} from './createBodyALE'
import {createBodyCSE} from './createBodyCSE'
import {createBodyCSD} from './createBodyCSD'
import {createBodyBRE} from './createBodyBRE'
import {createBodyBRD} from './createBodyBRD'
import {createBodyBRW} from './createBodyBRW'

import {createBodyRLE} from './createBodyRLE'
import {createBodyRLD} from './createBodyRLD'

import {createBodySPE} from './createBodySPE'
import {createBodySPD} from './createBodySPD'

import {createBodyNLE} from './createBodyNLE'
import {createBodyNLD} from './createBodyNLD'

import {createBodyLAE} from './createBodyLAE'
import {createBodyLAW} from './createBodyLAW'
import {createBodyLAP, createBodyLAPCT} from './createBodyLAP'

import {createBodyLPE} from './createBodyLPE'
import {createBodyAPE} from './createBodyAPE'
import {createBodyAPD} from './createBodyAPD'

import {createBodyRMA} from "../../../createEditAndCloneAuction/createBodyPostAndPatch/createBodyRMA";
import {createBodyRLP} from "./createBodyRLP";
import procedure from "../../../../../../redux/procedure";

export function createBody(data, oldData = null, auction){
    switch(true){
        case procedure.isLRE(data.base.sellingMethod):
            return createBodyLRE(data)
        case procedure.isLSE(data.base.sellingMethod):
            return createBodyLSE(data)
        case procedure.isLSP(data.base.sellingMethod):
            return createBodyLSP(data)
        case procedure.isLLE(data.base.sellingMethod):
            return createBodyLLE(data)
        case procedure.isLLD(data.base.sellingMethod):
            return createBodyLLD(data)
        case procedure.isLLP(data.base.sellingMethod):
            return createBodyLLP(data)
        case procedure.isBSE(data.base.sellingMethod):
            return createBodyBSE(data)
        case procedure.isBSD(data.base.sellingMethod):
            return createBodyBSD(data)
        case procedure.isALE(data.base.sellingMethod):
            return createBodyALE(data)
        case procedure.isCSE(data.base.sellingMethod):
            return createBodyCSE(data)
        case procedure.isCSD(data.base.sellingMethod):
            return createBodyCSD(data)
        case procedure.isBRE(data.base.sellingMethod):
            return createBodyBRE(data)
        case procedure.isBRW(data.base.sellingMethod):
            return createBodyBRW(data, oldData)
        case procedure.isBRD(data.base.sellingMethod):
            return createBodyBRD(data, oldData)
        case procedure.isRLE(data.base.sellingMethod):
        case procedure.isCLE(data.base.sellingMethod):
            return createBodyRLE(data)
        case procedure.isRLP(data.base.sellingMethod):
        case procedure.isCLP(data.base.sellingMethod):
            return createBodyRLP(data, oldData)
        case procedure.isRLD(data.base.sellingMethod):
        case procedure.isCLD(data.base.sellingMethod):
            return createBodyRLD(data)
        case procedure.isSPE(data.base.sellingMethod):
            return createBodySPE(data, oldData)
        case procedure.isSPD(data.base.sellingMethod):
            return createBodySPD(data, oldData)
        case procedure.isNLE(data.base.sellingMethod):
            return createBodyNLE(data)
        case procedure.isNLD(data.base.sellingMethod):
            return createBodyNLD(data)
        case procedure.isLAE(data.base.sellingMethod):
            return createBodyLAE(data)
        case procedure.isLAW(data.base.sellingMethod):
            return createBodyLAW(data)
        case procedure.isLAP(data.base.sellingMethod):
            return createBodyLAP(data)
        case procedure.isLPE(data.base.sellingMethod):
            return createBodyLPE(data)
        case procedure.isAPE(data.base.sellingMethod):
            return createBodyAPE(data, auction)
        case procedure.isAPD(data.base.sellingMethod):
            return createBodyAPD(data, auction)
        case procedure.isREM(data.base.sellingMethod):
            return createBodyRMA(data)
        default:
            return data.body
    }
}
export function createBodyCT(data, oldData = null){
    switch(true){
        case procedure.isLRE(data.base.sellingMethod):
            return createBodyLRE(data)
        case procedure.isLLE(data.base.sellingMethod):
            return createBodyLLE(data)
        case procedure.isLLD(data.base.sellingMethod):
            return createBodyLLD(data)
        case procedure.isLLP(data.base.sellingMethod):
            return createBodyLLP(data)
        case procedure.isBSE(data.base.sellingMethod):
            return createBodyBSE(data)
        case procedure.isBSD(data.base.sellingMethod):
            return createBodyBSD(data)
        case procedure.isALE(data.base.sellingMethod):
            return createBodyALE(data)
        case procedure.isCSE(data.base.sellingMethod):
            return createBodyCSE(data)
        case procedure.isCSD(data.base.sellingMethod):
            return createBodyCSD(data)
        case procedure.isBRE(data.base.sellingMethod):
            return createBodyBRE(data)
        case procedure.isBRW(data.base.sellingMethod):
            return createBodyBRW(data, oldData)
        case procedure.isBRD(data.base.sellingMethod):
            return createBodyBRD(data, oldData)
        case procedure.isRLE(data.base.sellingMethod):
        case procedure.isCLE(data.base.sellingMethod):
            return createBodyRLE(data)
        case procedure.isRLD(data.base.sellingMethod):
        case procedure.isCLD(data.base.sellingMethod):
            return createBodyRLD(data)
        case procedure.isSPE(data.base.sellingMethod):
            return createBodySPE(data, oldData)
        case procedure.isSPD(data.base.sellingMethod):
            return createBodySPD(data, oldData)
        case procedure.isNLE(data.base.sellingMethod):
            return createBodyNLE(data)
        case procedure.isNLD(data.base.sellingMethod):
            return createBodyNLD(data)
        case procedure.isLAE(data.base.sellingMethod):
            return createBodyLAE(data)
        case procedure.isLAW(data.base.sellingMethod):
            return createBodyLAW(data)
        case procedure.isLAP(data.base.sellingMethod):
            return createBodyLAPCT(data)
        case procedure.isLPE(data.base.sellingMethod):
            return createBodyLPE(data)
        case procedure.isAPE(data.base.sellingMethod):
            return createBodyAPE(data)
        case procedure.isAPD(data.base.sellingMethod):
            return createBodyAPD(data)
        default:
            return data.body
    }
}