import React from 'react';

import {connect} from 'react-redux';

import ZU from './../../../../../product/lotInformation/zu'
import Timber from './../../../../../product/lotInformation/timber'
import Subsoil from './../../../../../product/lotInformation/subsoil'
import Railway from './../../../../../product/lotInformation/railway'
import Dgf from './../../../../../product/lotInformation/dgf'
import LandRental from './../../../../../product/lotInformation/landRental'
import LSP from './../../../../../product/lotInformation/LSP.js'
import BasicSell from './../../../../../product/lotInformation/basicSell'
import Alienation from './../../../../../product/lotInformation/alienation'
import CommercialSell from './../../../../../product/lotInformation/commercialSell'
import BRE from './../../../../../product/lotInformation/bre'
import Rlecle from './../../../../../product/lotInformation/rlecle'
import Rldcld from './../../../../../product/lotInformation/rldcld'
import SPE from './../../../../../product/lotInformation/spe'
import NLE from './../../../../../product/lotInformation/nlenld'
import NLD from './../../../../../product/lotInformation/nld'
import LAE from './../../../../../product/lotInformation/lae'
import LAW from './../../../../../product/lotInformation/law'
import LAP from './../../../../../product/lotInformation/lap'

import procedure from "../../../../../../redux/procedure";
import DefaulAuctionInfo from "./defaulAuctionInfo";


const AuctionInformationBlock = (props) => {
    switch (true) {
        case procedure.isTIE(props.auctionId.sellingMethod):
            return <Timber/>//@deprecated @todo rewrite
        case procedure.isSUE(props.auctionId.sellingMethod):
        case procedure.isSUD(props.auctionId.sellingMethod):
            return <Subsoil/>//@deprecated @todo rewrite
        case procedure.isRCE(props.auctionId.sellingMethod):
        case procedure.isRCD(props.auctionId.sellingMethod):
            return <Railway/>//@deprecated @todo rewrite
        case procedure.isGFE(props.auctionId.sellingMethod):
        case procedure.isGFD(props.auctionId.sellingMethod):
            return <Dgf/>//@deprecated @todo rewrite
        case procedure.isLLE(props.auctionId.sellingMethod):
        case procedure.isLLD(props.auctionId.sellingMethod):
        case procedure.isLLP(props.auctionId.sellingMethod):
            return <ZU/>//@deprecated @todo rewrite
        case procedure.isLRE(props.auctionId.sellingMethod):
        case procedure.isLSE(props.auctionId.sellingMethod):
            return <LandRental/>//@deprecated @todo rewrite
        case procedure.isLSP(props.auctionId.sellingMethod):
            return <LSP/>//@deprecated @todo rewrite
        case procedure.isBSE(props.auctionId.sellingMethod):
        case procedure.isBSD(props.auctionId.sellingMethod):
            return <BasicSell/>//@deprecated @todo rewrite
        case procedure.isALE(props.auctionId.sellingMethod):
            return <Alienation/>//@deprecated @todo rewrite
        case procedure.isCSE(props.auctionId.sellingMethod):
        case procedure.isCSD(props.auctionId.sellingMethod):
            return <CommercialSell/>//@deprecated @todo rewrite
        case procedure.isBRE(props.auctionId.sellingMethod):
        case procedure.isBRD(props.auctionId.sellingMethod):
        case procedure.isBRW(props.auctionId.sellingMethod):
            return <BRE/>//@deprecated @todo rewrite
        case procedure.isRLE(props.auctionId.sellingMethod):
        case procedure.isRLP(props.auctionId.sellingMethod):
            return <Rlecle/>//@deprecated @todo rewrite
        case procedure.isRLD(props.auctionId.sellingMethod):
            return <Rldcld/>//@deprecated @todo rewrite
        case procedure.isCLE(props.auctionId.sellingMethod):
        case procedure.isCLP(props.auctionId.sellingMethod):
            return <Rlecle/>//@deprecated @todo rewrite
        case procedure.isCLD(props.auctionId.sellingMethod):
            return <Rldcld/>//@deprecated @todo rewrite
        case procedure.isSPE(props.auctionId.sellingMethod):
        case procedure.isSPD(props.auctionId.sellingMethod):
            return <SPE/>//@deprecated @todo rewrite
        case procedure.isNLE(props.auctionId.sellingMethod):
            return <NLE/>//@deprecated @todo rewrite
        case procedure.isNLD(props.auctionId.sellingMethod):
            return <NLD/>//@deprecated @todo rewrite
        case procedure.isLAE(props.auctionId.sellingMethod):
            return <LAE/>//@deprecated @todo rewrite
        case procedure.isLAW(props.auctionId.sellingMethod):
            return <LAW/>
        case procedure.isLAP(props.auctionId.sellingMethod):
            return <LAP/>//@deprecated @todo rewrite
        case procedure.isLPE(props.auctionId.sellingMethod):
            return <SPE/>//@deprecated @todo rewrite
        // case procedure.isAPE(props.auctionId.sellingMethod):
        // case procedure.isAPD(props.auctionId.sellingMethod):
        //     return <APE/> //@deprecated @todo rewrite
        case procedure.isAAE(props.auctionId.sellingMethod):
            return null
        default:
            return <DefaulAuctionInfo/> //@deprecated @todo rewrite

    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(AuctionInformationBlock)
