import React,{useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import InputDate from '../../../../../elements/inputs/inputDate';

import LocationOrg from '../../../../elems/localization/locationOrg';

const RelatedOrganizations = ( {data} ) => {
    const [address, setAddress] = useState(data?.currentTenants[data.i]?.address)
    const [dateFrom] = useState(data?.currentTenants[data.i]?.currentContractTime?.dateFrom ?? null)
    const [dateTill] = useState(data?.currentTenants[data.i]?.currentContractTime?.dateTill ?? null)

    useEffect(() => {
        let tmp = [...data.currentTenants]
        tmp[data.count].address = address
        data.setCurrentTenants(tmp)

        if (data?.editFlagSwitch) {
            data.editFlagSwitch(true)
        }
    }, [address])

    let disabled = data?.disabled ?? true

    return (
        <div className={styles.costBlock}>
            <Input //Повна юридична назва організації або ПІБ
                label='Повна юридична назва організації або ПІБ'
                value={data?.currentTenants[data.i]?.identifier?.legalName?.uk_UA}
                disabled={true}
            />
            <div className={styles.orgBlock}>
                <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                <Input //Ідентифікатори організації
                    label='Назва українською'
                    value={data?.currentTenants[data.i]?.identifier?.legalName?.uk_UA}
                    onChange={(e)=>{ 
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier = tmp[data.count]?.identifier ?? {}
                        tmp[data.count].identifier.legalName = tmp[data.count]?.identifier?.legalName ?? {}
                        tmp[data.count].identifier.legalName.uk_UA = e
                        data.setCurrentTenants(tmp)
                    }}
                    disabled={disabled}
                />
                <Select 
                    data={{
                        label: 'Тип ідентифікації юридичної особи',
                        target: data?.currentTenants[data.i]?.identifier?.scheme
                    }}
                    onChange={(e) => { 
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier = tmp[data.count]?.identifier ?? {}
                        tmp[data.count].identifier.scheme = e
                        data.setCurrentTenants(tmp)
                    }}
                    options={[
                        {name: 'Обрати', value: null},
                        {name: 'ЄДРПОУ', value: 'UA-EDR'},
                        {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                        {name: 'Паспорт', value: 'UA-PASSPORT'},
                        {name: 'ID-карта', value: 'UA-ID-CARD'},
                        {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                    ]}
                    disabled={disabled}
                />
                <Input
                    label='Код ЄДРПОУ або ІПН або паспорт'
                    value={data?.currentTenants[data.i]?.identifier?.id}
                    onChange={(e)=>{
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier = tmp[data.count]?.identifier ?? {}
                        tmp[data.count].identifier.id = e
                        data.setCurrentTenants(tmp)
                    }}
                    disabled={disabled}
                />
            </div>
            <LocationOrg 
                address={address}
                setAddress={setAddress}
                dataText={'Адреса'}
                disabled={disabled}
            />
            <Input
                label='Інформація щодо підтвердження повноважень'
                value={data?.currentTenants[data.i]?.representativeInfo}
                onChange={(e)=>{ 
                    let tmp = [...data.currentTenants]
                    tmp[data.count].representativeInfo = e
                    data.setCurrentTenants(tmp)
                }}
                disabled={disabled}
            />
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>Дата укладення та закінчення договору оренди</div>
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Період з</div>
                    <InputDate
                        data={{
                            target: dateFrom,
                            time: true
                        }}
                        onChange={(e)=>{ 
                            let tmp = [...data.currentTenants]
                            tmp[data.count].currentContractTime = {
                                dateFrom: e,
                                dateTill: tmp[data.count]?.currentContractTime?.dateTill ? tmp[data.count]?.currentContractTime?.dateTill : null
                            }
                            data.setCurrentTenants(tmp)
                        }}
                    />
                </div>
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Період по</div>
                    <InputDate
                        data={{
                            target: dateTill,
                            time: true
                        }}
                        onChange={(e)=>{ 
                            let tmp = [...data.currentTenants]
                            tmp[data.count].currentContractTime = {
                                dateFrom: tmp[data.count]?.currentContractTime?.dateFrom ? tmp[data.count]?.currentContractTime?.dateFrom : null,
                                dateTill: e
                            }
                            data.setCurrentTenants(tmp)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RelatedOrganizations