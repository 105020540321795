import React from 'react';
import styles from './rle_rld.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchCount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {changeMyCurentPage} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import {getDefaultHeaders} from "../../../_api/bidApi";
import procedure from "../../../../redux/procedure";

const RegulationsPropertyLease = ( {langFlag, aucyionsPath, search, token, auctionsSearch, setAuctionCategory, changeCurentPage, changeMyCurentPage, clearSearch} ) => {
    let active = null;
    if(search.category === 'regulationsPropertyLease'){
        active = styles.active;
    }   
    
    let doSearch = function doSearch(search, category) {
        changeMyCurentPage(0)
        setAuctionCategory(category);
        changeCurentPage(0);
        let dataSearch = {}
        dataSearch.order = search.order
        dataSearch.region = search.region
        dataSearch.category = category
        dataSearch.priceCurrency = search.priceCurrency
        dataSearch.priceFrom = search.priceFrom
        dataSearch.priceTo = search.priceTo
        dataSearch.measures = search.measures
        dataSearch.measuresFrom = search.measuresFrom
        dataSearch.measuresTo = search.measuresTo
        dataSearch.areaFrom = search?.areaFrom ? Number (search?.areaFrom): null
        dataSearch.areaTo = search.areaTo ? Number (search?.areaTo): null
        if(search.status === null || search.status === ''){
            dataSearch.status = null
        }else{
            dataSearch.status = search.status
        }
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: langFlag,
            params: '?offset=0'
        }
        auctionsSearch(data, getDefaultHeaders(token), dataSearch);
        clearSearch()
    }

    return (
            <div
                className={styles.aukpoisk_second_elem}
            >
                <Link
                    onClick={() => doSearch(search, 'regulationsPropertyLease-all')}
                    to={`${aucyionsPath}/regulationsPropertyLease${getUrlFiltersParam(search, 'regulationsPropertyLease-all')}`}
                >
                    <div className={styles.base + ' ' + active}></div>
                    <div className={styles.aukpoisk_second_elem_text}>Оренда за регламентом</div>
                </Link>
                <div className={styles.aukpoisk_second_elem_1}>
                    <Link to={`${aucyionsPath}/regulationsPropertyLease${getUrlFiltersParam(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_ENGLISH)}`}>
                        <div
                            className={styles.subMenu}
                            onClick={() => doSearch(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_ENGLISH)}
                        >
                            Оренда за регламентом (англійський аукціон)
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/regulationsPropertyLease${getUrlFiltersParam(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_DUTCH)}`}>
                        <div
                            className={styles.subMenu}
                            onClick={() => doSearch(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_DUTCH)}
                        >
                            Оренда за регламентом (голландський аукціон)
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/regulationsPropertyLease${getUrlFiltersParam(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_PRIORITY_ENGLISH)}`}>
                        <div
                            className={styles.subMenu}
                            onClick={() => doSearch(search, procedure.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_PRIORITY_ENGLISH)}
                        >
                            Оренда за регламентом. Переважне право
                        </div>
                    </Link>
                </div>
            </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    // if(search.itemType !== null){
    //     if(tmp === null){
    //         tmp = '?itemType=land'
    //     }else{
    //         tmp += '&itemType=land'
    //     }
    // }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(search.areaTo){
        tmp = (tmp === null) ? ('?areaTo=' + search.areaTo) : (tmp += '&areaTo=' + search.areaTo)
    }
    if(search.areaFrom){
        tmp = (tmp === null) ? ('?areaFrom=' + search.areaTo) : (tmp += '&areaFrom=' + search.areaTo)
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegulationsPropertyLease)