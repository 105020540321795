import React,{useState} from 'react';
import styles from './viewBid.module.css';

import {connect} from 'react-redux';
import {
    changeBidAward,
    setUniversalError,
    setCheckboxBidPublish,
    changeLoader,
} from '../../../../redux/actions.js';

import {modifiDateString} from '../../../../redux/modifiDate'

import {
    auctionsBidsGetId,
    changeBidErrorPopUpStatus,
    changeBidPopUpEdit,
    changeBidPopUpHelpPublish,
    changeBidPopUpEditDocType,
    changeBidPopUpEditDocTitle,
} from '../../../../redux/actions/auctionBid/auctionBid.js';
import { saveArrBids } from '../../../../redux/allAuctionBids';

import procedure, {
    isTIE, isSUE, isSUD, isRCE, isRCD, isLLE, isBSD,
    isLLD, isLLP, isLRE, isLSE, isLSP, isGFE, isGFD,
    isCSD, isBRD, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE,
    isAPE, isAPD
} from '../../../../redux/procedure';

import BidPopUpHelpPublish from './bidPopUpHelpPublish.js';
import TextHalf from '../../../elements/viewData/textHalf';
import PopDownBlock from '../../../elements/viewData/popdownBlock/popDownBlock';
import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';
import MessageLot from '../../messageLot/messageLot';
import AddDocPopUp from '../../../documents/addDocPopup/addDocPopup';
import DocItem from '../../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../redux/history.js';

import {checkProp, getCurrency} from '../../../../redux/check';

import {setDataAddress} from '../../../../redux/createAddress';
import {getUpdateDataAuction} from '../../updateData'
import PopAsk from "../../../_popups/popAsk/popAsk";
import {getDefaultHeaders, getUserBidsListByAuction} from "../../../_api/bidApi";

const BidLot = (props) => {
    const [popDelete, setPopDelete] = useState(false)
    const [popAddDoc, setPopAddDoc] = useState(false)

    //ЕЦП
    //Запрос на историю бида
    let tmpBaseUrl = null;
    let tmpId = null;
    if(window.location.pathname.indexOf('/tenders/') !== -1){
        tmpBaseUrl = window.location.pathname.split('/tenders/');
        tmpId = tmpBaseUrl[1].split('/')
        tmpId = tmpId[0]
    }

    const docScopeData = {}
    docScopeData.bidId = props.elemBid.id
    docScopeData.bidIdentifier = props.elemBid.bidIdentifier
    docScopeData.bidStatus = props.elemBid.status
    docScopeData.bidModeration = props.elemBid.needModeration
    docScopeData.aucId = props.auctionId.id
    docScopeData.bidDocs = props.elemBid.documents
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    if(props.token !== null){
        docScopeData.token = props.token.access_token
    }else{
        docScopeData.token = null
    }

    docScopeData.typeOf = 'bid'
    docScopeData.aucOwner = props.auctionId.createdByCurrentUser
    docScopeData.bidOwner = props.elemBid.createdByCurrentUser
    docScopeData.bidCreator = props.userProfiles.canCreateBid
    docScopeData.reload = async () => { await getUserBidsListByAuction(props, props.auctionId.id) }
    docScopeData.docList = props.elemBid.documents
    if (isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)) {
        docScopeData.preDisqual = isPreDisqualified(props)
    }

    let bidSize = null;
    let valuta = null;
    if(props.elemBid !== null){
        if(props.elemBid.unit !== null){
            bidSize = props.elemBid.unit.symbol
        }
        if(props.auctionId.value !== null){
            switch(props.auctionId.value.currency){
                case 'UAH':
                valuta = 'грн.'
                break;
                case 'USD':
                valuta = 'USD.'
                break;
                case 'EUR':
                valuta = 'EUR'
                break;
                case 'eurocent':
                valuta = 'eurocent'
                break;
            }
        }
    }

    let tmpPopUp = null;
    if(props.bidPopUpHelpPublish !== null){
        tmpPopUp = <BidPopUpHelpPublish />
    }
    let tmpValueAmount = null
    if(props.elemBid.value !== null){
        if(props.elemBid.value.amount !== null){
            tmpValueAmount = props.elemBid.value.amount
        }
    }

    let tmpCoast
    let tmpStatusD = null
    switch(true){
        case isBSD(props.auctionId.sellingMethod):
        case isRCD(props.auctionId.sellingMethod):
        case isSUD(props.auctionId.sellingMethod):
        case isGFD(props.auctionId.sellingMethod):
        case isCSD(props.auctionId.sellingMethod):
        case isBRD(props.auctionId.sellingMethod):
        case isNLD(props.auctionId.sellingMethod):
        case isAPD(props.auctionId.sellingMethod):
            if(props.elemBid.status === 'draft'){
                tmpStatusD = 'Чернетка заяви'
            }else if(props.elemBid.status === 'active'){
                tmpStatusD = 'Підтверджена заява'
            }else if(props.elemBid.status === 'deleted'){
                tmpStatusD = 'Видалена заява'
            }else if(props.elemBid.status === 'inactive'){
                tmpStatusD = 'Деактивована заява'
            }
            tmpCoast = null
            tmpCoast = <>
                            <TextHalf
                                title='Статус заяви на участь:'
                                data={tmpStatusD}
                            />
                        </>
            break;
        case isRCE(props.auctionId.sellingMethod):
            tmpCoast = <>
                            <TextHalf
                                title='Початкова ціна продажу лоту:'
                                //data={`${startCostAuction(minCoast)} ${valuta}`}
                                data={`${startCostAuction(props.auctionId.value.amount)} ${valuta}`}
                            />
                            <TextHalf
                                title='Цінова пропозиція:'
                                data={`${startCostAuction(tmpValueAmount)} ${valuta}`}
                            />
                        </>
            break;
        case isLLE(props.auctionId.sellingMethod):
        case isLLD(props.auctionId.sellingMethod):
        case isLLP(props.auctionId.sellingMethod):
            let tmpStatus = null
            if(props.elemBid.status === 'draft'){
                tmpStatus = 'Чернетка. Пропозиція не опублікована в ЦБД'
            }else if(props.elemBid.status === 'active'){
                tmpStatus = 'Пропозиція опублікована в ЦБД. Ви є зареєстрованим учасником аукціону'
            }else if(props.elemBid.status === 'deleted'){
                tmpStatus = 'Видалена'
            }
            tmpCoast =
                        <>
                            {!isLLD(props.auctionId.sellingMethod)
                            ? <>
                                <TextHalf
                                    title='Початкова ціна продажу лоту:'
                                    //data={`${startCostAuction(minCoast)} ${valuta}`}
                                    data={`${startCostAuction(props.auctionId.value.amount)} ${valuta}`}
                                />
                                <TextHalf
                                title='Цінова пропозиція:'
                                data={`${startCostAuction(tmpValueAmount)} ${valuta}`}
                                />
                            </>
                            : null}
                            <TextHalf
                            title='Статус заяви на участь:'
                            data={tmpStatus}
                            />
                        </>
            break;
        default:
            //let tmpStatusD = null
            if(props.elemBid.status === 'draft'){
                tmpStatusD = 'Чернетка заяви'
            }else if(props.elemBid.status === 'active'){
                tmpStatusD = 'Підтверджена заява'
            }else if(props.elemBid.status === 'deleted'){
                tmpStatusD = 'Видалена заява'
            }else if(props.elemBid.status === 'inactive'){
                tmpStatusD = 'Деактивована заява'
            }else if(props.elemBid.status === 'invalid'){
                tmpStatusD = 'Інвалідована заява'
            }
            let tmpKol = null
            if(
                props.auctionId.sellingMethod !== 'basicSell-english' &&
                props.auctionId.sellingMethod !== 'basicSell-english-fast' &&
                props.auctionId.sellingMethod !== 'basicSell-english-fast-manual' &&
                props.auctionId.sellingMethod !== 'basicSell-english-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'basicSell-english-initial-auction' &&
                props.auctionId.sellingMethod !== 'basicSell-english-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'basicSell-english-initial-qualification' &&
                props.auctionId.sellingMethod !== 'basicSell-english-initial-qualification-prod' &&
                props.auctionId.sellingMethod !== 'alienation-english' &&
                props.auctionId.sellingMethod !== 'alienation-english-fast' &&
                props.auctionId.sellingMethod !== 'alienation-english-fast-manual' &&
                props.auctionId.sellingMethod !== 'alienation-english-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'alienation-english-initial-auction' &&
                props.auctionId.sellingMethod !== 'alienation-english-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'alienation-english-initial-qualification' &&
                props.auctionId.sellingMethod !== 'alienation-english-initial-qualification-prod' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-fast' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-fast-manual' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-initial-auction' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-initial-qualification' &&
                props.auctionId.sellingMethod !== 'basicSell-dutch-initial-qualification-prod' &&
                props.auctionId.sellingMethod !== 'commercialSell-english' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-fast' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-fast-manual' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-initial-auction' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-initial-qualification' &&
                props.auctionId.sellingMethod !== 'commercialSell-english-initial-qualification-prod' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-fast' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-fast-manual' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-initial-auction' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-initial-qualification' &&
                props.auctionId.sellingMethod !== 'commercialSell-dutch-initial-qualification-prod' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-fast' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-fast-manual' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-fast-auction-manual-qualification' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-initial-auction' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-initial-auction-manual' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-initial-qualification' &&
                props.auctionId.sellingMethod !== 'bankRuptcy-english-initial-qualification-prod' &&
                !isRLE(props.auctionId.sellingMethod) &&
                !procedure.isRLP(props.auctionId.sellingMethod) &&
                !isRLD(props.auctionId.sellingMethod) &&
                !isCLE(props.auctionId.sellingMethod) &&
                !procedure.isCLP(props.auctionId.sellingMethod) &&
                !isCLD(props.auctionId.sellingMethod) &&
                !isSPE(props.auctionId.sellingMethod) &&
                !isSPD(props.auctionId.sellingMethod) &&
                !isNLE(props.auctionId.sellingMethod) &&
                !isNLD(props.auctionId.sellingMethod) &&
                !isLAE(props.auctionId.sellingMethod) &&
                !isLAW(props.auctionId.sellingMethod) &&
                !isLAP(props.auctionId.sellingMethod) &&
                !isLPE(props.auctionId.sellingMethod)
                && !isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
            ){
                tmpKol = <TextHalf
                    title={procedure.isREM(props.auctionId.sellingMethod) ? 'Розмір частки квоти в заяві' : 'Кількість'}
                    data={`${props.elemBid.quantity} ${bidSize}`}
                />
            }
            let tmpBidSumm = null
            if(
                props.auctionId.sellingMethod.startsWith('basicSell-dutch')
                || props.auctionId.sellingMethod.startsWith('commercialSell-dutch')
                || isNLD(props.auctionId.sellingMethod)
                || isAPD(props.auctionId.sellingMethod)
            ){
                tmpBidSumm = <TextHalf
                    title='Цінова пропозиція'
                    data={`${startCostAuction(props.auctionId.value.amount)} ${getCurrency(props.auctionId.value.currency)}`}
                />
            }else{
                tmpBidSumm = <TextHalf
                    title={'Цінова пропозиція' + (procedure.isREM(props.auctionId.sellingMethod) ? ' за 1 кВт-год.' : '')}
                    data={`${startCostAuction(tmpValueAmount)} ${getCurrency(props.auctionId.value.currency)}`}
                />
                if(isRLD(props.auctionId.sellingMethod) || isCLD(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
                    tmpBidSumm = null
                }
            }

            tmpCoast = <>
                            {tmpBidSumm}
                            {tmpKol}
                            <TextHalf
                                title='Статус заяви на участь:'
                                data={tmpStatusD}
                            />
                            {props.elemBid.hasOwnProperty('inactiveBidDate') ? props.elemBid.inactiveBidDate !== null ? <TextHalf
                                title='Дата деактивації заяви на участь:'
                                data={viewDate(props.elemBid.inactiveBidDate)}
                            /> : null : null}
                        </>
            break;
    }

    function viewDate(data){
        if(data !== null){
            return modifiDateString(data, 3, false)
        }
    }

    let tmpCheckBox = null
    let tmpStyles
    if(props.checkBoxBidPublish === false){
        tmpStyles = styles.pseudoCheckboxOn
    }else{
        tmpStyles = styles.pseudoCheckboxOff
    }
    if(props.elemBid.status === "draft" || props.elemBid.status === "inactive"){
        if(props.elemBid.needModeration === false){
            tmpCheckBox = 
                <>
                    <div className={styles.chekbox}>
                        <div
                            className={tmpStyles}
                            onClick={
                                () => {
                                    if(props.checkBoxBidPublish === false){
                                        props.setCheckboxBidPublish(true)
                                    }else{
                                        props.setCheckboxBidPublish(false)
                                    }
                                }
                            }
                        ></div>
                        <div className={styles.chekboxLabel}>Підтверджую згоду з умовами проведення аукціону, з Регламентом та тарифами ознайомлений</div>
                    </div>
                </>
        }
    }
    let tmpIsCurrentTenant = null
    if(props.elemBid !== null){
        if(props.elemBid.hasOwnProperty('isCurrentTenant') === true || props.elemBid.hasOwnProperty('priority') === true){
            if(props?.elemBid?.isCurrentTenant === true || props?.elemBid?.priority === "0"){
                tmpIsCurrentTenant = <div className={styles.HelpInfo}>Учасник з переважним правом</div>
            }else if(props?.elemBid?.isCurrentTenant === true || props?.elemBid?.priority === "1"){
                tmpIsCurrentTenant = <div className={styles.HelpInfo}>Учасник з переважним правом другої черги</div>
            }
        }
    }

    function viewBlockButtons(props, tmpId){
    let tmp = null

    let viewEditButton = null
    if(
        ! props.auctionId.sellingMethod.startsWith('basicSell-dutch') &&
        !props.auctionId.sellingMethod.startsWith('commercialSell-dutch') &&
        !props.auctionId.sellingMethod.startsWith('bankRuptcy-dutch') &&
        !isRLD(props.auctionId.sellingMethod) &&
        !isCLD(props.auctionId.sellingMethod) &&
        !isSPD(props.auctionId.sellingMethod) &&
        !isNLD(props.auctionId.sellingMethod)
        && !isAPD(props.auctionId.sellingMethod)
    ){
        viewEditButton = <div
            className={styles.btnEdit}
            onClick={
                ()=>{
                    props.changeBidPopUpEdit(props.elemBid)
                }
            }
        >
            Редагувати Заяву на участь
        </div>
    }

    if(props.auctionId.status === "active_tendering"){
        tmp = <div className={styles.buttonBlock}>
                <div
                    className={styles.btnDelete}
                    onClick={
                        () => {
                            setPopDelete(!popDelete)
                        }
                    }
                >
                    Видалити Заяву на участь
                </div>
                {
                    popDelete ? <PopAsk
                            title='Ви дійсно хочете видалити Заяву на участь?'
                            actionYes={()=>{
                                    deleteBid(props, tmpId, props.elemBid.id)
                                    setPopDelete(!popDelete)
                                    }}
                            actionNo={()=>{setPopDelete(!popDelete)}}
                            />
                    : null
                }
                {viewEditButton}
            <div>
                {buttonPublish(props, tmpId)}
            </div>
        </div>
    }else{
        if (isBSD(props.auctionId.sellingMethod) ||
            isRCD(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
            isGFD(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) ||
            isLLD(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod) ||
            isBRD(props.auctionId.sellingMethod) ||
            isRLE(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) || procedure.isRLP(props.auctionId.sellingMethod) ||
            isCLE(props.auctionId.sellingMethod) || isCLD(props.auctionId.sellingMethod) || procedure.isCLP(props.auctionId.sellingMethod) ||
            isSPD(props.auctionId.sellingMethod) ||
            isNLD(props.auctionId.sellingMethod) 
            || isAPD(props.auctionId.sellingMethod)
        ){
            if(props.auctionId.status === "active_auction"){
                tmp = <div className={styles.buttonBlock}>
                        {!isRLD(props.auctionId.sellingMethod) && !isCLD(props.auctionId.sellingMethod) && !isSPD(props.auctionId.sellingMethod) ? <div
                            className={styles.btnDelete}
                            onClick={
                                ()=>{setPopDelete(!popDelete)
                                }
                            }
                        >
                            Видалити Заяву на участь
                        </div> : null}
                        {
                            popDelete ? <PopAsk
                                    title='Ви дійсно хочете видалити Заяву на участь?'
                                    actionYes={()=>{
                                            deleteBid(props, tmpId, props.elemBid.id)
                                            setPopDelete(!popDelete)
                                            }}
                                    actionNo={()=>{setPopDelete(!popDelete)}}
                                    />
                            : null
                        }
                        {!isRLD(props.auctionId.sellingMethod) || !isCLD(props.auctionId.sellingMethod) ? viewEditButton : null}
                    <div>
                        {buttonPublish(props, tmpId)}
                    </div>
                </div>
            }
        }
    }
    return tmp
}
    
    let tmpInfo
    if(isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod) ){
        tmpInfo = null
    }else if(props.elemBid.status === 'active'){
        tmpInfo = <InstructionBlock
            title='Важлива інформація'
            content={
                <>
                    <p>Увага! Всі зміни, внесені до Вашої початкової закритої пропозиції після перевірки та підтвердження майданчиком, повторно не перевіряються та є повністю Вашою відповідальністю!</p>
                </>
            }
        />
    }else{
        tmpInfo = <InstructionBlock
            title='Додайте документи'
            content={
                <>
                    <p>{ isLAP(props.auctionId.sellingMethod) ? "Додайте обов’язкові документи учасника:" : "Додайте обов’язкові документи учасника, що відповідають вимогам" }</p>
                    {docNeeded(props)}
                    {viewHelpBids(props)}
                </>
            }
        />
    }
    return (
        <>{popAddDoc ?
            <AddDocPopUp
                actionClose={() => {
                    setPopAddDoc(false)
                }}
                actionNo={() => {
                    setPopAddDoc(false)
                }}
                scopeData={docScopeData}
                bidId={props.elemBid.id}
                elemBid={props.elemBid}
            /> : null}
            <div className={styles.blockBidLot} key={docScopeData?.bidId}>
                <div className={styles.blockBidLot_title}>Ваша пропозиція {tmpIsCurrentTenant}</div>
                {tmpCoast}
                {tmpInfo}
                {openDocComponents(props, popAddDoc, setPopAddDoc)}
                <PopDownBlock
                    title={`Документи пропозиції (${dataECPMapperForCount(props.elemBid)})`}
                    content={
                        <>  
                            {viewBidsDoc(props, setPopAddDoc , docScopeData)}
                        </>
                    }
                />
                <MessageLot
                    content={
                        <>
                            Згідно Регламенту роботи Електронної торгової системи, після завершення аукціону Ваша пропозиція і вся вкладена документація будуть опубліковані в загальному доступі. Детальніше: <a href="https://www.dto.com.ua/info/rules" target="blank">Регламент роботи ЕТС Prozorro:Продажі</a>
                        </>
                    }
                />
                {tmpCheckBox}
                {viewBlockButtons(props, tmpId)}
            </div>
            {tmpPopUp}
        </>
    )
}

function isPreDisqualified(props) { // для LRE, LSE, LSP - был ли участник дисквалифицирован
    let identifier = props.userProfiles.organization.identifier.identifier
    if (checkProp(props.auctionId, 'disqualifiedBids')) {
        for (let key in props.auctionId.disqualifiedBids) {
            if (props.auctionId.disqualifiedBids[key] === identifier) {
                return true
            }
        }
    }
    return false
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

function viewHelpBids(props){
    if (props.auctionId === null) return null
    if(!isLAE(props.auctionId.sellingMethod) && !isLAW(props.auctionId.sellingMethod) && !isLAP(props.auctionId.sellingMethod)) return null
    if(getBidDocType(props)){
        return (
            <p>Шановний учаснику, повідомляємо Вам, що для переведення Вашої ставки в статус "активна" на цьому аукціоні, необхідно завантажити документ  "Підстави для допуску дискваліфікованого учасника", які були вказані в блоці "Недопуск учасника" на попередньому аукціоні.</p>
        )
    }
}
function getBidDocType(props){
    if(props.auctionId === null) return false
    if(props.auctionId.disqualifiedBids === null || props.auctionId.disqualifiedBids.length === 0) return false
    let tmp = props.auctionId.disqualifiedBids.filter(
        (i) => {
            return i === props.userProfiles.organization.identifier.identifier
        }
    )
    return tmp.length > 0;

}

function docNeeded(props){
    if (props.auctionId !== null) {
        if (procedure.isREM(props.auctionId.sellingMethod)) {
            return (
                <>
                    <li>- Фінансове забезпечення;</li>
                    <li>- Інформація про кінцевого бенефіціарного власника;</li>
                </>
            )
        }
        if (isTIE(props.auctionId.sellingMethod)) {
            return (
                <>
                    <li>- ВИТЯГ з Єдиного державного реєстру юридичних осіб, фізичних осіб — підприємців;</li>
                    <li>- Заява про непоширення на заявника санкцій;</li>
                    <li>- Довідка, що містить реквізити та дані про учасника;</li>
                    <li>- копія паспорту (ФОП);</li>
                    <li>- Документ, що підтверджує повноваження представника юридичної особи (наказ/рішення зборів) ) (юридична особа).</li>
                </>
            )
        }
        if (isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)) {
            return (
                <>
                    <li>- копія паспорту (ФОП);</li>
                    <li>- ідентифікаційний код (ФОП);</li>
                    <li>- документ, що підтверджує повноваження представника юридичної особи (наказ/рішення зборів) (юридична особа);</li>
                    <li>- ВИТЯГ з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців (юридична особа);</li>
                    <li>- довідка, що містить реквізити та дані про учасника, завірена КЕП;</li>
                    <li>- заява про непоширення на заявника санкцій, завірена КЕП.</li>
                </>
            )
        }
        if (isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)) {
            return (
                <>
                    <li>- ФГВФО: У разі покупки прав вимоги/майнових прав, необхідно додати лист, що учасник не є боржником чи поручителем за кредитним договором</li>
                </>
            )
        }
        if (isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) || isLLP(props.auctionId.sellingMethod)) {
            return (
                <>
                    <li>- документ, що підтверджує сплату реєстраційного внеску;</li>
                    <li>- документ, що підтверджує сплату гарантийного внеску;</li>
                    <li>- заява на участь в аукціоні;</li>
                    <li>- копія паспорту (ФОП, фізична особа);</li>
                    <li>- ВИТЯГ з Єдиного державного реєстру юридичних осіб, фізичних осіб — підприємців (юридична особа, ФОП);</li>
                    <li>- довідка про кінцевого бенефіціарно власника (юридична особа).</li>
                </>
            )
        }
        if (isLAP(props.auctionId.sellingMethod)){
            return (
                <>
                    <li>- заява на участь;</li>
                    <li>- для учасника, якого було дискваліфіковано на попередньому аукціоні, необхідно додати наступний документ: Підстави для допуску дискваліфікованого учасника.</li>
                </>
            )
        }

        if(isLPE(props.auctionId.sellingMethod)){
            if(props.userProfiles.organization.identifier.scheme === "UA-EDR"){
                return (
                    <>
                        <li>- Остання річна або квартальна фінансова звітність</li>
                        <li>- Згода про взяття на себе зобов'язань, визначених умовами продажу</li>
                        <li>- Документ, що підтверджує сплату реєстраційного внеску</li>
                        <li>- Документ, що підтверджує сплату гарантійного внеску</li>
                    </>
                )
            }else if(props.userProfiles.organization.identifier.scheme === "UA-IPN"
                || props.userProfiles.organization.identifier.scheme === "UA-PASSPORT"
                || props.userProfiles.organization.identifier.scheme === "UA-ID-CARD"
                || props.userProfiles.organization.identifier.scheme === "UA-IPN-FOP"
            ){
                return (
                    <>
                        <li>- Інформація про джерела походження коштів для придбання об\'єкта великої приватизації</li>
                        <li>- Згода про взяття на себе зобов'язань, визначених умовами продажу</li>
                        <li>- Документ, що підтверджує сплату реєстраційного внеску</li>
                        <li>- Документ, що підтверджує сплату гарантійного внеску</li>
                    </>
                )
            }else{
                return (
                    <>
                        <li>- Згода про взяття на себе зобов'язань, визначених умовами продажу</li>
                        <li>- Документ, що підтверджує сплату реєстраційного внеску</li>
                        <li>- Документ, що підтверджує сплату гарантійного внеску</li>
                    </>
                )
            }
        }
    }
    return null
}

function openDocComponents(props, popAddDoc, setPopAddDoc){
        return (
            <div className={styles.btnAddDoc}
                onClick={() => setPopAddDoc(!popAddDoc)}
            >Додати документ</div>
        )
}

function dataECPMapper(data, t) {
    let tmp = data.documents
        .filter(
        (item) => {
            return item.documentType === 'digitalSignature' && item.relatedDocument === t.documentId
        })
    if (tmp.length === 0) return null
    return tmp
}

function dataECPMapperForCount(data) {
    let tmp = data.documents
        .filter(
        (item) => {
            return item.documentType !== 'digitalSignature'
        })
    return tmp.length
}

function viewBidsDoc(props, setPopAddDoc, docScopeData){
    if(props.elemBid !== null){
        return props.elemBid.documents.filter(
                    (t) => {
                        return t.documentType !== 'digitalSignature'
                    }
                ).map(
                    (i) => {
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${props.elemBid.bidIdentifier}/documents`
                return (
                        <DocItem
                            data={i}
                            scopeData={docScopeData}
                            key={i.id}
                            dataECP={ dataECPMapper(props.elemBid, i) }
                        />
                )
            }
        )
    }
    return null
}

function buttonPublish(props, tmpId){
    let tmp = <div
        className={styles.btnDisabled}
    >
        Публікація Заяви на участь
    </div>

    if(props.elemBid !== null && props.auctionId !== null){
        switch(true){
            case isTIE(props.auctionId.sellingMethod):
                let tmpOff = true
                for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'x_nonSanctionedStatement') tmpOff = false
                }
                if(tmpOff !== false) return tmp
                break
            case isLLE(props.auctionId.sellingMethod):
            case isLLD(props.auctionId.sellingMethod):
            case isLLP(props.auctionId.sellingMethod):
                let tmpOffx_registrationFeeApproval = true
                let tmpOffx_guaranteeApproval = true
                for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'x_registrationFeeApproval') tmpOffx_registrationFeeApproval = false
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'x_guaranteeApproval') tmpOffx_guaranteeApproval = false
                }
                if(tmpOffx_registrationFeeApproval !== false) return tmp
                if(tmpOffx_guaranteeApproval !== false) return tmp
                break
            case isLRE(props.auctionId.sellingMethod):
            case isLSE(props.auctionId.sellingMethod):
            case isLSP(props.auctionId.sellingMethod):
                let tmpOffcommercialProposal = true
                let tmpOffregistrationFeeApproval = true
                let tmpOffguaranteeApproval = true
                for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                    //TODO: Непонятно откуда этот документ в списке нет его как обязательный
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'commercialProposal') tmpOffcommercialProposal = false
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'x_registrationFeeApproval') tmpOffregistrationFeeApproval = false
                    if(props.elemBid.documents[tmpDotNeed].documentType === 'x_guaranteeApproval') tmpOffguaranteeApproval = false
                }
                if(tmpOffcommercialProposal !== false) return tmp
                if(tmpOffregistrationFeeApproval !== false) return tmp
                if(tmpOffguaranteeApproval !== false) return tmp
                break
            case isLPE(props.auctionId.sellingMethod):
                if(props.userProfiles.organization.identifier.scheme === "UA-EDR"){
                    let tmpFinancialStatements = true
                    let tmpAgreement = true
                    let tmpX_registrationFeeApproval = true
                    let tmpX_guaranteeApproval = true
                    for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'financialStatements') tmpFinancialStatements = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'agreement') tmpAgreement = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_registrationFeeApproval') tmpX_registrationFeeApproval = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_guaranteeApproval') tmpX_guaranteeApproval = false
                    }
                    if(tmpFinancialStatements !== false) return tmp
                    if(tmpAgreement !== false) return tmp
                    if(tmpX_registrationFeeApproval !== false) return tmp
                    if(tmpX_guaranteeApproval !== false) return tmp
                }else if(props.userProfiles.organization.identifier.scheme === "UA-IPN"
                    || props.userProfiles.organization.identifier.scheme === "UA-PASSPORT"
                    || props.userProfiles.organization.identifier.scheme === "UA-ID-CARD"
                    || props.userProfiles.organization.identifier.scheme === "UA-IPN-FOP"
                ){
                    let tmpFonds = true
                    let tmpAgreement = true
                    let tmpX_registrationFeeApproval = true
                    let tmpX_guaranteeApproval = true
                    for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'fonds') tmpFonds = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'agreement') tmpAgreement = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_registrationFeeApproval') tmpX_registrationFeeApproval = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_guaranteeApproval') tmpX_guaranteeApproval = false
                    }
                    if(tmpFonds !== false) return tmp
                    if(tmpAgreement !== false) return tmp
                    if(tmpX_registrationFeeApproval !== false) return tmp
                    if(tmpX_guaranteeApproval !== false) return tmp
                }else{
                    let tmpAgreement = true
                    let tmpX_registrationFeeApproval = true
                    let tmpX_guaranteeApproval = true
                    for(let tmpDotNeed = 0; tmpDotNeed < props.elemBid.documents.length; tmpDotNeed++){
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'agreement') tmpAgreement = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_registrationFeeApproval') tmpX_registrationFeeApproval = false
                        if(props.elemBid.documents[tmpDotNeed].documentType === 'x_guaranteeApproval') tmpX_guaranteeApproval = false
                    }
                    if(tmpAgreement !== false) return tmp
                    if(tmpX_registrationFeeApproval !== false) return tmp
                    if(tmpX_guaranteeApproval !== false) return tmp
                }
                break
            default:
                break
        }
    }

                if(props.auctionId.status === "active_tendering"){
                    if(props.elemBid.status === "draft" || props.elemBid.status === "inactive"){
                        if(props.elemBid.needModeration === false){
                            if(props.checkBoxBidPublish === true){
                                tmp = <div
                                    className={styles.btnPublish}
                                    onClick={
                                        ()=>{
                                            if(props.elemBid.documents.length > 0){
                                                if (isTIE(props.auctionId.sellingMethod)) {
                                                    let xNonSanctionedStatementDocumentCount = 0
                                                    for (let i = 0; i < props.elemBid.documents.length; i++) {
                                                        if (props.elemBid.documents[i].documentType === 'x_nonSanctionedStatement') xNonSanctionedStatementDocumentCount = xNonSanctionedStatementDocumentCount + 1
                                                    }
                                                    if (xNonSanctionedStatementDocumentCount === 1) {
                                                        props.changeBidPopUpHelpPublish({
                                                            tmpId: tmpId,
                                                            elemBidId: props.elemBid.id,
                                                            type: 1,
                                                            owner: props.elemBid.owner,
                                                            status: props.elemBid.status
                                                        })
                                                    } else {
                                                        props.changeBidPopUpHelpPublish({type: 2})
                                                    }
                                                }

                                                props.changeBidPopUpHelpPublish({
                                                    tmpId: tmpId,
                                                    elemBidId: props.elemBid.id,
                                                    type: 1,
                                                    owner: props.elemBid.owner,
                                                    status: props.elemBid.status
                                                })

                                            }else{
                                                    props.changeBidPopUpHelpPublish({
                                                        tmpId: tmpId,
                                                        elemBidId: props.elemBid.id,
                                                        type: 1,
                                                        owner: props.elemBid.owner,
                                                        status: props.elemBid.status
                                                    })
                                            }
                                        }
                                    }
                                >
                                    Публікація Заяви на участь
                                </div>
                            }
                        }else if(props.elemBid.needModeration === true){
                            tmp = <div
                                className={styles.btnDisabled}
                            >
                                Заява на участь на модерації
                            </div>
                        }
                    }else if(props.elemBid.status === "active" && props.elemBid.needModeration === true){
                        tmp = <div
                            className={styles.btnDisabled}
                        >
                            Заява на участь активна
                        </div>
                    }
                }else{
                    if (
                        isBSD(props.auctionId.sellingMethod) ||
                        isRCD(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
                        isGFD(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) ||
                        isBSD(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod) ||
                        isBRD(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) ||
                        isCLD(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) ||
                        isNLD(props.auctionId.sellingMethod)
                        || isAPD(props.auctionId.sellingMethod)
                    ){
                        if(props.auctionId.status === "active_auction"){
                            let dateActiveAuction = null
                            if(checkProp(props.auctionId, 'auctionPeriod') && checkProp(props.auctionId.auctionPeriod, 'startDate')){
                                dateActiveAuction = new Date(props.auctionId.auctionPeriod.startDate)
                            }
                            let tmpDate = dateActiveAuction.getTime() + ((1000 * 60) * 60)
                            let timePoyas = ((1000 * 60) * 60) * 3
                            let timeDutch = (((1000 * 60) * 60) * 6) + ((1000 * 60) * 45)
                            let tmpSumm = tmpDate + timePoyas + timeDutch
                            if(tmpSumm > Date.now()){
                                if(props.elemBid.status === "draft"){
                                    if(props.elemBid.needModeration === false){
                                        if(props.checkBoxBidPublish === true){
                                            tmp = <div
                                                className={styles.btnPublish}
                                                onClick={
                                                    ()=>{
                                                        if(props.elemBid.documents.length > 0){
                                                            let tt = 0
                                                            for(let i = 0; i < props.elemBid.documents.length; i++){
                                                                if(props.elemBid.documents[i].documentType === 'x_nonSanctionedStatement') tt = tt + 1
                                                            }
                                                            if(
                                                                isBSD(props.auctionId.sellingMethod) ||
                                                                isRCD(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
                                                                isGFD(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) ||
                                                                isBSD(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod) ||
                                                                isBRD(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) ||
                                                                isCLD(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) ||
                                                                isNLD(props.auctionId.sellingMethod)
                                                                || isAPD(props.auctionId.sellingMethod)
                                                            ){
                                                                    props.changeBidPopUpHelpPublish({
                                                                        tmpId: tmpId,
                                                                        elemBidId: props.elemBid.id,
                                                                        type: 1,
                                                                        owner: props.elemBid.owner,
                                                                        status: props.elemBid.status
                                                                    })
                                                            }else{
                                                                if(tt === 1){
                                                                    props.changeBidPopUpHelpPublish({
                                                                        tmpId: tmpId,
                                                                        elemBidId: props.elemBid.id,
                                                                        type: 1,
                                                                        owner: props.elemBid.owner,
                                                                        status: props.elemBid.status
                                                                    })
                                                                }else{
                                                                    props.changeBidPopUpHelpPublish({type: 2})
                                                                }
                                                            }
                                                        }else{
                                                            if(
                                                                isBSD(props.auctionId.sellingMethod) ||
                                                                isRCD(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
                                                                isGFD(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) ||
                                                                isBSD(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod) ||
                                                                isBRD(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) ||
                                                                isCLD(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) ||
                                                                isNLD(props.auctionId.sellingMethod)
                                                                || isAPD(props.auctionId.sellingMethod)
                                                            ){
                                                                props.changeBidPopUpHelpPublish({
                                                                    tmpId: tmpId,
                                                                    elemBidId: props.elemBid.id,
                                                                    type: 1,
                                                                    owner: props.elemBid.owner,
                                                                    status: props.elemBid.status
                                                                })
                                                            }else{
                                                                props.changeBidPopUpHelpPublish({type: 2})
                                                            }
                                                        }
                                                    }
                                                }
                                            >
                                                Публікація Заяви на участь
                                            </div>
                                        }
                                    }else if(props.elemBid.needModeration === true){
                                        if(props.elemBid.status === "active"){
                                            tmp = <div
                                                className={styles.btnDisabled}
                                            >
                                                Заява на участь активна
                                            </div>
                                        }else{
                                            tmp = <div
                                                className={styles.btnDisabled}
                                            >
                                                Заява на участь на модерації
                                            </div>
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    return tmp
}

//@todo move to API component
async function deleteBid(props, auction, bid){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid}`;
    await fetch(endPoint, {
        method: "delete",
        headers: getDefaultHeaders(props)
    })
    .then(
        async () => {
            await getUpdateDataAuction(props, props.auctionId.id)
            await getUserBidsListByAuction(props, props.auctionId.id)
        }
    )
    .catch(console.error)
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        bidPopUpHelpPublish: state.start.bidPopUpHelpPublish,
        checkBoxBidPublish: state.start.checkBoxBidPublish,
        userProfiles: state.start.userProfiles,
        bidSumm: state.start.bidSumm,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    auctionsBidsGetId,
    changeBidErrorPopUpStatus,
    changeBidPopUpEdit,
    changeBidPopUpHelpPublish,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
    setUniversalError,
    setCheckboxBidPublish,
    changeLoader,
    saveArrBids,
    changeBidPopUpEditDocType,
    changeBidPopUpEditDocTitle,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidLot)