import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'
import {TranslationsUk} from "../../../../redux/translations.uk";

const LotId = (props) => {
    let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }
    if (props?.auctionId?.lotId) {
        return (
            <TextHalf
                title={TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.lotId?.legalName ?? 'Лот виставляється' }
                data={props.auction.lotId}
            />
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotId)