import React, { useState } from 'react';
import {connect} from 'react-redux';

import styles from './bankAccounts.module.css';
import BankAccountsNew from './bankAccountsNew';

import {
    bankAccountGet,
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

const BankAccount =( { bankAccounts, setBankAccounts, editFlagSwitch})=> {
    // const [bankAccounts, setBankAccounts] = useState(!!data && data.length > 0 ? data : []);
    const [newBanks, setNewBankArr] = useState([]);
    const [deleteBanks, setDelBankArr] = useState([]);

    async function setBanks(data){
        for (let key in data){
            if (data[key].accounts.length === 0) {
                const index = data.indexOf(data[key])
                data.splice(index, 1)
            }
        }
        await setBankAccounts(null)
        setBankAccounts(data)
        if (editFlagSwitch) {
            editFlagSwitch(true)
        }
    }

    return (
        <div className={styles.bankBlock}>
            <div className={styles.bankBlockTitle}>Банківські реквізити *</div>
            <BankAccountsNew 
                bankAccounts={bankAccounts}
                setBankAccounts={setBanks}

                newBankArr={newBanks}
                deleteBanks={deleteBanks}
                setNewBankArr={setNewBankArr}
                setDelBankArr={setDelBankArr}
            />
        </div>
    )
}

 
const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        bankAccountWork: state.start.bankAccountWork,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        deleteBankGroops: state.start.deleteBankGroops,
        deleteCurentBank: state.start.deleteCurentBank
    }
}

const mapDispatchToProps = {
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
    bankAccountGet
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount)