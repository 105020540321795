import procedure from "../../redux/procedure";

export function disabledElement(data, status){
    if (procedure.isRLP(data.sellingMethod) || procedure.isCLP(data.sellingMethod)) {
        if (
            (data.status === null || data.status === 'active_rectification')
            || data.tenderAttempts === 1
            || procedure.inPeriod('rectificationPeriod', data)
        ) {
            return null
        }
        return 'disabled'
    }
    if(
        data.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        data.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        data.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        if(data.status === null || data.status === 'active_rectification'){
            if(data.tenderAttempts === 1){
                switch(status){
                    case '00':
                        return null
                    case '01':
                        if(data.status === null) return 'disabled'
                        if(data.status === 'active_rectification') return null
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        return null
                    default:
                        return null
                }
            }else{
                switch(status){
                    case '00':
                        return null
                    case '01':
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        //return 'disabled'
                        return null
                    default:
                        return null
                }
            }
        }else{
            return 'disabled'
        }
    }else{
        if(data.status === null){
            return null
        }else if(data.status === 'active_rectification'){
            switch(status){
                case '11':
                    return 'disabled'
                default:
                    return null
            }
        }else{
            let tmpStartData = null
            let tmpEndData = null
            if(data.hasOwnProperty('auctionPeriods')){
                for(let tt = 0; tt < data.auctionPeriods.length; tt++){
                    if(data.auctionPeriods[tt].status === 'rectificationPeriod'){
                        tmpStartData = new Date(data.auctionPeriods[tt].startDate).getTime()
                        tmpEndData = new Date(data.auctionPeriods[tt].endDate).getTime()
                    }
                }
            }else{
                if(data.hasOwnProperty('rectificationPeriod')){
                    if(data.rectificationPeriod !== null){
                        tmpStartData = new Date(data.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(data.rectificationPeriod.endDate).getTime()
                    }
                }
            }
            if(
                (
                    data.sellingMethod.startsWith('basicSell-english') ||
                    data.sellingMethod.startsWith('basicSell-dutch') ||
                    data.sellingMethod.startsWith('alienation-english') ||
                    data.sellingMethod.startsWith('commercialSell-english') ||
                    data.sellingMethod.startsWith('bankRuptcy-english') ||
                    data.sellingMethod.startsWith('bankRuptcy-dutch') ||

                    data.sellingMethod.startsWith('commercialPropertyLease-english') ||
                    data.sellingMethod.startsWith('commercialPropertyLease-dutch') ||
                    data.sellingMethod.startsWith('regulationsPropertyLease-english') ||
                    data.sellingMethod.startsWith('regulationsPropertyLease-dutch') ||
                    data.sellingMethod.startsWith('nonperformingLoans-english') ||
                    data.sellingMethod.startsWith('nonperformingLoans-dutch')

                ) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                switch(status){
                    case '11':
                        return 'disabled'
                    default:
                        return null
                }
            }else if(
                data.sellingMethod.startsWith('commercialSell-dutch') &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                switch(status){
                    case '11':
                        return 'disabled'
                    case '17':
                        return 'disabled'
                    default:
                        return null
                }
            }else{
                return 'disabled'
            }
        }
    }
}

export function onOffCurrentTenat(data){
    if(data.status === null || data.status === 'active_rectification'){
        return null
    }else{
        if(
            data.sellingMethod === 'landRental-english' ||
            data.sellingMethod === 'landRental-english-fast' ||
            data.sellingMethod === 'landRental-english-fast-manual' ||
            data.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landRental-english-initial-auction' ||
            data.sellingMethod === 'landRental-english-initial-auction-manual' ||
            data.sellingMethod === 'landRental-english-initial-qualification' ||
            data.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            data.sellingMethod === 'landSell-english' ||
            data.sellingMethod === 'landSell-english-fast' ||
            data.sellingMethod === 'landSell-english-fast-manual' ||
            data.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landSell-english-initial-auction' ||
            data.sellingMethod === 'landSell-english-initial-auction-manual' ||
            data.sellingMethod === 'landSell-english-initial-qualification' ||
            data.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            data.sellingMethod === 'landSell-priorityEnglish' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            return auctionPeriodOff(data)    
        }
    }
    return 'disabled'
}

function auctionPeriodOff(data){
    let tmp = new Date().getTime()
    let tmpEnd = -1
    if(data !== null){
        if(data.auctionPeriods !== null){
            if(data.auctionPeriods.length > 0){
                for(let tm = 0; tm < data.auctionPeriods.length; tm++){
                    if(data.auctionPeriods[tm].status === "transferPriorityPeriod") {
                        tmpEnd = new Date(data.auctionPeriods[tm].endDate)
                    }
                }
            }
        }
    }
    let tmpEnd2 = tmpEnd
    if(tmpEnd2 !== -1){
        tmpEnd2 = tmpEnd.getTime()
    }
    if(tmpEnd !== -1 && tmpEnd2 > tmp) return null
    return 'disabled'
}