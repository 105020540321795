import React, {useState} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';

import BankAccount from '../../bankAccount/bankAccount';
import LocationOrg from '../../../../elems/localization/locationOrg';

import { disabledElement } from '../../../../disabled';

import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'


const AuctionAPE = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') && Number.isInteger(data.tenderAttempts) ? `${data.tenderAttempts}`: "1")
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') && Number.isInteger(data.minNumberOfQualifiedBids) ? data.minNumberOfQualifiedBids : 1)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') && data.valueAddedTaxCharged !== null ? data.valueAddedTaxCharged : false)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [preparationValue, setPreparationValue] = useState(checkProp(data, 'preparationValue') ? data.preparationValue : null)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [isPerishable, setIsPerishable] = useState(checkProp(data, 'isPerishable') ? data.isPerishable : false)


    // const [, setAssetHolderInformationName] = useState((checkProp(data, 'organizationEntity') &&  checkProp(data.organizationEntity, 'name')) ? isMongo(data.organizationEntity.name) : null)
    const [assetHolderRepresentativeInfo, setAssetHolderRepresentativeInfo] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'representativeInfo')) ? data.organizationEntity.representativeInfo : null)

    const [contactPointName, setContactPointName] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'contactPoint') && checkProp(data.organizationEntity.contactPoint, 'name')) ? isMongo(data.organizationEntity.contactPoint.name) : null)
    const [contactPointEmail, setContactPointEmail] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'contactPoint') && checkProp(data.organizationEntity.contactPoint, 'email')) ? data.organizationEntity.contactPoint.email : null)
    const [contactPointTelephone, setContactPointTelephone] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'contactPoint') && checkProp(data.organizationEntity.contactPoint, 'telephone')) ? data.organizationEntity.contactPoint.telephone : null)
    const [contactPointFaxNumber, setContactPointFaxNumber] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'contactPoint') && checkProp(data.organizationEntity.contactPoint, 'faxNumber')) ? data.organizationEntity.contactPoint.faxNumber : null)
    const [contactPointUrl, setContactPointUrl] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'contactPoint') && checkProp(data.organizationEntity.contactPoint, 'url')) ? data.organizationEntity.contactPoint.url : null)

    const [contactPointName2, setContactPointName2] = useState((checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'name')) ? isMongo(data.contactPoint.name) : null)
    const [contactPointEmail2, setContactPointEmail2] = useState((checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'email')) ? data.contactPoint.email : null)
    const [contactPointTelephone2, setContactPointTelephone2] = useState((checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'telephone')) ? data.contactPoint.telephone : null)
    const [contactPointFaxNumber2, setContactPointFaxNumber2] = useState((checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'faxNumber')) ? data.contactPoint.faxNumber : null)
    const [contactPointUrl2, setContactPointUrl2] = useState((checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'url')) ? data.contactPoint.url : null)

    const [identifierLegalName, setIdentifierLegalName] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'identifier') && checkProp(data.organizationEntity.identifier, 'legalName')) ? isMongo(data.organizationEntity.identifier.legalName) : null)
    const [identifierScheme, setIdentifierScheme] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'identifier') && checkProp(data.organizationEntity.identifier, 'scheme')) ? data.organizationEntity.identifier.scheme : null)
    const [identifierId, setIdentifierId] = useState((checkProp(data, 'organizationEntity') && checkProp(data.organizationEntity, 'identifier') && checkProp(data.organizationEntity.identifier, 'id')) ? data.organizationEntity.identifier.id : null)

    const [address, setAddress] = useState(
        (checkProp(data, 'organizationEntity')
        && checkProp(data.organizationEntity, 'address')
    ) ? data.organizationEntity.address : null)


    let requestBody = {
        title: title !== ''
            ? { uk_UA: title }
            : null,
        description: description !== ''
            ? { uk_UA: description }
            : null,
        accessDetails:  accessDetails !== ''
            ? { uk_UA: accessDetails }
            : null,
        tenderAttempts: +tenderAttempts,
        lotId: lotId !== '' ? lotId : null,
        sellingMethod: data.sellingMethod,
        x_documentRequirements: {
            uk_UA: x_documentRequirements !== '' ? x_documentRequirements : null
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation !== '' ? x_additionalInformation : null
        },
        minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        auctionPeriod: isPerishable === true ? null : {
            startDate: startDate
        },
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        preparationValue: {
            currency: checkProp(preparationValue,'currency') ? preparationValue.currency : null,
            amount: checkProp(preparationValue,'amount') ? +preparationValue.amount : null,
            valueAddedTaxIncluded: checkProp(preparationValue,'addTax') ? preparationValue.addTax : null
        },
        previousAuctionId: previousAuctionId,
        
        bankAccounts: bankAccounts,
        isPerishable: isPerishable,

        organizationEntity: (identifierLegalName !== null && identifierLegalName !== '')
            && (identifierId !== null && identifierId !== '')
            && (identifierScheme !== null && identifierScheme !== '' && identifierScheme !== 'null') ? {
            identifier: {
                scheme: identifierScheme,
                id: identifierId,
                legalName: {
                    uk_UA: identifierLegalName
                }
            },
            address: address,
            representativeInfo: assetHolderRepresentativeInfo,
            contactPoint: {
                name: {
                    uk_UA: contactPointName
                },
                email: contactPointEmail,
                telephone: contactPointTelephone,
                faxNumber: contactPointFaxNumber,
                url: contactPointUrl
            }
        } : null,
        contactPoint: {
            name: {
                uk_UA: contactPointName2
            },
            email: contactPointEmail2,
            telephone: contactPointTelephone2,
            faxNumber: contactPointFaxNumber2,
            url: contactPointUrl2
        },
    }

    function perishable(){
        if(data.items !== null
            && auction.items.length > 0
            && auction.items[0].hasOwnProperty('classification')
            && auction.items[0].classification.hasOwnProperty('id')
        ){
            if(auction.items[0].classification.id[0] === '0' && auction.items[0].classification.id[1] === '3') return isPerishableView()
            if(auction.items[0].classification.id[0] === '1' && auction.items[0].classification.id[1] === '5') return isPerishableView()
            if(auction.items[0].classification.id[0] === '3'
                && auction.items[0].classification.id[1] === '3'
                && auction.items[0].classification.id[2] === '6'
            ) return isPerishableView()

            if(isPerishable !== false) setIsPerishable(false)
            return (
                <Checkbox 
                    value={false}
                    //onChange={setIsPerishable}
                    disabled='disabled'
                >
                    Майно, що швидко псується
                </Checkbox>
            )
        }
        return isPerishableView()
    }
    function isPerishableView(){
        if(isPerishable === true){
            return (
                <Checkbox 
                    value={true}
                    onChange={setIsPerishable}

                >
                    Майно, що швидко псується
                </Checkbox>
            )
        }else{
            return (
                <Checkbox 
                    value={false}
                    onChange={setIsPerishable}

                >
                    Майно, що швидко псується
                </Checkbox>
            )
        }
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label="Номер попереднього аукціону (аукціону з єдиним учасником)"
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabledElement(auction, '01')}
                            required
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    return (
        <>
            <Input //"Номер лота"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                
                required
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                
                required
            />
            <TextArea 
                value={description}
                
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: "Лот виставляється", 
                    target: tenderAttempts,
                    
                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок ознайомлення з майном, час і місце проведення огляду об’єкта" 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label="Перелік та вимоги до оформлення документів"
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                
            />
            <Input
                label="Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minNumberOfQualifiedBids.legalName, 
                    target: minNumberOfQualifiedBids,

                    // required: true,
                }}
                //disabled={true}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: 1, name: '1'},
                        {value: 2, name: '2'}
                    ]
                }
            />
            {perishable()}
            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InputAmount //"Стартова ціна лота"
                    title="Стартова ціна лота"
                    lable="Стартова ціна лота"
                    data={{
                        amount: checkProp(value,'amount') && value.amount !== 0.01 ? value.amount : null,
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                    }}
                    onChange={(e) => {
                        setValue(e)
                        if(Number(e.amount) <= 20000){
                            setRegistrationFee({registrationFee, amount: 17})
                        }else if(Number(e.amount) <= 50000){
                            setRegistrationFee({registrationFee, amount: 119})
                        }else if(Number(e.amount) <= 200000){
                            setRegistrationFee({registrationFee, amount: 340})
                        }else if(Number(e.amount) <= 1000000){
                            setRegistrationFee({registrationFee, amount: 510})
                        }else if(Number(e.amount) > 1000000){
                            setRegistrationFee({registrationFee, amount: 1700})
                        }
                        let tmpMinStep = (Number(e.amount) / 100) * 1
                        setMinimalStep({
                            amount: tmpMinStep,
                            currency: checkProp(value,'currency') ? value.currency : 'UAH'
                        })
                    }}
                    
                    tax
                    required
                />
                <InputAmount //"Гарантійний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    
                    required
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Розмір кроку аукціону"
                    lable="Розмір кроку аукціону"
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    
                    required
                />
                <InputAmount //"Реєстраційний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee,'amount') ? registrationFee.amount : null,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    
                    required
                />
                <InputAmount //"Витрати на підготовку лота"
                    title="Витрати на підготовку лота"
                    lable="Витрати на підготовку лота"
                    data={{
                        amount: checkProp(preparationValue,'amount') ? preparationValue.amount : null,
                        currency: checkProp(preparationValue,'currency') ? preparationValue.currency : null,
                        addTax: checkProp(preparationValue,'valueAddedTaxIncluded') ? preparationValue.valueAddedTaxIncluded : true
                    }}
                    onChange={(e) => {setPreparationValue(e)}}

                    tax
                    required
                />
                <div style={{height: '20px'}}></div>
                <Checkbox 
                        value={valueAddedTaxCharged === true ? true : false}
                        onChange={setValueAddedTaxCharged}
                        //disabled={true}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>


            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Контактні дані організатора аукціону</div>
                <Input //Повна юридична назва організації або ПІБ
                    label='Повна юридична назва організації або ПІБ'
                    value={identifierLegalName}
                    // onChange={(e)=>{ setAssetHolderInformationName(e) }}
                    disabled={true}
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                    <Input //Ідентифікатори організації
                        label='Повна юридична назва організації'
                        value={identifierLegalName}
                        onChange={(e)=>{ setIdentifierLegalName(e) }}
                        required
                    />
                    <Select 
                        data={{
                            label: 'Ідентифікатори організації',
                            target: identifierScheme,
                            
                            required: true,
                        }}
                        onChange={(e) => { setIdentifierScheme(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'ЄДРПОУ', value: 'UA-EDR'},
                            {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                            {name: 'Паспорт', value: 'UA-PASSPORT'},
                            {name: 'ID-карта', value: 'UA-ID-CARD'},
                            {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                        ]}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                    <Input //Код ЄДРПОУ або ІПН або паспорт
                        label='Код ЄДРПОУ або ІПН або паспорт'
                        value={identifierId}
                        onChange={(e)=>{ setIdentifierId(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                </div>
                <LocationOrg
                    address={address}
                    setAddress={setAddress}
                    dataText='Місцезнаходження організатора'
                    /*disabled={disabledInput(auction, '01', true)}*/
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={contactPointName}
                        onChange={(e)=>{ setContactPointName(e) }}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={contactPointEmail}
                        onChange={(e)=>{ setContactPointEmail(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={contactPointTelephone}
                        onChange={(e)=>{ setContactPointTelephone(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={contactPointFaxNumber}
                        onChange={(e)=>{ setContactPointFaxNumber(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={contactPointUrl}
                        onChange={(e)=>{ setContactPointUrl(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                </div>
                <Input //Інформація щодо підтвердження повноважень
                    label='Інформація щодо підтвердження повноважень'
                    value={assetHolderRepresentativeInfo}
                    onChange={(e)=>{ setAssetHolderRepresentativeInfo(e) }}
                    //required
                />
            </div>

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Особа відповідальна за забезпечення огляду</div>
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={contactPointName2}
                        onChange={(e)=>{ setContactPointName2(e) }}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={contactPointEmail2}
                        onChange={(e)=>{ setContactPointEmail2(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={contactPointTelephone2}
                        onChange={(e)=>{ setContactPointTelephone2(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={contactPointFaxNumber2}
                        onChange={(e)=>{ setContactPointFaxNumber2(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={contactPointUrl2}
                        onChange={(e)=>{ setContactPointUrl2(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                </div>
            </div>

            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={isPerishable === true ? 'disabled' : disabledElement(auction, '11')}
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp
                            let bodyObj = {
                               base: requestBody,
                            }

                            if(auction.status !== null){
                                tmp = createBody(bodyObj, null, auction)
                            }else{
                                tmp = createBody(bodyObj, null, null)
                            }
                            if(auction.id !== null && auction.id !== ''){
                                if (Object.keys(tmp).length !== 0) editAuc(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionAPE