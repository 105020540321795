import { clearDataLot } from "./clearDataItem";

export async function createItemCS(props, body, intendedUseList=null, intendedUse=null, intendedUseListSecond=null, intendedUseSecond=null){
    let route = 'auction'
    if (props.auctionsMy.status === null) route = 'auction-draft'
    if(body.limitedCirculationProperty !== true){
        delete body.limitedCirculationPropertyDescription
    }

    let tmp = []
    if(intendedUse !== null && intendedUse.length > 0){
        for (let j = 0; j < intendedUseList.length; j++){
            for (let i = 0; i < intendedUse.length; i++){
                if(intendedUseList[j].id === intendedUse[i]){
                    tmp[tmp.length] = {
                        scheme: "CVZU",
                        id: intendedUseList[j].id,
                        description: intendedUseList[j].description
                    }
                }
            }
        }
    }
    let tmp1 = []
    if(intendedUseSecond !== null && intendedUseSecond.length > 0){
        for (let j = 0; j < intendedUseListSecond.length; j++){
            for (let i = 0; i < intendedUseSecond.length; i++){
                if(intendedUseListSecond[j].id === intendedUseSecond[i]){
                    tmp1[tmp1.length] = {
                        scheme: "kvtspz",
                        id: intendedUseListSecond[j].classificationId,
                        description: {uk_UA: intendedUseListSecond[j].description}
                    }
                }
                if(tmp1.length > 0) break
            }
            if(tmp1.length > 0) break
        }
    }

    let tmp2 = [...tmp, ...tmp1]
    const array2 = tmp2.filter(element => element !== null);
    body.additionalClassifications = array2
    if(body.additionalClassifications.length === 0) body.additionalClassifications = null

    if (route === 'auction') {
        validateItem(body, props.auctionsMy)
    }

    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}/item`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp
            if(JSON.stringify(json) === '{"message":{"documents":"Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"}}'){
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: 'Для редагування аукціону спочатку необхідно додати документ "Погодження змін до опису лоту, опис причин редагування"'})
            }else{
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        }else{
            clearDataLot(props)
            let endPoint
            if(route === 'auction-draft'){
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}`;
            }else{
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/${route}/${props.auctionsMy.id}`;
            }
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
                  props.setUniversalError(tmp)
                }else{
                    props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                }
            }
            props.changeLoader(false)
        }
    }
}

export async function saveItemCS(props, body, itemId, intendedUseList, intendedUse){
    let route = 'auction'
    if (props.auctionsMy.status === null) route = 'auction-draft'
    if(body.limitedCirculationProperty !== true){
        delete body.limitedCirculationPropertyDescription
    }

    if(body.classification.id[0] === "0" && body.classification.id[1] === "5"){
        let tmp = []
        if(intendedUse !== null && intendedUse?.length > 0){
            for (let j = 0; j < intendedUseList.length; j++){
                for (let i = 0; i < intendedUse.length; i++){
                    if(intendedUseList[j].id === intendedUse[i]){
                        tmp[tmp.length] = {
                            scheme: "CVZU",
                            id: intendedUseList[j].id,
                            description: intendedUseList[j].description
                        }
                    }
                }
            }
        }

        let tmp2 = [...tmp]
        const array2 = tmp2.filter(element => element !== null);
        body.additionalClassifications = array2
        if(body.additionalClassifications.length === 0) body.additionalClassifications = null
    }

    if (route === 'auction') {
        validateItem(body, props.auctionsMy)
    }

    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}/item/${itemId}`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp
            if(JSON.stringify(json) === '{"message":{"documents":"Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"}}'){
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: 'Для редагування аукціону спочатку необхідно додати документ "Погодження змін до опису лоту, опис причин редагування"'})
            }else{
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        }else{
            clearDataLot(props)
            let endPoint
            if(route === 'auction-draft'){
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}`;
            }else{
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/${route}/${props.auctionsMy.id}`;
            }
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
                  props.setUniversalError(tmp)
                }else{
                    props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                }
            }
            props.changeLoader(false)
        }
    }
}

export async function deleteItem(props, aucId, itemId){
    let route = 'auction'
    if (props.auctionsMy.status === null) route = 'auction-draft'

    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let endPoint2
        if(route === 'auction-draft'){
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}`;
        }else{
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/${route}/${props.auctionsMy.id}`;
        }
        props.changeLoader(true)
        const response2 = await fetch(endPoint2, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2)
                props.changeLoader(false)
            }
        }
    }
}

export function getValues(array, value, prop1, prop2){
    for(let key in array){
        if(array[key][prop1] === value){
            return array[key][prop2]
        }
    }
}

export function getValuesUA(array, value, prop1, prop2){
    for(let key in array){
        if(array[key][prop1] === value){
            return array[key][prop2].uk_UA
        }
    }
}


export function validateItem(itemBody, auction) {
    let valid = true

    if (itemBody?.address) {
        if (itemBody?.address?.postalCode?.length !== 5) {
            valid = false
        }
        if (typeof itemBody?.address?.locality?.uk_UA?.length === 'undefined' ||
            itemBody?.address?.locality?.uk_UA?.length <= 1) {
            valid = false
        }
        if (typeof itemBody?.address?.countryName?.uk_UA?.length === 'undefined' ||
            itemBody?.address?.locality?.uk_UA?.length <= 1) {
            valid = false
        }
        if (typeof itemBody?.address?.streetAddress?.uk_UA?.length === 'undefined' ||
            itemBody?.address?.locality?.uk_UA?.length <= 1) {
            valid = false
        }
    }

    if (itemBody?.registrationDetails) {
        if (typeof itemBody?.registrationDetails?.registrationStatus?.length === 'undefined' ||
            itemBody?.address?.locality?.uk_UA?.length <= 1) {
            valid = false
        }
    }

    // console.log(itemBody)
    if (!valid) {
        throw new Error("Будь ласка заповніть всі обов'язкові поля");
    }
}

