import React from 'react';
import styles from './lotInformation.module.css';

import {connect} from 'react-redux';

import {reverseStr, createApostrof} from '../../../model/summNumberToSreang.js';

import TextHalf from './textHalf'
import TextFull from './textFull'

import {modifiDateString} from '../../../redux/modifiDate'
import {viewStringValue} from '../../../api/valueToString.js'

import LotId from './auctionElements/lotId'
import AccessDetailsString from './auctionElements/accessDetails'
import PreviousAuctionIdString from './auctionElements/previousAuctionId'
import RegistrationFeeString from './auctionElements/registrationFee'
import ValueAddedTaxChargedString from './auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './auctionElements/leaseDuration'
import AuctionUrlString from './auctionElements/auctionUrl'
import GuaranteeString from './auctionElements/guarantee'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import CurrentTenantGuaranteeFeeString from './auctionElements/currentTenantGuaranteeFee'
import RegistryObjectIdString from './auctionElements/registryObjectId'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'


const LotInformation = (props) => {
    return (
        <div className={styles.infoBlock}>
            <h3>Інформація про лот</h3>
            <LotId />
            <ValueString />
            <GuaranteeString />
            <RegistrationFeeString />
            {strDiscount(props)}
            {DuchSteap(props)}
            <DescriptionString />
            <AuctionIdString />
            <TenderAttemptsString /> 
            <LotIdentifierString />
            <SellingMethodString />
            <XAdditionalInformationString />
            <MinNumberOfQualifiedBidsString />
            <CurrentTenantGuaranteeFeeString />
            <AccessDetailsString />
            <PreviousAuctionIdString />
            <RegistryObjectIdString />
            <AuctionUrlString />
            <LeaseDurationString />
            {strValuePeriod(props)}
            {strCalcOnBookValue(props)}
            <ValueAddedTaxChargedString />
            {strListType(props)}
            {strLeaseRules(props)}
            <XDocumentRequirements />
        </div>
    )
}



function DuchSteap(props){
    let tmpDutchStepPercent = null
    let tmpDutchStepQuantity = null
    let tmpDutchStepValue = null
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                if(props.auctionId.dutchStep.dutchStepPercent !== null){
                    tmpDutchStepPercent = <TextHalf
                        title='Відсоток зниження, %'
                        data={props.auctionId.dutchStep.dutchStepPercent}
                    />
                }
                if(props.auctionId.dutchStep.dutchStepQuantity !== null){
                    tmpDutchStepQuantity = <TextHalf
                        title='Кількість кроків'
                        data={props.auctionId.dutchStep.dutchStepQuantity}
                    />
                }
                if(props.auctionId.dutchStep.hasOwnProperty('dutchStepValue') === true){
                    if(props.auctionId.dutchStep.dutchStepValue !== null){
                        let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
                        tmpDutchStepValue = <TextHalf
                            title='Розмір кроку голландського раунду, грн'
                            data={tmp}
                        />
                    }
                }
            }
        }
    }
    return (
        <>
            <TextFull
                title="Розмір кроку аукціону"
                data=''
            />  
            {tmpDutchStepPercent}
            {tmpDutchStepQuantity}
            {tmpDutchStepValue}
        </>
    )
}

function strLeaseRules(props){
    if(props.auctionId !== null){
        return (
            <>
                <h4>Правила та умови передачі об'єкта в оренду</h4>
                {strSchedule(props)}
            </>
        )
    }
    return null
}

function strSchedule(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                return (
                    <>
                        <TextFull
                            title="Пропонований графік оренди"
                            data=''
                        />
                        {strScheduleHoursBy(props)}
                        {strScheduleDaysBy(props)}
                        {viewShedulePerion(props)}
                        {strScheduleOther(props)}
                    </>
                )
            }
        }
    }
    return null
}

function strScheduleDaysBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('daysBy') === true){
                    if(props.auctionId.schedule.daysBy !== null){
                        return (
                            <>
                                {strScheduleDaysByValue(props)}
                                {strScheduleDaysByBy(props)}
                            </>
                        )
                    }
                }
            }
        }
    }
    return null
}

function strScheduleDaysByValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('daysBy') === true){
                    if(props.auctionId.schedule.daysBy !== null){
                        if(props.auctionId.schedule.daysBy.hasOwnProperty('value') === true){
                            if(props.auctionId.schedule.daysBy.value !== null){
                                return (
                                    <>
                                        <TextFull
                                            title="Дні"
                                            data={props.auctionId.schedule.daysBy.value}
                                        />  
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleDaysByBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('daysBy') === true){
                    if(props.auctionId.schedule.daysBy !== null){
                        if(props.auctionId.schedule.daysBy.hasOwnProperty('by') === true){
                            if(props.auctionId.schedule.daysBy.by !== null){
                                let tmp = ''
                                if(props.auctionId.schedule.daysBy.by === 'week'){
                                    tmp = 'тиждень'
                                }else if(props.auctionId.schedule.daysBy.by === 'month'){
                                    tmp = 'місяць'
                                }
                                return (
                                    <>
                                        <TextFull
                                            title="За"
                                            data={tmp}
                                        />  
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('hoursBy') === true){
                    if(props.auctionId.schedule.hoursBy !== null){
                        return (
                            <>
                                {strScheduleHoursByValue(props)}
                                {strScheduleHoursByBy(props)}
                            </>
                        )
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursByValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('hoursBy') === true){
                    if(props.auctionId.schedule.hoursBy !== null){
                        if(props.auctionId.schedule.hoursBy.hasOwnProperty('value') === true){
                            if(props.auctionId.schedule.hoursBy.value !== null){
                                return (
                                    <>
                                        <TextFull
                                            title="Години"
                                            data={props.auctionId.schedule.hoursBy.value}
                                        />  
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursByBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('hoursBy') === true){
                    if(props.auctionId.schedule.hoursBy !== null){
                        if(props.auctionId.schedule.hoursBy.hasOwnProperty('by') === true){
                            if(props.auctionId.schedule.hoursBy.by !== null){
                                let tmp = ''
                                if(props.auctionId.schedule.hoursBy.by === 'day'){
                                    tmp = 'день'
                                }else if(props.auctionId.schedule.hoursBy.by === 'week'){
                                    tmp = 'тиждень'
                                }else if(props.auctionId.schedule.hoursBy.by === 'month'){
                                    tmp = 'місяць'
                                }
                                return (
                                    <>
                                        <TextFull
                                            title="За"
                                            data={tmp}
                                        />  
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewShedulePerion(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('period') === true){
                    if(props.auctionId.schedule.period !== null){
                        if(props.auctionId.schedule.period.length > 0){
                            return props.auctionId.schedule.period.map(
                                (e, count) => {
                                    return (
                                        <div className={styles.wrapPeriod}>
                                            <div className={styles.wrapPeriod_mainDate}>
                                                <TextHalf
                                                    title={count+1}
                                                    data=''
                                                />
                                                <TextHalf
                                                    title="Дата початку"
                                                    data={modifiDateString(e.startDate, 1, false)}
                                                />
                                                <TextHalf
                                                    title="Дата завершення"
                                                    data={modifiDateString(e.endDate, 1, false)}
                                                />
                                            </div>
                                            <div className={styles.wrapPeriod_main}>
                                                {viewPeriodWeeks(e.weekdays)}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewPeriodWeeks(data){
    return data
    .filter(
        (e) => {
            return e !== null
        }
    )
    .map(
        (e) => {
            return (
                <div className={styles.wrapPeriod_mainDate}>
                    <TextHalf
                        title="День тижня"
                        data={e.weekday.uk_UA}
                    />
                    <TextHalf
                        title="Початок"
                        data={e.startTime}
                    />
                    <TextHalf
                        title="Кінець"
                        data={e.endTime}
                    />
                </div>
            )
        }
    )
}

function strScheduleOther(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('schedule') === true){
            if(props.auctionId.schedule !== null){
                if(props.auctionId.schedule.hasOwnProperty('other') === true){
                    if(props.auctionId.schedule.other !== null && props.auctionId.schedule.other !== ''){
                        return (
                            <TextHalf
                                title="Інший графік використання"
                                data={props.auctionId.schedule.other}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strDiscount(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true){
            if(props.auctionId.discount !== null){
                return (
                    <>
                        <TextFull
                            title="Знижки"
                            data=''
                        />
                        {strDiscountPercent(props)}
                        <TextFull
                            title="Стартова вартість попереднього аукціону"
                            data=''
                        />
                        {strPreviousAuctionValueAmount(props)}
                    </>
                )
            }
        }
    }
    return null
}

function strDiscountPercent(props){
    if(props.auctionId !== null){
        /*...if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
        ){*/
            if(props.auctionId.hasOwnProperty('discount') === true){
                if(props.auctionId.discount !== null){
                    if(props.auctionId.discount.hasOwnProperty('discountPercent') === true){
                        if(
                            props.auctionId.discount.discountPercent !== null &&
                            props.auctionId.discount.discountPercent !== 0
                        ){
                            return (
                                <>
                                    <TextHalf
                                        title="Розмір знижки від попереднього аукціону, %"
                                        data={props.auctionId.discount.discountPercent}
                                    />
                                </>
                            )
                        }
                    }
                }
            }
        //}
    }
    return null
}

function strPreviousAuctionValueAmount(props){
    let guarantee = null;
    let guaranteeTmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true){
            if(props.auctionId.discount !== null){
                if(props.auctionId.discount.hasOwnProperty('previousAuctionValue') === true){
                    if(props.auctionId.discount.previousAuctionValue !== null){
                        if(String(props.auctionId.discount.previousAuctionValue.amount).indexOf('.') !== -1){
                            guarantee =  String(props.auctionId.discount.previousAuctionValue.amount);
                            guarantee = guarantee.split('.');
                            guaranteeTmp = createApostrof(reverseStr(guarantee[0]));
                            reverseStr(guaranteeTmp);
                        }
                        if(String(props.auctionId.discount.previousAuctionValue.amount).indexOf('.') !== -1){
                            let tmp = guarantee + ' грн.';
                            return (
                                <TextHalf
                                    title="Сума"
                                    data={tmp}
                                />
                            )
                        }else{
                            if(props.auctionId.discount.previousAuctionValue.amount !== 0 && props.auctionId.discount.previousAuctionValue.amount !== null){
                                let tmp = props.auctionId.discount.previousAuctionValue.amount + ' грн.';
                                return (
                                    <TextHalf
                                        title="Сума"
                                        data={tmp}
                                    />
                                )
                            }
                            return null
                        }
                    }
                }
            }
        }
    }
    return null
}

function strListType(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('listType') === true){
            if(props.auctionId.listType !== null){
                let tmp = ''
                if(props.auctionId.listType === 'First'){
                    tmp = 'Перший'
                }
                return (
                    <TextHalf
                        title="Тип переліку, до якого віднесено об'єкт"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

function strCalcOnBookValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('calcOnBookValue') === true){
            if(props.auctionId.calcOnBookValue !== null){
                let tmp
                if(props.auctionId.calcOnBookValue === true){
                    tmp = 'Так'
                }else{
                    tmp = 'Ні'
                }
                return (
                    <TextFull
                        title="Стартова орендна плата розрахована від балансової вартості"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

function strValuePeriod(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('valuePeriod') === true){
            if(props.auctionId.valuePeriod !== null){
                let tmp = ''
                if(props.auctionId.valuePeriod === 'month'){
                    tmp = 'Стартова вартість за місяць'
                }else if(props.auctionId.valuePeriod === 'hour'){
                    tmp = 'Стартова вартість за годину'
                }else if(props.auctionId.valuePeriod === 'day'){
                    tmp = 'Стартова вартість за день'
                }
                return (
                    <TextHalf
                        title="Період розрахунку стартової вартості"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotInformation)
