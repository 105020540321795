import React from 'react';
import styles from './answer.module.css';

import ButtonAnswer from './buttonAnswer/buttonAnswer.js';

import {modifiDateString} from '../../../redux/modifiDate'

import {connect} from 'react-redux';
import {TranslationsUk} from "../../../redux/translations.uk";

const Answer = (props) => {
    //TODO: ДОРАБОТАТЬ ВОПРОСІ В МОНГЕ отключил проверки!!!!

    let answerBlock = 'Наразі немає жодного запитання від учасників'
    if (props.auction?.questions.length > 0) {
        answerBlock = props.auction.questions.map(
            (question) => {
                return (
                    <div className={styles.answerBlock}>
                        {question?.author &&
                            <>
                                <div className={styles.dataBlock}>
                                    <div className={styles.answer}>
                                        <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.author?.legalName}:</p>
                                        <p className={styles.dataDescription}>{question.author.name.uk_UA}</p>
                                    </div>
                                </div>
                                <div className={styles.dataBlock}>
                                    <div className={styles.dataRow}>
                                        <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.author?.telephone?.legalName}:</p>
                                        <p className={styles.dataDescription}>{question.author.telephone}</p>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.author?.faxNumber?.legalName}:</p>
                                        <p className={styles.dataDescription}>{question.author.faxNumber}</p>
                                    </div>
                                </div>
                                <div className={styles.dataBlock}>
                                    <div className={styles.dataRow}>
                                        <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.author?.email?.legalName}:</p>
                                        <p className={styles.dataDescription}>{question.author.email}</p>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.author?.url?.legalName}:</p>
                                        <p className={styles.dataDescription}><a href={question.author.url}
                                                                                 target="_blank"
                                                                                 rel="noreferrer">{question.author.url}</a>
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                        {question?.datePublished &&

                            <div className={styles.dataBlock}>
                                <div className={styles.dataRow}>
                                </div>
                                <div className={styles.dataRow}>
                                    <p className={styles.dataTitle}>{TranslationsUk?.base?.Question?.datePublished.legalName}: {modifiDateString(question.datePublished, 3, false)}</p>
                                </div>
                            </div>
                        }
                        <div
                            className={styles.description}>{TranslationsUk?.base?.Question?.title.legalName}:</div>
                        <h3 className={styles.title}>{question.title.uk_UA}</h3>

                        <div className={styles.question}>
                            <div
                                className={styles.description}>{TranslationsUk?.base?.Question?.description.legalName}</div>
                            <div className={styles.subject}>{question.description.uk_UA}</div>
                        </div>
                        {editButton(props, question)}
                        {buyerButton(props, question)}
                    </div>
                )
            }
        )
    }

    return (
        <div className={styles.answerBlock}>
            {answerBlock}
        </div>
    )
}

function buyerButton(props, question) {
    if (props?.auction?.privateData?.isOwner === true) {
        if (question.answer !== null) {
            return <div className={styles.answer}>
                <div className={styles.description}>Відповідь організатора</div>
                <div className={styles.subject}>{question.answer.uk_UA}</div>
                {question?.dateAnswered &&
                    <div
                        className={styles.description}>{TranslationsUk?.base?.Question?.dateAnswered.legalName}: {modifiDateString(question.dateAnswered, 3, false)}</div>
                }
            </div>
        } else {
            let dateChatStart = null
            let dateChatEnd = null
            if (props.auction.questionPeriod !== null) {
                dateChatStart = new Date(props.auction.questionPeriod.startDate)
                dateChatEnd = new Date(props.auction.questionPeriod.endDate)
            }
            let tmpStart = null
            let tmpEnd = null
            if (dateChatStart !== null && dateChatEnd !== null) {
                tmpStart = dateChatStart.getTime()
                tmpEnd = dateChatEnd.getTime()
                if (tmpStart < Date.now() && tmpEnd > Date.now()) {
                    return <ButtonAnswer
                        itemAnswer={question.id}
                        edit='ANSWER'
                    />
                }
            }
            return answerOwner(question)
        }
    } else {
        return answerOwner(question)
    }
}

function answerOwner(question) {
    if (question?.answer) {
        return (
            <div className={styles.answer}>
                <div className={styles.description}>Відповідь організатора</div>
                <div className={styles.subject}>{question?.answer?.uk_UA}</div>
                <br/>
                {question?.dateAnswered &&
                    <div className={styles.description}>Дата
                        відповіді: {modifiDateString(question.dateAnswered, 3, false)}</div>
                }
            </div>
        )
    }
    return null
}

function editButton(props, i) {
    let dateChatStart = null
    let dateChatEnd = null
    if (props.auction !== null) {
        if (props.auction.questionPeriod !== null) {
            dateChatStart = new Date(props.auction.questionPeriod.startDate)
            dateChatEnd = new Date(props.auction.questionPeriod.endDate)
        }
        let tmpStart = null
        let tmpEnd = null
        let closeEdit = null
        if (i.canUpdateUntil !== null) {
            closeEdit = new Date(i.canUpdateUntil)
            closeEdit = closeEdit.getTime()
            if (closeEdit > Date.now()) {
                if (dateChatStart !== null && dateChatEnd !== null) {
                    tmpStart = dateChatStart.getTime()
                    tmpEnd = dateChatEnd.getTime()
                    if (tmpStart < Date.now() && tmpEnd > Date.now()) {
                        return (
                            <>
                                <ButtonAnswer
                                    item={i}
                                    edit='EDIT'
                                />
                                {i.canUpdateUntil ?
                                    <div className={styles.description + ' ' + styles.help}>Редагування доступне
                                        до {modifiDateString(i.canUpdateUntil, 3, false)}</div>
                                    : null}
                            </>
                        )
                    }
                }
            }
        } else {
            if (dateChatStart !== null && dateChatEnd !== null) {
                tmpStart = dateChatStart.getTime()
                tmpEnd = dateChatEnd.getTime()
                if (tmpStart < Date.now() && tmpEnd > Date.now()) {
                    return (
                        <ButtonAnswer
                            item={i}
                            edit='EDIT'
                        />
                    )
                }
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)