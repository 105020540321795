import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../redux/modifiDate'

import procedure from '../../../redux/procedure';

import ContactPoint from '../../contactPoint/contactPoint'
import {TranslationsUk} from "../../../redux/translations.uk.js";

const InformationAboutTheOrganizer = (props) => {
    let sellingEntity = props.auction?.sellingEntity,
        hasRelatedOrganizations = false
    if (sellingEntity === undefined || sellingEntity === null) {
        sellingEntity = props.auction?.relatedOrganizations?.sellingEntity
        hasRelatedOrganizations = true
    }
    if (sellingEntity === undefined || sellingEntity === null) return null;
    let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }

    return (
        <div className={styles.infoBlock}>
            <h3>{hasRelatedOrganizations ?
                TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.sellingEntity?.legalName ?? "Інформація про Організатора"
                : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.sellingEntity?.legalName ?? "Інформація про Організатора"}</h3>
            <div className={styles.orgTitle}>
                <p className={styles.desc}>{hasRelatedOrganizations ?
                    TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.sellingEntity?.name?.legalName ?? "Найменування організатора"
                    : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.sellingEntity?.name?.legalName ?? "Найменування організатора"}</p>
                <p>{contactName(sellingEntity, props.auction)}</p>
            </div>
            {sellingEntity?.contactPoint &&
                <div className={styles.orgContact1}>
                    <p className={styles.desc}>{hasRelatedOrganizations ?
                        TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.sellingEntity?.contactPoint?.legalName ?? "Контактні дані"
                        : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.sellingEntity?.contactPoint?.legalName ?? "Контактні дані"}</p>
                    <ContactPoint data={sellingEntity?.contactPoint}/>
                </div>
            }
            {sellingEntity?.identifier?.id &&
                <div className={styles.orgId}>
                    <p className={styles.desc}>{hasRelatedOrganizations ?
                        TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.sellingEntity?.identifier?.legalName?.legalName ?? "Код ЄДРПОУ або ІПН або паспорт"
                        : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.sellingEntity?.identifier?.legalName?.legalName ?? "Код ЄДРПОУ або ІПН або паспорт"}</p>
                    <p>{sellingEntity?.identifier?.id}</p>
                </div>
            }
            <div className={styles.orgAdress}>
                <p className={styles.desc}>{hasRelatedOrganizations ?
                    TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.sellingEntity?.address?.legalName ?? "Юридична адреса"
                    : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.sellingEntity?.address?.legalName ?? "Юридична адреса"}</p>
                <p>{contactAddress(sellingEntity)}</p>
            </div>
            {sellingEntity?.address?.addressID?.id &&
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>КОАТУУ</p>
                    <p>{sellingEntity.address.addressID.id}</p>
                </div>
            }
            {sellingEntity?.representativeCertificateNumber &&
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>№ свідоцтва про право на провадження діяльності арбітражного
                        керуючого</p>
                    <p>{sellingEntity?.representativeCertificateNumber}</p>
                </div>
            }
            {sellingEntity?.representativeCertificateDate &&
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Дата видачі про право на провадження діяльності арбітражного
                        керуючого</p>
                    <p>{modifiDateString(sellingEntity.representativeCertificateDate, 1, false)}</p>
                </div>
            }
            {sellingEntity.representativeInfo &&
                <div className={styles.orgTitle}>
                    <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                    <p>{sellingEntity.representativeInfo}</p>
                </div>
            }
        </div>
    )
}

function contactName(sellingEntity, auction) {
    let tmpBreBrd = ''
    if (procedure.isBRE(auction.sellingMethod)
        || procedure.isBRD(auction.sellingMethod)
        || procedure.isBRW(auction.sellingMethod)
    ) {
        tmpBreBrd = 'Арбітражний керуючий '
    }
    return tmpBreBrd + sellingEntity.name?.uk_UA
}


function contactAddress(sellingEntity) {
    let address = ''
    if (sellingEntity?.address?.postalCode !== null) {
        address += sellingEntity?.address?.postalCode + ', '
    }
    if (sellingEntity?.address?.countryName !== null) {
        address += sellingEntity?.address?.countryName?.uk_UA + ', '
    }
    if (sellingEntity?.address?.region !== null) {
        address += sellingEntity?.address?.region?.uk_UA + ', '
    }
    if (sellingEntity?.address?.locality !== null) {
        address += sellingEntity?.address?.locality?.uk_UA + ', '
    }
    if (sellingEntity?.address?.streetAddress !== null) {
        address += sellingEntity?.address?.streetAddress?.uk_UA
    }

    return address
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InformationAboutTheOrganizer)
