import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {changeLoader, setUniversalError} from '../../redux/actions.js';
import { auctionGetId } from '../../redux/actions/auctions/auctions.js';
import {setDataAddress} from '../../redux/createAddress';

import { isLAW } from '../../redux/procedure';
import {getDefaultHeaders} from "../_api/bidApi";

const ProcedureComplite = (props) => {
    return (viewcompleteAuction(props, props.auctionId))
}

function viewcompleteAuction(props, i){
    if(i !== null){
        if(props.auctionId.privateData.isOwner === true){
            if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") return null
            let tmpSellingMethod = null
            if(i.sellingMethod !== null){
                tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
            }
            if( tmpSellingMethod === -1){
                if(isLAW(i.sellingMethod) === true && i.status === 'active_awarded' && i.awards[0].status === 'active'){
                    return <div
                        className={styles.title_button}
                        onClick={
                            () => {
                                completeAuction(props, i.id)
                            }
                        }
                    >
                        Завершення аукціону
                    </div>
                }
                if(i.hasOwnProperty('contracts') === true){
                    if(i?.contracts?.length > 0){
                            for(let t=0; t < i.contracts.length; t++){
                                if(
                                    i.contracts[t].status === 'active'
                                    || i.contracts[t].status === 'paid'
                                    || (
                                        i.contracts[t].status === 'pending'
                                        && props.auctionId.sellingMethod === 'bankRuptcy-withoutAuction'
                                    )
                                ){
                                    return <div
                                        className={styles.title_button}
                                        onClick={
                                            () => {
                                                completeAuction(props, i.id)
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </div>
                                }
                            }

                    }
                }
            }else{
                if(i.status === 'active_qualification'){
                //if(i.hasOwnProperty('awards') === true){
                    //if(i.awards.length > 0){
                        //if(i.awards[0].status === 'active'){
                            return <div
                                className={styles.title_button}
                                onClick={
                                    () => {
                                        completeAuction(props, i.id)
                                    }
                                }
                            >
                                Завершення аукціону
                            </div>
                        //}
                    //}
                //}
                }
            }
        }
    }
    return null
}

async function completeAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)});
        }else{
            props.setDataAddress(json1, 'SET_FULL_AUCTION')
        }
        props.changeLoader(false)
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    setUniversalError,
    changeLoader,
    auctionGetId,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureComplite)