import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeLoader} from '../../../../../redux/actions.js';
import {setDataAddress} from '../../../../../redux/createAddress';
import {bankAccountGet} from '../../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../../redux/actions.js';

import EditAuction from './editAuction'
import EditAuctionTab from './navTitleBar/navTitleBar'
import WindowDocument from '../../../windowDocument/windowDocument'
import WindowItems from "../../../../_CRUD/item/windowItems";

const WindowAuction = ( props ) => {
    function setBaseUrl(){
        if(window.location.pathname.indexOf('/edit/') !== -1){
            return window.location.pathname.split('/edit/');
        }
        return null
    }

    function setPageId(){
        let tmp = setBaseUrl()
        if(tmp !== null){
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`
        props.bankAccountGet(dataBank, header)
    }, [])

    if(props.auctionsMy !== null){
        let texpRegistBlok = null
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === 'landRental-english' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-english' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            texpRegistBlok = <div className={styles.helpBlok}>
                Шановні користувачі! Починаючи з 01.01.2025р. мінімальна заробітна плата в Україні встановлена на рівні 7100 грн. Просимо враховувати це при встановленні реєстраційного та гарантійного внесків!
            </div>
        }


        let tmp
        switch(props.createAuctionTab){
            case 0:
                tmp = <EditAuction />
                break;
            case 1:
                tmp = <WindowItems />
                break;
            case 2:
                tmp = <WindowDocument />
                break;
            default:
                tmp = null
        }
        return (
            <div className={styles.wrapflexBlock}>
                <EditAuctionTab auctionsMy={props.auctionsMy}/>
                {texpRegistBlok}
                {tmp}
            </div>
        )
    }
    return null
}

async function getAucions(props, id){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
        }
    }
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
    }
}

const mapDispatchToProps = {
    changeLoader,
    setDataAddress,
    bankAccountGet,
    setUniversalError
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)