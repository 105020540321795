import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../redux/modifiDate'

import ContactPoints from '../../contactPoint/contactPoint'
import procedure from "../../../redux/procedure";

const CurrentTenantLAE = (props) => {
    if (procedure.isPriority(props?.auction?.sellingMethod)) {
        let tmp,
            currentTenants = props.auction?.currentTenants
        if (currentTenants === undefined || currentTenants === null) {
            currentTenants = props.auction?.relatedOrganizations?.currentTenants
        }
        if (currentTenants === undefined || currentTenants === null) return null;

        tmp = currentTenants.map((currentTenant) => {
            return (
                <div className={styles.infoBlock}>
                    <h4>Інформація про учасника з переважним правом</h4>
                    <div className={styles.orgTitle}>
                        <p className={styles.desc}>Найменування учасника з переважним правом</p>
                        <p>{contactName(currentTenant)}</p>
                    </div>
                    {currentTenant.hasOwnProperty('contactPoint') === true && currentTenant.contactPoint === null
                        ? null
                        : <div className={styles.orgContact}>
                            <p className={styles.desc}>Контактні дані</p>
                            {contactPoint(currentTenant.contactPoint)}
                        </div>
                    }
                    {currentTenant.hasOwnProperty('representativeInfo') !== true
                        ? null
                        : <div className={styles.orgContact}>
                            <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                            <p>{currentTenant.representativeInfo}</p>
                        </div>
                    }
                    {currentTenant.hasOwnProperty('currentTenantValue') !== true && currentTenant.currentTenantValue !== null
                        ? null
                        : <div className={styles.orgContact}>
                            <p className={styles.desc}>Орендна ставка (сума на рік)</p>
                            <p>{currentTenant?.currentTenantValue?.amount} грн.</p>
                        </div>
                    }
                    <div className={styles.orgId}>
                        <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                        <p>{contactEDRPOU(currentTenant)}</p>
                    </div>
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>Юридична адреса</p>
                        <p>{contactAddress(currentTenant)}</p>
                    </div>
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>КОАТУУ</p>
                        <p>{contactKOATUU(currentTenant)}</p>
                    </div>
                    {currentContractTime(currentTenant)}
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>Пріорітет учасника</p>
                        <p>{checkCurrentTenantsPriority(currentTenant)}</p>
                    </div>
                </div>
            )
        })
        return (
            <div className={styles.infoBlock}>
                <h3>Дані про учасників з переважним правом</h3>
                {tmp}
            </div>
        )
    }
    return null
}

function checkCurrentTenantsPriority(props){
    if(props.priority === 0) return "З переважним правом"
    if(props.priority === 1) return "З переважним правом другої черги"
    return null
}

function contactKOATUU(props){
    if(props.hasOwnProperty('address') !== true) return null
    if(props.address === null) return null
    if(props.address.hasOwnProperty('addressID') !== true) return null
    if(props.address.addressID === null) return null
    if(props.address.addressID.hasOwnProperty('id') !== true) return null
    if(props.address.addressID.id === null) return null
    return props.address.addressID.id
}

function contactName(props){
    if(props.hasOwnProperty('name') !== true) return null
    if(props.name === null) return null
    return props.name.uk_UA
}

function currentContractTime(props){
    let tmp = ''
    if(props.hasOwnProperty('currentContractTime') !== true) return null
    if(props.currentContractTime === null) return null
    if(
        props.currentContractTime.dateFrom !== null &&
        props.currentContractTime.dateTill !== null
    ){
        return (
            <>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Дата укладення та закінчення договору оренди</p>
                    <p></p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час початку</p>
                    <p>{modifiDateString(props.currentContractTime.dateFrom, 3)}</p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час закінчення</p>
                    <p>{modifiDateString(props.currentContractTime.dateTill, 3)}</p>
                </div>
            </>
        )
    }
    return tmp
}

function contactPoint(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

function contactEDRPOU(props){
    if(props.hasOwnProperty('identifier') !== true) return null
    if(props.identifier === null) return null
    if(props.identifier.id === null) return null
    return props.identifier.id
}

function contactAddress(props){
    let tmp = ''
    if(props.hasOwnProperty('address') !== true) return null
    if(props.address === null) return null
    if(props.address.hasOwnProperty('postalCode')){
        if(props.address.postalCode !== null){
            tmp += props.address.postalCode + ', '
        }
    }
    if(props.address.hasOwnProperty('countryName')){
        if(props.address.countryName !== null){
            tmp += props.address.countryName.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('region')){
        if(props.address.region !== null){
            tmp += props.address.region.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('locality')){
        if(props.address.locality !== null){
            tmp += props.address.locality.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('streetAddress')){
        if(props.address.streetAddress !== null){
            tmp += props.address.streetAddress.uk_UA
        }
    }                                    
    return tmp
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(CurrentTenantLAE)
