import React, {useState, useEffect} from 'react';
import styles from './procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../redux/translations.uk.js';

import {isMongo, checkProp} from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Button from '../../../../elements/buttons/button';
import InputAmount from '../../../../elements/inputs/inputAmount';
import InputDate from '../../../../elements/inputs/inputDate';
import Checkbox from '../../../../elements/inputs/checkBox';

import LeaseDuration from '../../../elems/leaseDuration/leaseDuration';
import LeaseRules from './leaseRules/leaseRules';

import BankAccount from '../bankAccount/bankAccount';

import {disabledElement} from '../../../disabled';

import {createBody} from './lib/createBody';
import {setTenderAttemptList} from './lib/utils';

import {createAuc, editAuc, editAucPatch} from '../../createEditAndCloneAuction/edit/editTabMonga'
import SlimmerBlock from "../../../../elements/slimmerBlock/slimmerBlock";
import RelatedOrganizations from "./llp/relatedOrganizations";

const AuctionCLP = ({data, ...props}) => {
    const auction = useSelector(state => state.start.auctionsMy)

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 1)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(
        checkProp(data, 'minNumberOfQualifiedBids') && data.minNumberOfQualifiedBids !== null && data.minNumberOfQualifiedBids !== "null" ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [discountPercent, setDiscountPercent] = useState(checkProp(data, 'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data, 'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(data?.value ?? null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') ? data.valueAddedTaxCharged && data.valueAddedTaxCharged !== null && data.valueAddedTaxCharged !== 'null' : true)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data, 'bankAccounts') ? data.bankAccounts : [])
    const [discount, setDiscount] = useState(checkProp(data, 'discount') && checkProp(data.discount, 'discount') ? data.discount.discount : false)

    const [leaseDuration, setLeaseDuration] = useState(checkProp(data, 'leaseDuration') ? data.leaseDuration : null)
    const [valuePeriod, setValuePeriod] = useState(checkProp(data, 'valuePeriod') && data.valuePeriod !== null && data.valuePeriod !== 'null' ? data.valuePeriod : 'month')
    const [schedule, setSchedule] = useState(checkProp(data, 'schedule') ? data.schedule : null)

    const [currentTenantsEdited, setCurrentTenantsEdited] = useState(false)
    const [bankAccountsEdited, setBankAccountsEdited] = useState(false)
    const [scheduleEdited, setScheduleEdited] = useState(false)


    useEffect(() => {
        if (checkProp(data, 'schedule')) setSchedule(data.schedule)
    }, [])

    const [currentTenantGuaranteeFee, setCurrentTenantGuaranteeFee] = useState( data?.currentTenantGuaranteeFee ?? null)

    const [currentTenants, setCurrentTenants] = useState(
        (data?.relatedOrganizations?.currentTenants?.length > 0) ? data.relatedOrganizations.currentTenants : [{}]
    )


    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        accessDetails: accessDetails === null || accessDetails === ''
            ? null
            : {uk_UA: accessDetails},
        x_documentRequirements: x_documentRequirements === null || x_documentRequirements === ""
            ? null
            : {uk_UA: x_documentRequirements},
        x_additionalInformation: x_additionalInformation === null || x_additionalInformation === ''
            ? null
            : {uk_UA: x_additionalInformation},
        minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value, 'currency') ? value.currency : null,
            amount: checkProp(value, 'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value, 'addTax') ? value.addTax : true
        },
        minimalStep: {
            currency: checkProp(minimalStep, 'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep, 'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee, 'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee, 'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee, 'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee, 'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        discount: {
            discount: discount,
            discountPercent: discountPercent,
            previousAuctionValue: {
                currency: checkProp(previousAuctionValue, 'currency') ? previousAuctionValue.currency : null,
                amount: checkProp(previousAuctionValue, 'amount') ? +previousAuctionValue.amount : null,
                valueAddedTaxIncluded: checkProp(previousAuctionValue, 'addTax') ? previousAuctionValue.addTax : null
            }
        },
        bankAccounts: bankAccounts,

        leaseDuration: leaseDuration,
        valuePeriod: valuePeriod,
        schedule: schedule,


        currentTenantGuaranteeFee: {
            currency: checkProp(currentTenantGuaranteeFee, 'currency') ? currentTenantGuaranteeFee.currency : null,
            amount: (Number(currentTenantGuaranteeFee?.amount) && Number(currentTenantGuaranteeFee?.amount) <= Number(guarantee?.amount))
                ? +currentTenantGuaranteeFee.amount
                : (guarantee?.amount ? +guarantee.amount : null)
        },
        relatedOrganizations: {
            currentTenants: currentTenantsWithoutContacts()
        }
    }

    function cleanRequestBody(requestBody) {
        let multiLangFields = ['title', 'description', 'accessDetails', 'x_documentRequirements', 'x_additionalInformation',],
            baseFields = [
                'lotId', 'previousAuctionId', 'tenderAttempts',
                'discount', 'valuePeriod', 'leaseDuration', 'minNumberOfQualifiedBids',
                'numberOfCurrentTenants',
            ],
            valueFields = ['value',
                'valueAddedTaxCharged',
                'minimalStep',
                'guarantee',
                'registrationFee',
                'currentTenantGuaranteeFee',
            ]

        // bankAccounts
        // relatedOrganizations і всі вкладені поля

        for (let i in baseFields) {
            if (data[baseFields[i]] === requestBody[baseFields[i]]) {
                delete requestBody[baseFields[i]]
            }
        }
        for (let i in multiLangFields) {
            if (
                (data[multiLangFields[i]]?.uk_UA === requestBody[multiLangFields[i]]?.uk_UA) ||
                (data[multiLangFields[i]] === requestBody[multiLangFields[i]]?.uk_UA)
            ) {
                delete requestBody[multiLangFields[i]]
            }
        }
        for (let i in valueFields) {
            if (
                (data[valueFields[i]]?.amount === requestBody[valueFields[i]]?.amount) ||
                (data[valueFields[i]] === requestBody[valueFields[i]]?.amount)
            ) {
                delete requestBody[valueFields[i]]
            }
        }

        //discount
        if (data?.discount === requestBody?.discount ) {
            delete requestBody.discount
        }
        if (
            (
                (data?.discount?.previousAuctionValue?.amount === requestBody?.discount?.previousAuctionValue?.amount) ||
                (data?.discount?.previousAuctionValue === requestBody?.discount?.previousAuctionValue?.amount)
            )
            && (data?.discount?.discount === requestBody?.discount?.discount)
            && (data?.discount?.discountPercent === requestBody?.discount?.discountPercent)
        ) {
            delete requestBody.discount
        }

        // console.log('currentTenantsEdited', currentTenantsEdited)
        // console.log('bankAccountsEdited', bankAccountsEdited)
        // console.log('scheduleEdited', scheduleEdited)

        if (currentTenantsEdited !== true) {
            delete requestBody.relatedOrganizations
        }

        if (bankAccountsEdited !== true) {
            delete requestBody.bankAccounts
        }

        if (scheduleEdited !== true) {
            delete requestBody.schedule
        }

        if (data?.auctionPeriod && data.auctionId !== null) {
            delete requestBody.auctionPeriod
        }

        return requestBody
    }

    function currentTenantsWithoutContacts() {
        if (currentTenants !== null && currentTenants.length > 0) {
            let tmp = []
            for (let i = 0; i < currentTenants.length; i++) {
                tmp[i] = {
                    identifier: currentTenants[i].identifier,
                    address: currentTenants[i].address,
                    representativeInfo: currentTenants[i].representativeInfo,
                    currentContractTime: currentTenants[i].currentContractTime
                }
            }
            return tmp
        }
        return currentTenants
    }

    function discountBlock() {
        if (tenderAttempts > 1) {
            return (
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.previousAuctionId.legalName}
                            value={previousAuctionId}
                            onChange={(e) => {
                                setPreviousAuctionId(e)
                            }}
                            disabled={disabledElement(auction, '01')}
                            required
                        />
                    </div>
                    {discount
                        ?
                        <>
                            <Checkbox
                                value={discount}
                                onChange={setDiscount}
                                disabled={disabledElement(auction, '01')}
                            >
                                Застосувати знижку
                            </Checkbox>
                            <div
                                // className={styles.twoInLine}
                                style={{
                                    width: '60%',
                                    height: '180px',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue, 'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue, 'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue, 'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                    }}
                                    onChange={(e) => {
                                        setPreviousAuctionValue(e)
                                        if (discount !== null
                                            && discount === true
                                            && discountPercent !== null
                                            && discountPercent !== ''
                                            && e.amount !== null
                                            && e.amount !== ''
                                        ) {
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(e.amount) - (Number(e.amount) / 100) * Number(discountPercent),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                        }
                                    }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.discount.discountPercent.legalName}
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e) => {
                                        setDiscountPercent(e)
                                        if (discount !== null
                                            && discount === true
                                            && e !== null
                                            && e !== ''
                                            && previousAuctionValue.amount !== null
                                            && previousAuctionValue.amount !== ''
                                        ) {
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(previousAuctionValue.amount) - (Number(previousAuctionValue.amount) / 100) * Number(e),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                        }
                                    }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                        :
                        <Checkbox
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }

    return (
        <>
            <Input //"Номер лота"
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.lotId.legalName}
                value={lotId}
                onChange={(e) => {
                    setLotId(e)
                }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <Input
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.title.legalName}
                value={title}
                onChange={(e) => {
                    setTitle(e)
                }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <TextArea 
                value={description}
                disabled={disabledElement(auction, '01')}
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.description.legalName}
                required
                onChange={(e) => {
                    setDescription(e)
                }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.tenderAttempts.legalName,
                    target: tenderAttempts,

                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => {
                    setTenderAttempts(e)
                }}
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.accessDetails.legalName}
                value={accessDetails}
                onChange={(e) => {
                    setAccessDetails(e)
                }}
                disabled={disabledElement(auction, '01')}
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.x_documentRequirements.legalName}
                value={x_documentRequirements}
                onChange={(e) => {
                    setX_documentRequirements(e)
                }}
                disabled={disabledElement(auction, '01')}
            />
            <Input
                label={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e) => {
                    setX_additionalInformation(e)
                }}
                disabled={disabledElement(auction, '01')}
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.minNumberOfQualifiedBids.legalName,
                    target: minNumberOfQualifiedBids,

                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => {
                    setMinNumberOfQualifiedBids(e)
                }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: 1, name: '1'},
                        {value: 2, name: '2'}
                    ]
                }
            />

            <SlimmerBlock
                title='Дані про учасника з переважним правом'
                content={
                    <RelatedOrganizations
                        data={{
                            name: "Дані про учасника з переважним правом",
                            i: 0,
                            count: 0,
                            currentTenants: currentTenants,
                            setCurrentTenants: setCurrentTenants,
                            disabled:false,
                            editFlagSwitch: setCurrentTenantsEdited
                        }}
                    />
                }
                slim={false}
            />


            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InputAmount //"Стартова ціна лота"
                    title={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.value.legalName}
                    lable={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(value, 'amount') ? value.amount : null,
                        currency: checkProp(value, 'currency') ? value.currency : null,
                        addTax: checkProp(value, 'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                    }}
                    onChange={(e) => {
                        setValue(e)
                        if (Number(e.amount) <= 20000) {
                            setRegistrationFee({registrationFee, amount: 17})
                        } else if (Number(e.amount) <= 50000) {
                            setRegistrationFee({registrationFee, amount: 119})
                        } else if (Number(e.amount) <= 200000) {
                            setRegistrationFee({registrationFee, amount: 340})
                        } else if (Number(e.amount) <= 1000000) {
                            setRegistrationFee({registrationFee, amount: 510})
                        } else if (Number(e.amount) > 1000000) {
                            setRegistrationFee({registrationFee, amount: 1700})
                        }
                    }}
                    disabled={disabledElement(auction, '01')}
                    tax
                    required
                />
                <InputAmount //"Гарантійний внесок"
                    title={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.guarantee.legalName}
                    lable={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee, 'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee, 'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => {
                        if (e.amount === null) return
                        if (Number(e.amount) === Number(guarantee?.amount)) return
                        if (Number(e.amount) <= 0) e.amount = 0.01
                        setGuarantee(e)
                        // setCurrentTenantGuaranteeFee(e)
                    }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount //"Розмір кроку аукціону"
                    title={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.minimalStep.legalName}
                    lable={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep, 'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep, 'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => {
                        setMinimalStep(e)
                    }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount //"Реєстраційний внесок"
                    title={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.registrationFee.legalName}
                    lable={TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee, 'amount') ? registrationFee.amount : null,
                        currency: checkProp(registrationFee, 'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => {
                        setRegistrationFee(e)
                    }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount
                    title="Гарантійний внесок чинного орендаря *"
                    lable="Гарантійний внесок чинного орендаря"
                    placeholder={guarantee?.amount ? Number(guarantee?.amount) : ''}
                    data={{
                        amount: Number(currentTenantGuaranteeFee?.amount) > 0 ? currentTenantGuaranteeFee?.amount : null,
                        currency: checkProp(currentTenantGuaranteeFee, 'currency') ? currentTenantGuaranteeFee.currency : null,
                    }}
                    onChange={(e) => {
                        if (e.amount === null) setCurrentTenantGuaranteeFee(null)
                        if ((Number(currentTenantGuaranteeFee?.amount) !== Number(e.amount))) {
                            if ((Number(e.amount) > 0) && (Number(e.amount) <= Number(guarantee?.amount))) {
                                setCurrentTenantGuaranteeFee({amount: Number(e.amount), currency: 'UAH'})
                            }
                            if (Number(e.amount) <= 0) setCurrentTenantGuaranteeFee({amount: null, currency: 'UAH'})
                            if (Number(e.amount) > Number(guarantee?.amount)) {
                                setCurrentTenantGuaranteeFee({amount: Number(guarantee?.amount), currency: 'UAH'})
                            }
                            return e
                        }
                    }}
                />
                <div style={{height: '20px'}}></div>
                <Checkbox
                    value={valueAddedTaxCharged}
                    onChange={setValueAddedTaxCharged}
                    disabled={disabledElement(auction, '01')}
                >
                    На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>

            <Select
                data={{
                    label: 'Період розрахунку стартової вартості',
                    target: valuePeriod,

                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => {
                    setValuePeriod(e)
                }}
                options={
                    [
                        {value: 'null', name: 'Обрати'},
                        {value: 'month', name: 'Стартова вартість за місяць'},
                        {value: 'day', name: 'Стартова вартість за день'},
                        {value: 'hour', name: 'Стартова вартість за годину'}
                    ]
                }
            />
            <LeaseDuration data={leaseDuration} setData={setLeaseDuration}/>
            <LeaseRules data={schedule}
                        main={checkProp(data, 'schedule') ? data.schedule : null}
                        updateData={setSchedule}
                        editFlagSwitch={setScheduleEdited}
            />

            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['commercialPropertyLease-priorityEnglish'].commercialPropertyLeasePriorityEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={e => setStartDate(e)}
                    disabled={data.auctionId ? 'disabled' : false}
                    required
                />
            </div>
            <BankAccount
                bankAccounts={bankAccounts}
                setBankAccounts={setBankAccounts}
                editFlagSwitch={setBankAccountsEdited}
            />
            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () => {
                            let tmp
                            let bodyObj = {
                                base: cleanRequestBody(requestBody),
                            }
                            tmp = createBody(bodyObj, data)
                            if (auction.id !== null && auction.id !== '') {
                                if (data?.auctionId) {
                                    editAucPatch(props.updateAuc, tmp)
                                } else editAuc(props.updateAuc, tmp)
                            } else {
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionCLP