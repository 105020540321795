import React from 'react';
import styles from './categoryMenu.module.css';

import ComponentScrap from './scrap/scrap.js';
import ComponentNadra from './nadra/nadra.js';
import ComponentRailwayCargo from './railwayCargo/railwayCargo.js';
import ComponentDgf from './dgf/dgf.js';
import ComponentLegitimatePropertyLease from './legitimatePropertyLease/legitimatePropertyLease';
import ComponentLandRental from './landRental/landRental';
import ComponentLandSell from './landSell/landSell';
// import ComponentBasicSellLand from './basicSell/basicSellLand';
// import ComponentBasicSellJpc from './basicSell/basicSellJPC';
// import ComponentBasicSellVehicle from './basicSell/basicSellVehicle';
// import ComponentBasicSellRealEstate from './basicSell/basicSellRealEstate';
// import ComponentBasicSellEtc from './basicSell/basicSellEtc';
import ComponentBasicSell from './basicSell/basicSell';
import ComponentAlienation from './alienation/alienation';
import ComponentBankRuptcy from './bankRuptcy/bankRuptcy';
import ComponentCommercialSell from './commercialSell/commercialSell';
import RegulationsPropertyLease from './rle_rld/rle_rld';
import CommercialPropertyLease from './cle_cld/cle_cld';
import SmallPrivatization from './smallPrivatization/smallPrivatization';
import NLE from './nle/nle';
import LAE from './landArrested/landArrested';
import LargePrivatization from './largePrivatization/largePrivatization';
import APE from './APE/ape';
import Legacy from './legacy/legacy';

import { setDataAddress } from '../../../redux/createAddress';

import { connect } from 'react-redux';

const CategoryMenu = (props) => {
  function clearSearch() {
    props.setDataAddress(null, 'SEARCH_CLASSIFICATION');
    props.setDataAddress(null, 'SEARCH_REGION');
    props.setDataAddress('null', 'SEARCH_ADDITIONAL_CLASSIFICATION');
    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION');
    props.setDataAddress(null, 'CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS');
    props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_REGION');
    props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY');
    props.setDataAddress('', 'SEARCH_AREA_FROM');
    props.setDataAddress('', 'SEARCH_AREA_TO');
    props.setDataAddress(null, 'SET_LAND_AREA_FROM');
    props.setDataAddress(null, 'SET_LAND_AREA_TO');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_FROM_YEAR');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_FROM_MONTH');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_FROM_DAY');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_TO_YEAR');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_TO_MONTH');
    props.setDataAddress('', 'SEARCH_LEASE_DURATION_TO_DAY');
    props.setDataAddress(null, 'SET_CADASTRAL_NUMBER');
    props.setDataAddress('null', 'SEARCH_ITEM_TYPE');
    props.setDataAddress('null', 'SEARCH_IS_PERISHABLE');
  }

  return (
    <div className={styles.aukpoisk_second}>
      <SmallPrivatization clearSearch={clearSearch} />
      <ComponentLegitimatePropertyLease clearSearch={clearSearch} />
      <LAE clearSearch={clearSearch} />
      <ComponentLandRental clearSearch={clearSearch} />
      <ComponentLandSell clearSearch={clearSearch} />
      <ComponentDgf clearSearch={clearSearch} />
      <NLE clearSearch={clearSearch} />
      {/* <ComponentBasicSellRealEstate clearSearch={clearSearch} /> */}
      {/* <ComponentBasicSellVehicle clearSearch={clearSearch} /> */}
      {/* <ComponentBasicSellJpc clearSearch={clearSearch} /> */}
      {/* <ComponentBasicSellLand clearSearch={clearSearch} /> */}
      <ComponentBankRuptcy clearSearch={clearSearch} />
      <ComponentAlienation clearSearch={clearSearch} />
      <ComponentRailwayCargo clearSearch={clearSearch} />
      <ComponentNadra clearSearch={clearSearch} />
      <ComponentBasicSell clearSearch={clearSearch} />
      {/* <ComponentBasicSellEtc clearSearch={clearSearch} /> */}
      <ComponentCommercialSell clearSearch={clearSearch} />
      <CommercialPropertyLease clearSearch={clearSearch} />
      <RegulationsPropertyLease clearSearch={clearSearch} />
      <ComponentScrap clearSearch={clearSearch} />
      <LargePrivatization clearSearch={clearSearch} />
      <APE clearSearch={clearSearch} />
      <Legacy clearSearch={clearSearch} />
      {/*<SearchREM clearSearch={clearSearch} />*/}
    </div>
  );
};

const mapDispatchToProps = {
  setDataAddress
};

export default connect(null, mapDispatchToProps)(CategoryMenu);
