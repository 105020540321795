import React from 'react';
import styles from './auctionResultTab.module.css';

import Title from '../../../../product/title/title.js'
import Tabs from '../../../../product/tabs/tabs'

import {connect} from 'react-redux';

import ProtocolLink from '../../../../product/protocolLink/protocolLink';
import Cancellations from '../../../../product/cancellationsNew/newAward'

import ViewAwardList from "../../../award/view/viewAwardList";
import EditAwardList from "../../../award/edit/editAwardList";
import procedure from "../../../../../redux/procedure";
import ContractList from "../../../contract/ContractList";
import SidebarRightBlockOld from "../sidebarRightBlock/sidebarRightBlockOld";
import SidebarRightBlock from "../sidebarRightBlock/sidebarRightBlock";

const AuctionResultTab = (props) => {
    if (props.auction === null) return null
    return (
        <div className={styles.wrapNew}>
            <div className={styles.mainBlock}>
                <Title/>
                <div className={styles.aucProperties}>
                    <Tabs/>
                    <ViewAwardList showWinners={true}/>
                    <ViewAwardList showWinners={false}/>
                    <EditAwardList/>
                    {procedure.isLAW(props.auction.sellingMethod) !== true ? <ContractList/> : null}
                    <Cancellations/>
                    {procedure.isLAW(props.auction.sellingMethod) ? null : <ProtocolLink/>}
                </div>
            </div>
            {procedure.isREM(props.auction.sellingMethod)
                ?
                <SidebarRightBlock/>
                :
                <SidebarRightBlockOld/>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(AuctionResultTab)