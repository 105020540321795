import {getDefaultHeaders} from "../_api/bidApi";

export async function getMyAuctionId(props) {
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
    let route = 'public/auction'
    if (props.auctionsMy.status === null) route = 'private/auction-draft'
    // if (
    //     isLRE(props.auctionsMy.sellingMethod) || isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod)
    //     || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)
    //     || isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
    //     isALE(props.auctionsMy.sellingMethod) ||
    //     isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
    //     isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) || isBRW(props.auctionsMy.sellingMethod) ||
    //     isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
    //     isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
    //     isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
    //     isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
    //     isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
    //     isLPE(props.auctionsMy.sellingMethod) ||
    //     props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards') ||
    //     isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
    // ) {
      if(route === 'private/auction-draft'){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/${route}/${props.auctionsMy.id}`;
      }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/${route}/${props.auctionsMy.id}/sync?` + Math.random();
      }
    // }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
          props.setUniversalError(tmp)
        }else{
            await props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
    }
  }
}
