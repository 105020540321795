import {
    addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount, addAuctionPeriodProperty,
    addBankProperty, addSchedule
} from './utilsCreateBody'

export function createBodyRLP(data, oldData) {
    if (data !== null) {
        let body = {}
        body = data?.base?.title ? addPropertyMonga(data, body, 'title') : body
        body = data?.base?.description ? addPropertyMonga(data, body, 'description') : body
        body = data?.base?.accessDetails ? addPropertyMonga(data, body, 'accessDetails') : body
        body = data?.base?.tenderAttempts ? addProperty(data, body, 'tenderAttempts') : body
        body = data?.base?.lotId ? addProperty(data, body, 'lotId') : body
        body = data?.base?.auctionPeriod ? addAuctionPeriodProperty(data, body, 'auctionPeriod') : body
        body = data?.base?.x_documentRequirements ? addPropertyMonga(data, body, 'x_documentRequirements') : body
        body = data?.base?.x_additionalInformation ? addPropertyMonga(data, body, 'x_additionalInformation') : body
        body = data?.base?.minNumberOfQualifiedBids ? addProperty(data, body, 'minNumberOfQualifiedBids') : body
        body = data?.base?.valueAddedTaxCharged ? addProperty(data, body, 'valueAddedTaxCharged') : body
        body = data?.base?.value ? addPropertyValue(data, body, 'value') : body
        body = data?.base?.minimalStep ? addPropertyValue(data, body, 'minimalStep') : body
        body = data?.base?.guarantee ? addPropertyValue(data, body, 'guarantee') : body
        body = data?.base?.registrationFee ? addPropertyValue(data, body, 'registrationFee') : body

        body = data?.base?.currentTenantGuaranteeFee ? addPropertyValue(data, body, 'currentTenantGuaranteeFee') : body

        body = data?.base?.previousAuctionId ? addProperty(data, body, 'previousAuctionId') : body
        body = data?.base?.discount ? addPropertyDiscount(data, body, 'discount') : body
        body = data?.base?.bankAccounts ? addBankProperty(data, body, 'banks') : body
        body = data?.base?.leaseDuration ? addProperty(data, body, 'leaseDuration') : body
        body = data?.base?.valuePeriod ? addProperty(data, body, 'valuePeriod') : body
        body = data?.base?.schedule ? addSchedule(data, body, 'schedule') : body

        body.leaseRules = data.base.leaseRules
        body.leaseDuration = data.base.leaseDuration
        body.relatedOrganizations = data.base.relatedOrganizations
        // body.currentTenantGuaranteeFee = data.base.currentTenantGuaranteeFee
        body.valuePeriod = data.base.valuePeriod

        if (body?.auctionPeriod?.startDate === null) {
            body.auctionPeriod = null
        }
        // if (body?.minimalStep?.amount === null || body?.minimalStep?.amount === 0) {
        //     body.minimalStep = null
        // }
        // if (body?.currentTenantGuaranteeFee?.amount === null || body?.currentTenantGuaranteeFee?.amount <= 0) {
        //     body.currentTenantGuaranteeFee = null
        // }
        if (body?.title?.uk_UA?.length === 0) {
            body.title = null
        }
        if (body?.description?.uk_UA?.length === 0) {
            body.description = null
        }
        if (body?.schedule === oldData?.schedule) {
            delete body.schedule
        }
        if (body?.relatedOrganizations?.currentTenants === oldData?.relatedOrganizations?.currentTenants) {
            delete body.relatedOrganizations
        }

        if (body?.relatedOrganizations?.currentTenants?.length > 0) {
            for (let i = 0; i < body?.relatedOrganizations?.currentTenants?.length; i++) {
                let currentTenant = body.relatedOrganizations.currentTenants[i]

                if (currentTenant?.identifier?.id === null || typeof currentTenant?.identifier?.id === "undefined") {
                    currentTenant.identifier = null
                }

                Object.keys(currentTenant).forEach((k) => currentTenant[k] == null && delete currentTenant[k]);

                body.relatedOrganizations.currentTenants[i] = currentTenant
                if (currentTenant?.length === 0 || Object.keys(currentTenant)?.length === 0) {
                    body.relatedOrganizations.currentTenants.splice(i, 1)
                }
            }
        }
        if (body?.relatedOrganizations?.currentTenants?.length === 0) {
            body.relatedOrganizations.currentTenants = null
        }
        // if (body?.relatedOrganizations?.identifier?.id === null) {
        //     body.relatedOrganizations.identifier = null
        // }
        // if (
        //     body?.relatedOrganizations?.identifier === null
        //     &&body?.relatedOrganizations?.address === null
        // ) {
        //     body.relatedOrganizations = null
        // }
        return body
    }
    return null
}