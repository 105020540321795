import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import {viewStringValue} from '../../../../api/valueToString.js'
import {TranslationsUk} from "../../../../redux/translations.uk";

const MinimalStepString = (props) => {
    if(props?.auction?.minimalStep) {
        let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
        if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
            procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
        }
        return (
            <TextFull
                title={TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.minimalStep?.legalName ?? "Мінімальний крок аукціону"}
                data={viewStringValue(props.auction.minimalStep)}
            />
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(MinimalStepString)