import React, {useState} from 'react';
import styles from './history.module.css';

import { connect } from 'react-redux';
import { dateTransform } from '../../../redux/modifiDate.js'
import { checkProp } from '../../../redux/check';
import thumbnailApi from "../../_api/thumbnailsApi";

const HistoryDocItem = (props) => {
    const [showImagePopup, setShowImagePopup] = useState(false)

    function imagePopUp(document) {
        return <div className={styles.imageWrapperBg}>
            <div className={styles.imageWrapper}>
                <div className={styles.popUpClose}
                     onClick={() => setShowImagePopup(!showImagePopup)}>&times;
                </div>
                <div className={styles.image}>
                    <img src={thumbnailApi.getDocumentThumbnailLink(document, 1200, 800)}
                         alt={checkProp(document.title, 'uk_UA') ? document.title.uk_UA : document.title}/>
                </div>
            </div>
        </div>
    }

    function viewImg(document) {
        return (
            <div className={styles.lotImage}>
                <div className={styles.imgHover} onClick={() => setShowImagePopup(!showImagePopup)}>
                    <div className={styles.zoomIcon}>
                        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -5 30 30">
                            <g id="Слой_2" data-name="Слой 2">
                                <g id="Слой_1-2" data-name="Слой 1">
                                    <path className={styles.cls} fill="#fff"
                                          d="M18.14,16.86,11.2,10a6.09,6.09,0,0,0,1.32-3.8A6.26,6.26,0,0,0,0,6.2a6.23,6.23,0,0,0,6.26,6.2,6.11,6.11,0,0,0,3.62-1.16l7,6.9a.86.86,0,0,0,.62.26.88.88,0,0,0,.64-.26A.93.93,0,0,0,18.14,16.86ZM1.6,6.2a4.66,4.66,0,0,1,9.32,0,4.66,4.66,0,0,1-9.32,0Zm7.78.1a.71.71,0,0,1-.7.7H7V8.6a.7.7,0,1,1-1.4,0V7H3.82a.7.7,0,1,1,0-1.4H5.6V3.78a.7.7,0,0,1,1.4,0V5.6H8.68A.7.7,0,0,1,9.38,6.3Z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                {(document.documentType === 'illustration') ?
                    <img src={thumbnailApi.getDocumentThumbnailLink(document, 150, 150)}
                         alt={checkProp(document.title, 'uk_UA') ? document.title.uk_UA : document.title}/>
                    :
                    <img src="https://www.dto.com.ua/images/doc.png" alt=""/>
                }
            </div>
        )
    }

    function viewLink(data, scope) {
        let url = data?.url ?? null
        if (url && data?._ds_scope =='private' && scope.token) {
            url += '?token=' + scope.token
        }
console.log(url, data, scope)
        if (url) {
            return <a href={url} download>
                <div className={styles.downLoadBtn}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-11 -8 42 42">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgDownld} fill="#92929d" fillRule="evenodd"
                                      d="M5.33,0A5.33,5.33,0,0,0,0,5.33v16a5.34,5.34,0,0,0,5.33,5.34H16a5.33,5.33,0,0,0,5.33-5.34V9a2.67,2.67,0,0,0-.59-1.68L15.6,1a2.63,2.63,0,0,0-2.07-1ZM15.5,5.1V7.2h1.7ZM5.33,2.67A2.66,2.66,0,0,0,2.67,5.33v16A2.66,2.66,0,0,0,5.33,24H16a2.67,2.67,0,0,0,2.67-2.67V9.87H15.5A2.68,2.68,0,0,1,12.83,7.2V2.67Zm6.67,14,.53-.4a1.33,1.33,0,1,1,1.6,2.13l-2.65,2a1.37,1.37,0,0,1-.81.28,1.32,1.32,0,0,1-.81-.28l-2.66-2a1.33,1.33,0,1,1,1.6-2.13l.53.4V11.48a1.34,1.34,0,0,1,2.67,0Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </a>
        }
        return null
    }

    function viewDocument(i){
    if(i.documentType === 'digitalSignature'){
        return (
            <div className={styles.lotFull}>
                <div className={styles.desc}>Версія документа що підписаний КЕП, iD прозорро</div>
                <div div className={styles.prop}>{i.relatedDocument}</div>
            </div>
        )
    }
    return null
    }

    let tmpDescription = null
        if (props.data !== null) {
            if (checkProp(props.data, 'description')) {
                if (checkProp(props.data.description, 'uk_UA')) {
                    tmpDescription = props.data.description.uk_UA
                } else {
                    tmpDescription = props.data.description
                }
            }
        }

        if (props.data.documentType === 'illustration') {
            return (
                <div className={styles.infoSubBlock}>
                    <div className={styles.titleBlock}>
                        <div className={styles.titleBlockItem}>
                            <div className={styles.changedBage}>(Змінений документ)</div>
                            <div className={styles.infoBlockTitle}>
                                {checkProp(props.data.title,'uk_UA') ? props.data.title.uk_UA : props.data.title}
                            </div>
                        </div>
                        <div className={styles.btnBlock}>
                            {viewLink(props?.data, props?.scope)}
                        </div>
                    </div>
                    <div className={styles.imageContentBlock}>
                        <div className={styles.imgContainer}>
                            {viewImg(props.data)}
                        </div>
                        <div className={styles.imageProps}>
                            {viewDocument(props.data)}
                            {checkProp(props.data,'description') ?
                                <div className={styles.lotFull}>
                                    <div className={styles.desc}>Опис документу</div>
                                    <div className={styles.prop}>{props.data.description.uk_UA}</div>
                                </div> : null
                            }
                            <div className={styles.lotHalf}>
                                <div className={styles.desc}>Тип документу</div>
                                <div className={styles.prop}>{props.docType}</div>
                            </div>
                            {checkProp(props.data,'datePublished') ?
                                <div className={styles.lotHalf}>
                                    <div className={styles.desc}>Документ опубліковано</div>
                                    <div className={styles.prop}>{dateTransform(props.data.datePublished, 'time')}</div>
                                </div> : null
                            }
                            {checkProp(props.data,'datePublished') ?
                                <div className={styles.lotHalf}>
                                    <div className={styles.desc}>Дата останнього оновлення</div>
                                    <div className={styles.prop}>{dateTransform(props.data.dateModified, 'time')}</div>
                                </div> : null
                            }
                        </div>
                    </div>
                    {showImagePopup ? imagePopUp(props.data) : null}
                </div>
            )
        } else {
            return (
                <div className={styles.infoSubBlock}>
                    <div className={styles.titleBlock}>
                        <div className={styles.titleBlockItem}>
                            <div className={styles.changedBage}>(Змінений документ)</div>
                            <h3 className={styles.infoBlockTitle}>
                                {checkProp(props.data.title,'uk_UA') ? props.data.title.uk_UA : props.data.title}
                            </h3>
                        </div>
                        <div className={styles.btnBlock}>
                            {viewLink(props?.data, props?.scope)}
                        </div>
                    </div>
                    {viewDocument(props.data)}
                    {checkProp(props.data,'description') ?
                        tmpDescription > 180 ?
                            <div className={styles.lotFull}>
                                <div className={styles.desc}>Опис документу</div>
                                <div className={styles.prop}>{tmpDescription}</div>
                            </div> :
                            <div className={styles.lotHalf}>
                                <div className={styles.desc}>Опис документу</div>
                                <div className={styles.prop}>{tmpDescription}</div>
                            </div> 
                        : null
                    }
                    <div className={styles.lotHalf}>
                        <div className={styles.desc}>Тип документу</div>
                        <div className={styles.prop}>{props.docType}</div>
                    </div>
                    {checkProp(props.data,'datePublished') ?
                        <div className={styles.lotHalf}>
                            <div className={styles.desc}>Документ опубліковано</div>
                            <div className={styles.prop}>{dateTransform(props.data.datePublished, 'time')}</div>
                        </div> : null
                    }
                     {checkProp(props.data,'datePublished') ?
                    <div className={styles.lotHalf}>
                        <div className={styles.desc}>Дата останнього оновлення</div>
                        <div className={styles.prop}>{dateTransform(props.data.dateModified, 'time')}</div>
                    </div> : null
                    }
                
                    {/*<div className={styles.lotFull}>
                <p className={styles.desc}>Файли</p>
                <ul className={styles.fileList}>
                    <li>Scan01.jpg</li>
                    <li>Scan01.jpg</li>
                </ul>
            </div>*/}
                </div>
            )
        }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDocItem)