import React, { useState, useEffect } from 'react';
import styles from './leaseRules.module.css';
// import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../elements/inputs/input'
import Checkbox from '../../../../../elements/inputs/checkBox'
import Select from '../../../../../elements/inputs/inputSelect'
import Period from './period/period';

import { checkProp } from '../../../../../../redux/check';

const LeaseRules = ({ data, main, updateData, editFlagSwitch }) => {

    const [hoursByValue, setHoursByValue] = useState(
        main !== null
        ? checkProp(main, 'hoursBy') ? main?.hoursBy?.value : null
        : null
    );
    const [hoursByValueBy, setHoursByValueBy] = useState(
        main !== null
        ? checkProp(main, 'hoursBy') ? main?.hoursBy?.by : null
        : null
    );
    const [daysByValue, setDaysByValue] = useState(
        main !== null
        ? checkProp(main, 'daysBy') ? main.daysBy.value : null
        : null
        
    );
    const [daysByValueBy, setDaysByValueBy] = useState(
        main !== null
        ? checkProp(main, 'daysBy') ? main.daysBy.by : null
        : null
        
    );

    const [graphicHourStatus, setGraphicHourStatus] = useState(
        main !== null
        ? checkProp(main, 'period') && main.period.length > 0 ? true : false
        : false
        
    );
    const [period, setPeriod] = useState(
        main !== null
        ? checkProp(main, 'period') && main.period !== null ? main.period : []
        : []
    );

    const hoursByVal = [
        { name: 'День', value: 'day'},
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]
    const daysByVal = [
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]

    const [other, setOther] = useState(
        data !== null
        ? checkProp(data, 'other') ? data.other : null
        : null
    )

    const [sheduleWasEdited, setSheduleWasEdited] = useState(false)

    useEffect(()=>{
        let tmp = body
        if(graphicHourStatus){
            if(body.period !== null && body.period.length > 0){
                for(let e=0; e<body.period.length; e++){
                    if(body.period[e].hasOwnProperty('weekdays') === true){
                        for(let i=0; i<body.period[e].weekdays.length; i++){
                            if(body.period[e].weekdays[i].weekday.hasOwnProperty('uk_UA') !== true){
                                tmp.period[e].weekdays[i].weekday = {uk_UA: body.period[e].weekdays[i].weekday}
                            }
                        }
                    }
                }
            }
        }else{
            body.period = null
        }
        updateData(tmp)
        if (sheduleWasEdited) {
            editFlagSwitch(sheduleWasEdited)
        }
    }, [hoursByValue, hoursByValueBy, daysByValue, daysByValueBy, graphicHourStatus, period, other])

    let body = {
        hoursBy: {value: hoursByValue, by: hoursByValueBy},
        daysBy: {value: daysByValue, by: daysByValueBy},
        period:	period.length > 0 ? period : null,
        other: other
    }

    function getLeseDuration(value, type){
        if(type === 'hours'){
            switch(value){
            case 'day': return 24
            case 'week': return 168
            case 'month': return 744
            default: return
            }
        }
        if(type === 'days'){
            switch(value){
            case 'week': return 7
            case 'month': return 31
            default: return
            }
        }
    }

    function viewPeriods(){
        if(graphicHourStatus){
            return (
                <>
                    { period !== null && period.length > 0 ?
                        period.map((i, count) => {
                            return <Period 
                                key={count}
                                index={count}
                                data={i}
                                period={period}
                                setPeriod={setPeriod}
                            />
                        }) : null
                    }
                    <button 
                        className={styles.addPeriodButton} 
                        onClick={() => { 
                            let tmp = {
                                startDate: null,
                                endDate: null,
                                weekdays:[]
                            }
                            setSheduleWasEdited(true)
                            setPeriod([...period, tmp]) 
                            }}>
                            Додати період
                    </button>
                </>
            )
        }
        return null
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Правила та умови передачі об`єкта в оренду</div>
            <div className={styles.subtitle}>Пропонований графік оренди</div>
            <div className={styles.scheduleBy}>
                <div className={styles.scheduleHours}>
                    <Input
                        value={hoursByValue}
                        type='number'
                        label="Годин"
                        min={0}
                        max={getLeseDuration(hoursByValue, 'hours')}
                        onChange={e => {
                            setHoursByValue(e)
                            setSheduleWasEdited(true)
                        }}
                        maxlength={3}
                        reqiured
                    />
                    <Select
                        data={{
                            target: hoursByValueBy,
                            label: "На"
                        }}
                        onChange={e => {
                            setHoursByValueBy(e)
                            setSheduleWasEdited(true)
                        }}
                        options={hoursByVal}
                    />
                </div>
                <div className={styles.scheduleDays}>
                    <Input
                        value={daysByValue}
                        type='number'
                        label="Днів"
                        min={0}
                        max={getLeseDuration(daysByValue, 'days')}
                        onChange={e => {
                            setDaysByValue(e)
                            setSheduleWasEdited(true)
                        }}
                        maxlength={2}
                        reqiured
                    />
                    <Select
                        data={{
                            target: daysByValueBy,
                            label: "На"
                        }}
                        onChange={e => {
                            setDaysByValueBy(e)
                            setSheduleWasEdited(true)
                        }}
                        options={daysByVal}
                    />
                </div>
            </div>
            <Checkbox 
                value={graphicHourStatus}
                onChange={ () => {
                    setPeriod([])
                    setGraphicHourStatus(!graphicHourStatus)
                }} 
                label="Графік погодинного використання" 
            />
            {viewPeriods()}
            <Input //"Other"
                label="Інший графік використання "
                value={other}
                onChange={(e)=>{
                    setOther(e)
                    setSheduleWasEdited(true)
                }}
            />
        </div>
    )
}

export default LeaseRules;