import React,{useState} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmountMult';
import InputDate from '../../../../../elements/inputs/inputDate';
import InstructionBlock from '../../../../../elements/viewData/instructionBlock/instructionBlock'

//import BankAccount from '../../bankAccount/bankAccount';
import BanksAccount from '../../bankNew/banks';

import { disabledElement } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils';
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'

const AuctionLSE = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const bankAccount = useSelector(state => state.start.bankAccount)
    useSelector(state => state.start.token);


    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 1)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)

    const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    const [conditions, setConditions] = useState(checkProp(data, 'conditions') ? isMongo(data.conditions) : null)

    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    //const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    //const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    //const [discountPercent, setDiscountPercent] = useState(checkProp(checkProp(data,'discount') && data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    //const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(checkProp(data,'discount') && data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : {
        amount: 800,
        currency: "UAH",
    })
    const [normativeMonetaryValuation, setNormativeMonetaryValuation] = useState(checkProp(data, 'normativeMonetaryValuation') ? data.normativeMonetaryValuation : null)
    const [expertMonetaryValuation, setExpertMonetaryValuation] = useState(checkProp(data, 'expertMonetaryValuation') ? data.expertMonetaryValuation : null)
    const [budgetSpent, setBudgetSpent] = useState(checkProp(data, 'budgetSpent') ? data.budgetSpent : null)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [saleType, setSaleType] = useState(checkProp(data, 'saleType') && data.saleType !== null && data.saleType !== 'null' ? data.saleType : 'landSale')

    let requestBody = {
        title: title !== null && title !== ""
            ? {
                uk_UA: title
            }
            : null,
        description: description !== null && description !== ""
            ? {
                uk_UA: description
            }
            : null,
        accessDetails: accessDetails !== null && accessDetails !== ''
            ? {
                uk_UA: accessDetails
            }
            : null,

        restrictions: restrictions !== null && restrictions !== ""
            ? {
                uk_UA: restrictions
            }
            : null,
        conditions: conditions !== null && conditions !== ""
            ? {
                uk_UA: conditions
            }
            : null,

        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        value: value?.amount !== null && value?.amount > 0
            ? {
                currency: checkProp(value,'currency') ? value.currency : null,
                amount: checkProp(value,'amount') ? +value.amount : null,
                valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
            }
            : null,
        minimalStep: minimalStep?.amount !== null && minimalStep?.amount > 0
            ? {
                currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
            }
            : null,
        guarantee: guarantee?.amount !== null && guarantee?.amount > 0
            ? {
                currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
            }
            : null,
        registrationFee: registrationFee?.amount !== null && registrationFee?.amount > 0
            ? {
                currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
            }
            : null,

        normativeMonetaryValuation: normativeMonetaryValuation?.amount !== null && normativeMonetaryValuation?.amount > 0
            ? {
                currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                amount: checkProp(normativeMonetaryValuation,'amount') ? +normativeMonetaryValuation.amount : null
            }
            : null,
        expertMonetaryValuation: expertMonetaryValuation?.amount !== null && expertMonetaryValuation?.amount > 0
            ? {
                currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                amount: checkProp(expertMonetaryValuation,'amount') ? +expertMonetaryValuation.amount : null
            }
            : null,
        budgetSpent: budgetSpent?.amount !== null && budgetSpent?.amount > 0
            ? {
                currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                amount: checkProp(budgetSpent,'amount') ? +budgetSpent.amount : null
            }
            : null,
        previousAuctionId: previousAuctionId,
        bankAccounts: saveBanks(),
        saleType: saleType,
    }
    
    function discountBlock(){
        if (tenderAttempts === 1) return null
        return(
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                <div className={styles.twoInLine}>
                    <Input //"Ідентифікатор попереднього аукціону"
                        label="Номер попереднього аукціону (аукціону з єдиним учасником)"
                        value={previousAuctionId}
                        onChange={(e)=>{ setPreviousAuctionId(e) }}
                        disabled={disabledElement(auction, '01')}
                        required
                    />
                </div>
            </div>
        )
    }

    function saveBanks(){
        let tmp = []
        for(let ii = 0; ii < bankAccounts.length; ii++){
            if(bankAccounts[ii].hasOwnProperty('status') !== true){
                tmp.push(bankAccounts[ii])
            }else{
                if(!bankAccounts[ii].status) tmp.push(bankAccounts[ii])
            }
        }
        return tmp
    }

    return (
        <>
            <Input //"Номер лота" 
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                
                //required
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                required
                
            />
            <TextArea 
                value={description}
                
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />


            <TextArea 
                value={restrictions}
                
                label="Обтяження прав на земельну ділянку, обмеження у використанні земель"
                //required
                onChange={(e)=>{ setRestrictions(e) }}
            />
            <TextArea 
                value={conditions}
                
                label="Містобудівні умови земельної ділянки"
                //required
                onChange={(e)=>{ setConditions(e) }}
            />


            <Select //'Лот виставляється',
                data={{
                    label: "Порядковий номер аукціону", 
                    target: tenderAttempts,
                    
                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { 
                        setTenderAttempts(e)
                    }
                }
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок та можливий час ознайомлення з лотом" 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                
            />
            <Input
                label="Перелік та вимоги до оформлення документів"
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                
            />
            <Select //"Тип продажу" 
                data={{
                    label: "Тип продажу", 
                    target: saleType,
                    
                    required: true,
                }}
                onChange={(e) => { setSaleType(e) }}
                options={
                    [
                        {value: 'landSale', name: "Продаж земельних ділянок"},
                        {value: 'superficiesSale', name: "Продаж прав суперфіцію"},
                        {value: 'emphyteusisSale', name: "Продаж прав емфітевзису"}
                    ]
                }
            />

            {discountBlock()}

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InstructionBlock
                    title='Важлива інформація'
                    content="Шановні користувачі! Починаючи з 01.01.2025р. мінімальна заробітна плата в Україні встановлена на рівні 8000 грн. Просимо враховувати це при встановленні реєстраційного та гарантійного внесків!"
                />
                <InputAmount //"Стартова ціна лота"
                    title="Стартова ціна"
                    lable="Стартова ціна"
                    data={value === null || value === '' || value === "null" || (value.currency === 'null' && value.amount === 0.01)
                        ? {amount: 0,
                            currency: null,
                            addTax: false
                        }
                        : {amount: value.amount,
                            currency: value.currency,
                            addTax: value.valueAddedTaxIncluded
                        }
                    }
                    onChange={(e) => {
                                setValue(e)
                                let tmp = {
                                    amount: (e.amount / 100) * 1,
                                    currency: minimalStep === null || minimalStep?.currency === null || minimalStep.currency === "null" ? null : minimalStep.currency,
                                }
                                setMinimalStep(tmp)
                                // if(Number(e.amount) <= 20000){
                                //     setRegistrationFee({registrationFee, amount: 17})
                                // }else if(Number(e.amount) <= 50000){
                                //     setRegistrationFee({registrationFee, amount: 119})
                                // }else if(Number(e.amount) <= 200000){
                                //     setRegistrationFee({registrationFee, amount: 340})
                                // }else if(Number(e.amount) <= 1000000){
                                //     setRegistrationFee({registrationFee, amount: 510})
                                // }else if(Number(e.amount) > 1000000){
                                //     setRegistrationFee({registrationFee, amount: 1700})
                                // }
                    }}
                    required
                    
                    tax
                />
                <InputAmount //"Гарантійний внесок"
                    title="Гарантійний внесок"
                    lable="Гарантійний внесок"
                    data={{
                        amount: checkProp(guarantee,'amount') && guarantee.amount !== '' ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    required
                    
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Мінімальний крок"
                    lable="Мінімальний крок"
                    data={{
                        amount: checkProp(minimalStep,'amount') && minimalStep.amount !== '' ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Реєстраційний внесок"
                    lable="Реєстраційний внесок"
                    data={{
                        amount: checkProp(registrationFee,'amount') && registrationFee.amount !== '' ? registrationFee.amount : 800,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    required
                    //disabled={true}
                />


                <InputAmount //"Реєстраційний внесок"
                    title="Нормативна грошова оцінка"
                    lable="Нормативна грошова оцінка"
                    data={{
                        amount: checkProp(normativeMonetaryValuation,'amount') ? normativeMonetaryValuation.amount : null,
                        currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setNormativeMonetaryValuation(e) }}
                    
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Експертна грошова оцінка"
                    lable="Експертна грошова оцінка"
                    data={{
                        amount: checkProp(expertMonetaryValuation,'amount') ? expertMonetaryValuation.amount : null,
                        currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setExpertMonetaryValuation(e) }}
                    
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Сума витрат (видатків), здійснених на підготовку лота"
                    lable="Сума витрат (видатків), здійснених на підготовку лота"
                    data={{
                        amount: checkProp(budgetSpent,'amount') ? budgetSpent.amount : null,
                        currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                    }}
                    onChange={(e) => { setBudgetSpent(e) }}
                    
                />
            </div>

            <BanksAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} auction={auction} bankAccount={bankAccount}/>

            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>{`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}</div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    //disabled={disabledElement(auction, '10')}
                />
            </div>

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp
                            let bodyObj = {
                                base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if(auction.id !== null && auction.id !== ''){
                                editAuc(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionLSE