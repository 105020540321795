import React from 'react';
import styles from './slider.module.css';

import {connect} from 'react-redux';

const Slider = () => {
    return (
        <>
            <div className={styles.slider_wrap} >
                <div className={styles.slider_wrap_black}>
                    <div className={styles.connect}>
                        <div className={styles.block_title}>Портал Державніторги.Онлайн: офіційний учасник проекту Prozorro:Продажі</div>
                        <div className={styles.block_description}>Відкритість та прозорість, що дозволяє отримати доступ усім бажаючим до лотів, які раніше були недоступні</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, null)(Slider)