import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../redux/modifiDate'

import ContactPoints from '../../contactPoint/contactPoint'
import {TranslationsUk} from "../../../redux/translations.uk";
import procedure from "../../../redux/procedure";

const CurrentTenant = (props) => {
    let currentTenants = props.auction?.currentTenants
    let hasRelatedOrganizations = false
    if (currentTenants === undefined || currentTenants === null) {
        currentTenants = props.auction?.relatedOrganizations?.currentTenants
        hasRelatedOrganizations = true
    }
    if (currentTenants === undefined || currentTenants === null) return null;

    let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }
    return currentTenants.map((currentTenant) => {
        return (
            <div className={styles.infoBlock}>
                <h3>{hasRelatedOrganizations ?
                    TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.currentTenants?.legalName ?? (procedure.isLLE(props.auction.sellingMethod) ? "Інформація про Чинного орендаря" : "Дані про учасника з переважним правом")
                    : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.currentTenants?.legalName ?? (procedure.isLLE(props.auction.sellingMethod) ? "Інформація про Чинного орендаря" : "Дані про учасника з переважним правом")}</h3>
                {props?.auction?.numberOfCurrentTenants &&
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}> Кількість учасників з переважним правом</p>
                        <p>{props?.auction?.numberOfCurrentTenants }</p>
                    </div>
                }
                <div className={styles.orgTitle}>
                    <p className={styles.desc}>{hasRelatedOrganizations ?
                        TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.relatedOrganizations?.currentTenants?.name?.legalName ?? "Повна юридична назва організації або ПІБ"
                        : TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.currentTenants?.name?.legalName ?? "Повна юридична назва організації або ПІБ"}</p>
                    <p>{currentTenant?.name?.uk_UA}</p>
                </div>
                {currentTenant?.contactPoint &&
                    <div className={styles.orgContact}>
                        <p className={styles.desc}>Контактні дані</p>
                        {contactPoint(currentTenant.contactPoint)}
                    </div>
                }
                {currentTenant?.representativeInfo &&
                    <div className={styles.orgContact}>
                        <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                        <p>{currentTenant.representativeInfo}</p>
                    </div>
                }
                {currentTenant?.currentTenantValue?.amount &&
                     <div className={styles.orgContact}>
                        <p className={styles.desc}>Орендна ставка (сума на рік)</p>
                        <p>{currentTenant.currentTenantValue.amount} грн.</p>
                    </div>
                }
                <div className={styles.orgId}>
                    <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                    <p>{contactEDRPOU(currentTenant)}</p>
                </div>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Юридична адреса</p>
                    <p>{contactAddress(currentTenant)}</p>
                </div>
                {currentTenant?.address?.addressID?.id &&
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>КОАТУУ</p>
                        <p>{currentTenant.address.addressID.id}</p>
                    </div>
                }
                {currentContractTime(currentTenant)}
            </div>
        )
    })
}

function currentContractTime(props){
    let tmp = ''
    if(props.hasOwnProperty('currentContractTime') !== true) return null
    if(props.currentContractTime === null) return null
    if(
        props.currentContractTime.dateFrom !== null &&
        props.currentContractTime.dateTill !== null
    ){
        return (
            <>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Дата укладення та закінчення договору оренди</p>
                    <p></p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час початку</p>
                    <p>{modifiDateString(props.currentContractTime.dateFrom, 3)}</p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час закінчення</p>
                    <p>{modifiDateString(props.currentContractTime.dateTill, 3)}</p>
                </div>
            </>
        )
    }
    return tmp
}

function contactPoint(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

function contactEDRPOU(props){
    if(props.hasOwnProperty('identifier') !== true) return null
    if(props.identifier === null) return null
    if(props.identifier.id === null) return null
    return props.identifier.id
}

function contactAddress(props){
    let tmp = ''
    if(props.hasOwnProperty('address') !== true) return null
    if(props.address === null) return null
    if(props.address.hasOwnProperty('postalCode')){
        if(props.address.postalCode !== null){
            tmp += props.address.postalCode + ', '
        }
    }
    if(props.address.hasOwnProperty('countryName')){
        if(props.address.countryName !== null){
            tmp += props.address.countryName.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('region')){
        if(props.address.region !== null){
            tmp += props.address.region.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('locality')){
        if(props.address.locality !== null){
            tmp += props.address.locality.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('streetAddress')){
        if(props.address.streetAddress !== null){
            tmp += props.address.streetAddress.uk_UA
        }
    }                                    
    return tmp
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(CurrentTenant)
