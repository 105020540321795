import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import TextFullHeader from './textFullHeader'
import { checkProp } from '../../../../redux/check';

import ZuMap from '../../../map/zuMapsNotEdit'

import Address from './elements/address'
import RegistrationDetails from './elements/registrationDetails'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            tmp = props.auction.items
            .map(
                (i, count) => {
                    let tmpZuMap = null
                    if(i.hasOwnProperty('location')  === true){
                        if(i.location !== null){
                            if((i.location.latitude !== null && i.location.latitude !== '')  && (i.location.longitude !== null && i.location.longitude !== '')){
                                tmpZuMap = <ZuMap
                                latitude = {i.location.latitude}
                                longitude = {i.location.longitude}
                                />
                            }
                        }
                    }
                    return (
                        <>
                            {viewTypeAuction(i)}
                            {tmpZuMap}
                        </>
                    )
                }
            )
        }
    }
    

    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data){
    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(checkProp(data, 'classification')){
        tmpClassificationScheme = data.classification.scheme
        tmpClassificationId = data.classification.id
        tmpClassificationDescription = checkProp(data.classification, 'description') &&
            checkProp(data.classification.description, 'uk_UA') ? data.classification.description.uk_UA : null
    }
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}>

            </div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {AddClassificationScheme(data)}

            <Address title='Місцезнаходження' data={data} />
            <RegistrationDetails data={data} />
            {data.smallPrivatizationItemType !== null ? <TextHalf
                title="Тип активу"
                data={viewBasicSellItemType(data)}
            /> : null}
            <TextHalf
                title="Тип об'єкта майна"
                data={strTypeObject(data)}
            />
            {jointPropertyComplexQuantityOff(data)}
            {unitName(data)}
            {quantityName(data)}
            {zuItemType(data)}
            {dgfLocation(data)}
        </>
    )
}

function jointPropertyComplexQuantityOff(data){
    if(data !== null){
        if(data.hasOwnProperty('itemProps')  === true){
            if(data.itemProps !== null){
                if(data.itemProps.itemPropsType !== 'jointPropertyComplex'){
                    return (
                        <TextHalf
                            title="Кількість одиниць"
                            data={data.quantity}
                        />
                    )
                }
            }
        }
    }
    return null
}

function viewBasicSellItemType(data){
    if(data.basicSellItemType === 'claimRights') return 'Права вимоги'
    if(data.basicSellItemType === 'assets') return 'Майно'
    if(data.smallPrivatizationItemType === 'claimRights') return 'Права вимоги'
    if(data.smallPrivatizationItemType === 'assets') return 'Майно'
    if(data.largePrivatizationItemType === 'claimRights') return 'Права вимоги'
    if(data.largePrivatizationItemType === 'assets') return 'Майно'
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

function quantityName(data){
    if(data !== null){
        if(data.quantity !== null){
            return <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
        }
    }
    return null
}

function strTypeObject(data){
    if(data.hasOwnProperty('itemProps')  === true){
        if(data.itemProps !== null){
            if(data.itemProps.itemPropsType === 'land'){
                return "Земельна ділянка"
            }else if(data.itemProps.itemPropsType === 'jointPropertyComplex'){
                return "ЄМК"
            }else if(data.itemProps.itemPropsType === "vehicle"){
                return "Транспортний засіб"
            }else if(data.itemProps.itemPropsType === 'realEstate'){
                return "Нерухоме майно"
            }
        }
    }
    return 'Інше'
}

function AddClassificationScheme(data){
    if(data !== null){
        if(data.hasOwnProperty('additionalClassifications')){
            if(data.additionalClassifications !== null){
                if(data.additionalClassifications.length > 0){
                    return data.additionalClassifications.map(
                        (e) => {
                            let tmpAdd = e.scheme + ' ' + e.id + ' - ' + (e?.description ? e.description.uk_UA : null)
                            return <TextHalf
                                title="Додатковий класифікатор об'єкта"
                                data={tmpAdd}
                            />
                        }
                    )
                }
            }
        }
    }
    return null
}

function dgfLocation(data){
    if(data !== null){
        if(data.hasOwnProperty('location') === true){
            if(data.location !== null){
                return <>
                    <TextFullHeader
                        title="Географічні координати місця розташування:"
                        data=''
                    />
                    <TextHalf
                        title="Широта"
                        data={data.location.latitude}
                    />
                    <TextHalf
                        title="Довгота"
                        data={data.location.longitude}
                    />
                </>
            }
        }
    }
    return null
}

function zuItemType(data){
    if(data.hasOwnProperty('itemProps')  === true){
        if(data.itemProps !== null){
            if(data.itemProps.itemPropsType === "land"){
                return dgfLandProps1(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'jointPropertyComplex'){
                return dgfJointPropertyComplexProps(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'vehicle'){
                return dgfVehicleProps(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'realEstate'){
                return dgfRealEstateProps(data.itemProps)
            }
        }
    }
}

function dgfLandProps1(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, земельна ділянка:"
                data=''
            />
            {data.cadastralNumber ? <TextHalf
                title="Кадастровий номер"
                data={data.cadastralNumber}
            /> : null}
            {data.landArea ? <TextHalf
                title="Площа ділянки, га"
                data={data.landArea}
            /> : null}
            {data.ownershipType ? <TextHalf
                title="Тип власності"
                data={viewOwnership(data.ownershipType)}
            /> : null}
            {data.landIntendedUse ? <TextHalf
                title="Поточне використання земельної ділянки"
                data={data.landIntendedUse}
            /> : null}
            {data.hasOwnProperty('hasEncumbrances') === true
                ? <TextHalf
                    title="Присутні обтяження"
                    data={data.hasEncumbrances === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasEncumbrances') === true && data.hasEncumbrances === true
                ? <TextHalf
                    title="Інформація про наявні обтяження"
                    data={getEncumbrances(data.encumbrances)}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true
                ? <TextHalf
                    title="Наявні співвласники"
                    data={data.hasJointOwnership === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true && data.hasJointOwnership === true
                ? <TextHalf
                    title="Інформація про співвласників"
                    data={data.jointOwnership}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true
                ? <TextHalf
                    title="Інженерні мережі наявні"
                    data={data.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true && data.hasUtilitiesAvailability === true
                ? <TextHalf
                    title="Інформація про інженерні мережі"
                    data={data.utilitiesAvailability}
                />
                : null
            }
        </>
    }
}
function getEncumbrances(data){
    switch(data){
        case "noEncumbrances": return 'Обтяження відсутні'
        case "arrest": return 'Арешт нерухомого майна'
        case "collateral": return 'Податкова застава'
        case "restraintsOnAlienation": return 'Заборона відчуження'
        case "otherEncumbrances": return 'Інші обтяження'
        default: return null
    }
}


function viewOwnership(data){
    switch(data){
        case "state": return 'Державна'
        case "private": return 'Приватна'
        case "municipal": return 'Комунальна'
        case "compatible": return 'Сумісна'
        case "unknown": return 'Невідомо'
        default: return ''
    }
}

function dgfRealEstateProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, нерухомість:"
                data=''
            />
            <TextHalf
                title="Загальна площа будівлі, кв.м."
                data={data.totalBuildingArea}
            />
            <TextHalf
                title="Загальна площа об'єкта в будівлі, кв.м."
                data={data.totalObjectArea}
            />
            <TextHalf
                title="Корисна площа об'єкта в будівлі, кв.м."
                data={data.usableArea}
            />
            <TextHalf
                title="Рік будівництва"
                data={data.constructionYear}
            />
            {strConstructionTechnology(data)}
            <TextHalf
                title="Житлова площа, кв. м."
                data={data.livingArea}
            />
            <TextHalf
                title="Площа кухні, кв. м."
                data={data.kitchenArea}
            />
            <TextHalf
                title="Площа ділянки, кв. м."
                data={data.landArea}
            />
            {strLocationInBuilding(data)}
            <TextHalf
                title="Номер поверху або поверхів"
                data={data.floors}
            />
            <TextHalf
                title="Технічний стан об'єкта"
                data={data.generalCondition}
            />
            <TextHalf
                title="Чи приєднаний об'єкт до електромережі"
                data={viewTrueOrFalse(data.serviceElectricity)}
            />
            <TextHalf
                title="Потужність електромережі, кВт"
                data={data.powerSupplyCapacity}
            />
            {strPowerSupplyClass(data)}
            <TextHalf
                title="Водозабезпечення присутнє"
                data={viewTrueOrFalse(data.serviceWater)}
            />
            <TextHalf
                title="Каналізація присутня"
                data={viewTrueOrFalse(data.serviceSewerage)}
            />
            <TextHalf
                title="Газифікація присутня"
                data={viewTrueOrFalse(data.serviceGas)}
            />
            <TextHalf
                title="Централізоване опалення присутнє"
                data={viewTrueOrFalse(data.serviceCentralHeating)}
            />
            <TextHalf
                title="Автономне опалення присутнє"
                data={viewTrueOrFalse(data.serviceAutonomousHeating)}
            />
            <TextHalf
                title="Лічильник опалення присутній"
                data={viewTrueOrFalse(data.serviceHeatingCounter)}
            />
            <TextHalf
                title="Вентиляція присутня"
                data={viewTrueOrFalse(data.serviceVentilation)}
            />
            <TextHalf
                title="Кондиціонування присутнє"
                data={viewTrueOrFalse(data.serviceAirConditioning)}
            />
            <TextHalf
                title="Телефонізація присутня"
                data={viewTrueOrFalse(data.servicePhone)}
            />
            <TextHalf
                title="Телебачення присутнє"
                data={viewTrueOrFalse(data.serviceTV)}
            />
            <TextHalf
                title="Інтернет присутній"
                data={viewTrueOrFalse(data.serviceInternet)}
            />
            <TextHalf
                title="Ліфт присутній"
                data={viewTrueOrFalse(data.serviceElevator)}
            />
            <TextHalf
                title="Охоронна сигналізація присутня"
                data={viewTrueOrFalse(data.serviceSecurityAlarm)}
            />
            <TextHalf
                title="Пожежна сигналізація присутня"
                data={viewTrueOrFalse(data.serviceFireAlarm)}
            />
            <TextHalf
                title="Додаткова інформація щодо комунікацій, що є в об'єкті"
                data={data.servicesDescription}
            />
            <TextHalf
                title="Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                data={data.servicesAccounting}
            />
            <TextHalf
                title="Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                data={data.landTax}
            />
        </>
    }
    return null
}
function strConstructionTechnology(data){
    if(data.constructionTechnology !== null){
        let tmp = ''
        if(data.constructionTechnology === 'brick'){
            tmp = 'Цегла'
        }else if(data.constructionTechnology === 'insulatedPanel'){
            tmp = 'Утеплена панель'
        }else if(data.constructionTechnology === 'monolithicFrame'){
            tmp = 'Монолітно-каркасна'
        }else if(data.constructionTechnology === 'other'){
            tmp = 'Iнше'
        }else if(data.constructionTechnology === 'panel'){
            tmp = 'Панель'
        }
        return (
            <TextHalf
                title="Технологія будівництва"
                data={tmp}
            />
        )
    }
}
function strLocationInBuilding(data){
    if(data.locationInBuilding !== null){
        let tmp = ''
        if(data.locationInBuilding === 'aboveGround'){
            tmp = 'Надземний'
        }else if(data.locationInBuilding === 'attic'){
            tmp = 'Мансардний'
        }else if(data.locationInBuilding === 'basement'){
            tmp = 'Підвальний'
        }else if(data.locationInBuilding === 'pedimental'){
            tmp = 'Цокольний'
        }else if(data.locationInBuilding === 'technical'){
            tmp = 'Технічний'
        }
        return (
            <TextHalf
                title="Місце розташування об’єкта в будівлі"
                data={tmp}
            />
        )
    }
}
function strPowerSupplyClass(data){
    if(data.powerSupplyClass !== null){
        let tmp
        if(data.powerSupplyClass === 'first'){
            tmp = 'Перший ступінь'
        }else if(data.powerSupplyClass === 'over50'){
            tmp = 'Понад 50 кВт'
        }else if(data.powerSupplyClass === 'second'){
            tmp = 'Другий ступінь'
        }else{
            tmp = data.powerSupplyClass
        }
        return (
            <TextHalf
                title="Ступінь потужності електромережі 1"
                data={tmp}
            />
        )
    }
}


function dgfJointPropertyComplexProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, єдиний майновий комплекс:"
                data=''
            />
            <TextHalf
                title="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                data={data.quantityAndNomenclature}
            />
            <TextHalf
                title="Кількість робочих місць, ociб"
                data={data.workplacesQuantity}
            />
            <TextHalf
                title="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                data={data.buildingsInformation}
            />
            <TextHalf
                title="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                data={data.landPlotInformation}
            />
            <TextHalf
                title="Основні зобов’язання (договірні та позадоговірні)"
                data={data.mainObligations}
            />
            {data.hasOwnProperty('landProps') ? dgfLandProps(data.landProps) : null}
        </>
    }
}

function dgfVehicleProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, транспортний засіб:"
                data=''
            />
            <TextHalf
                title="Марка транспортного засобу"
                data={data.brand}
            />
            <TextHalf
                title="Модель транспортного засобу"
                data={data.model}
            />
            <TextHalf
                title="Рік випуску"
                data={data.productionYear}
            />
            <TextHalf
                title="Об’єм двигуна, см³"
                data={data.engineCapacity}
            />
            {strFuelType(data)}
            {strTransmissionType(data)}
            <TextHalf
                title="Колір"
                data={data.color}
            />
            <TextHalf
                title="Пробіг, км"
                data={data.kilometrage}
            />
            <TextHalf
                title="Номер кузова або шасі"
                data={data.identificationNumber}
            />
            <TextHalf
                title="Комплектація"
                data={data.configuration}
            />
            <TextHalf
                title="Технічний стан"
                data={data.condition}
            />
            <TextHalf
                title="Потреба у ремонті"
                data={viewTrueOrFalse(data.damagePresence)}
            />
            <TextHalf
                title="Опис пошкоджень"
                data={data.damagedDescription}
            />
        </>
    }
}

function strFuelType(data){
    if(data.fuelType !== null){
        let tmp = ''
        if(data.fuelType === 'diesel'){
            tmp = 'Дизель'
        }else if(data.fuelType === 'electric'){
            tmp = 'Електро'
        }else if(data.fuelType === 'gasoline'){
            tmp = 'Бензин'
        }else if(data.fuelType === 'hybrid'){
            tmp = 'Гібридний'
        }else if(data.fuelType === 'liquefiedPetroleumGas'){
            tmp = 'Зріджений нафтовий газ'
        }else if(data.fuelType === 'naturalGas'){
            tmp = 'Газ'
        }else if(data.fuelType === 'other'){
            tmp = 'Інший'
        }
        return (
            <TextHalf
                title="Тип палива"
                data={tmp}
            />
        )
    }
}
function strTransmissionType(data){
    if(data.transmission !== null){
        let tmp = ''
        if(data.transmission === 'automatic'){
            tmp = 'Автоматична'
        }else if(data.transmission === 'manual'){
            tmp = 'Механічна'
        }else if(data.transmission === 'other'){
            tmp = 'Інше'
        }else if(data.transmission === 'semiAutomatic'){
            tmp = 'Напівавтоматична'
        }
        return (
            <TextHalf
                title="Тип коробки передач"
                data={tmp}
            />
        )
    }
}

function dgfLandProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Характеристики земельної ділянки:"
                data=''
            />
            {data.hasOwnProperty('landArea') ? <TextHalf
                title="Площа ділянки, га"
                data={data.landArea}
            /> : null}
            <TextHalf
                title="Кадастровий номер"
                data={data.cadastralNumber}
            />
            {strOwnershipType(data)}
            <TextHalf
                title="Поточне використання"
                data={data.intendedUse}
            />
            {data.hasOwnProperty('hasEncumbrances') === true
                ? <TextHalf
                    title="Присутні обтяження"
                    data={data.hasEncumbrances === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasEncumbrances') === true && data.hasEncumbrances === true
                ? <TextHalf
                    title="Інформація про наявні обтяження"
                    data={getEncumbrances(data.encumbrances)}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true
                ? <TextHalf
                    title="Наявні співвласники"
                    data={data.hasJointOwnership === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true && data.hasJointOwnership === true
                ? <TextHalf
                    title="Інформація про співвласників"
                    data={data.jointOwnership}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true
                ? <TextHalf
                    title="Інженерні мережі наявні"
                    data={data.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true && data.hasUtilitiesAvailability === true
                ? <TextHalf
                    title="Інформація про інженерні мережі"
                    data={data.utilitiesAvailability}
                />
                : null
            }
        </>
    }
}
function strOwnershipType(data){
    if(data.ownershipType !== null){
        let tmp = ''
        if(data.ownershipType === 'compatible'){
            tmp = 'Сумісна'
        }else if(data.ownershipType === 'municipal'){
            tmp = 'Комунальна'
        }else if(data.ownershipType === 'private'){
            tmp = 'Приватна'
        }else if(data.ownershipType === 'state'){
            tmp = 'Державна'
        }else if(data.ownershipType === 'unknown'){
            tmp = 'Невідомо'
        }
        return (
            <TextHalf
                title="Тип власності"
                data={tmp}
            />
        )
    }
}

function viewTrueOrFalse(data){
    if(data === undefined || data === 'undefined') return 'Невідомо'
    if(data) return 'Так'
    return 'Ні'
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
