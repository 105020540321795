// noinspection ES6UnusedImports

import React, {useState} from 'react';
import styles from './bankAccounts.module.css';

import {connect} from 'react-redux';

import Button from '../../../../elements/buttons/button';
import Select from '../../../../elements/inputs/inputSelect';

import BankAccountsGroups from './bankAccountsGroups'

import procedure from '../../../../../redux/procedure'

import {
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';


const BankAccounts = ( {data, bankAccounts, setBankAccounts, ...props} ) => {
    const [banksGroupe, setBanksGroupe] = useState(null)
    const [bank, setBank] = useState(null)

    function elementDisabledCreate(props){
        if(props.auctionsMy.status !== null){
            if(props.auctionsMy.status !== 'active_rectification'){
                let tmpStartData = null
                let tmpEndData = null
                    if(props.auctionsMy.rectificationPeriod !== null){
                        tmpStartData = new Date(props.auctionsMy.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(props.auctionsMy.rectificationPeriod.endDate).getTime()
                    }
                if(
                    (
                        procedure.isBSE(props.auctionsMy.sellingMethod) || procedure.isBSD(props.auctionsMy.sellingMethod) ||
                        procedure.isALE(props.auctionsMy.sellingMethod) ||
                        procedure.isCSE(props.auctionsMy.sellingMethod) || procedure.isCSD(props.auctionsMy.sellingMethod) ||
                        procedure.isRLE(props.auctionsMy.sellingMethod) || procedure.isRLD(props.auctionsMy.sellingMethod) || procedure.isRLP(props.auctionsMy.sellingMethod) ||
                        procedure.isCLE(props.auctionsMy.sellingMethod) || procedure.isCLD(props.auctionsMy.sellingMethod) || procedure.isCLP(props.auctionsMy.sellingMethod) ||
                        procedure.isNLE(props.auctionsMy.sellingMethod) || procedure.isNLD(props.auctionsMy.sellingMethod) ||
                        procedure.isLAE(props.auctionsMy.sellingMethod) || procedure.isLAW(props.auctionsMy.sellingMethod) || procedure.isLAP(props.auctionsMy.sellingMethod) ||
                        procedure.isAPE(props.auctionsMy.sellingMethod) || procedure.isAPD(props.auctionsMy.sellingMethod)
                        || procedure.isLLE(props.auctionsMy.sellingMethod) || procedure.isLLD(props.auctionsMy.sellingMethod) || procedure.isLLP(props.auctionsMy.sellingMethod)
                        ) &&
                    ((tmpStartData < Date.now() && tmpEndData > Date.now()) || procedure.inPeriod('rectificationPeriod', props.auctionsMy))
                ){
                    return null
                }else if(procedure.isBRE(props.auctionsMy.sellingMethod) || procedure.isBRD(props.auctionsMy.sellingMethod) || procedure.isBRW(props.auctionsMy.sellingMethod)){
                    if(
                        props.auctionsMy.status === 'complete' ||
                        props.auctionsMy.status === 'cancelled' ||
                        props.auctionsMy.status === 'unsuccessful'
                    ){
                        return 'disabled'
                    }else if(
                        props.auctionsMy.status === 'active_auction' ||
                        props.auctionsMy.status === 'pending_payment' ||
                        props.auctionsMy.status === 'active_qualification'
                    ){
                        return null
                    }else if(props.auctionsMy.status === 'active_awarded'){
                        if(props.auctionsMy.contracts !== null && props.auctionsMy.contracts.length > 0){
                            let tmp
                            tmp = props.auctionsMy.contracts.filter(
                                (e) => {
                                    return (e.status === 'pending')
                                }
                            )
                            if(tmp.length > 0) return 'disabled'
                        }
                    }
                    return null
                }else if(procedure.isSPE(props.auctionsMy.sellingMethod) || procedure.isSPD(props.auctionsMy.sellingMethod)){
                    return null
                }else if(procedure.isLPE(props.auctionsMy.sellingMethod)){
                    return null
                }else{
                    return 'disabled'
                }
            }
        }
    }

    function viewCreatGroup(props){
        if(banksGroupe !== null){
            return (
                <Select 
                        data={{
                            label:"Рахунки",
                            target: bank,
                            descr: "Обрати банківський рахунок"
                        }}
                        onChange={ e => setBank(e) }
                        options={selectBankAuction(props)}
                    />
            )
        }
        return null
    }

    if(props.bankAccountZU !== null){
        return <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}>
            <BankAccountsGroups 
                bankAccounts={bankAccounts}
                setBankAccounts={setBankAccounts}
            />
            <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}></div>
            <Select 
                data={{
                    label:"Банківські рахунки",
                    target: banksGroupe,
                    descr: "Обрати банківський рахунок"
                }}
                onChange={ 
                    e => setBanksGroupe(e)
                }
                options={optionBankAccount(props)}
                disabled={procedure.isLAW(props.auctionsMy.sellingMethod) ? false : elementDisabledCreate(props)}
            />
            {viewCreatGroup(props)}
            <div className={styles.editBanks}>
                <Button
                    color="blue"
                    onClick={
                        async () => {
                            let tmp = {
                                id: bank,
                                groupe: banksGroupe,
                                type: props.createBankAccountZU.groupType
                            }
                            let newBank = await getBank(props, tmp)
                            const tmpBank  = JSON.parse(JSON.stringify(newBank))

                            tmpBank.new = true
                            let tmpBanksAccounts = bankAccounts
                            if (tmpBanksAccounts.length > 0){
                                for (let key in bankAccounts){
                                    if ( tmpBanksAccounts[key].accountType === banksGroupe) {
                                        tmpBanksAccounts[key].accounts.push(tmpBank)
                                        setBankAccounts(tmpBanksAccounts)
                                        setBanksGroupe(null)
                                        setBank(null)
                                        return
                                    }
                                }

                                let tmp = {}
                                tmp.accountType = banksGroupe
                                tmp.accounts= []
                                tmp.accounts.push(tmpBank)
                                tmpBanksAccounts.push(tmp)
                                setBankAccounts(tmpBanksAccounts)
                            }
                            else {
                                let tmp = {}
                                tmp.accountType = banksGroupe
                                tmp.accounts= []
                                tmp.accounts.push(tmpBank)
                                setBankAccounts([tmp])
                            }

                            setBanksGroupe(null)
                            setBank(null)
                        }
                    }
                    disabled={(banksGroupe === null || bank === null) ? true : false}
                >
                    Додати
                </Button>
                <Button
                    disabled={procedure.isLAW(props.auctionsMy.sellingMethod) ? false : elementDisabledCreate(props)}
                    color={'gray'}
                    onClick={
                        () => {
                            props.openBankWindows(true)
                        }
                    }
                >
                    Створити та редагувати банківський рахунок
                </Button>
            </div>
        </div>
    }
}

function getBank(props, data){
    if(props.bankAccount !== null){
        for(let key in props.bankAccount) {
            if (props.bankAccount[key].id === data.id) return props.bankAccount[key]
        }
    }
    return null
}

function optionBankAccount(props){
    if( 
        procedure.isLLE(props.auctionsMy.sellingMethod) || procedure.isLLD(props.auctionsMy.sellingMethod) || procedure.isLLP(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                {value: 'other', name: 'Інші платежі'},

                {value: 'advancePayment', name: 'Рахунок для авансового внеску'},
                {value: 'lease', name: 'Рахунок для оплати оренди'}
            ]
    }
    if( 
        procedure.isALE(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if( 
       procedure.isBRE(props.auctionsMy.sellingMethod) || procedure.isBRD(props.auctionsMy.sellingMethod) || procedure.isBRW(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'payment', name: 'Плата за лот', style: 'bold'},
                /*{value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'organizer', name: 'Організатор'},
                {value: 'advancePayment', name: 'Авансовий внесок'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                {value: 'lease', name: 'Орендні платежі'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'other', name: 'Інші платежі'}*/
            ]
    }
    if(procedure.isRLE(props.auctionsMy.sellingMethod) || procedure.isRLD(props.auctionsMy.sellingMethod) || procedure.isRLP(props.auctionsMy.sellingMethod)){
        return [
            {value: 'lease', name: 'Орендні платежі', style: 'bold'},
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок'},
            {value: 'organizer', name: 'Організатор'}
        ]
    }
    if(procedure.isCLE(props.auctionsMy.sellingMethod) || procedure.isCLD(props.auctionsMy.sellingMethod) || procedure.isCLP(props.auctionsMy.sellingMethod)){
        return [
            {value: 'lease', name: 'Орендні платежі', style: 'bold'},
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок'},
            {value: 'other', name: 'Інші платежі'}
        ]
    }
    if(procedure.isSPE(props.auctionsMy.sellingMethod) || procedure.isSPD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'payment', name: 'Плата за лот'},
            ]
    }
    if(procedure.isNLE(props.auctionsMy.sellingMethod) || procedure.isNLD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if(procedure.isLAE(props.auctionsMy.sellingMethod) || procedure.isLAP(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
            ]
    }
    if(procedure.isLAW(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
            ]
    }
    if(procedure.isLPE(props.auctionsMy.sellingMethod)){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'payment', name: 'Плата за лот'},
            ]
    }
    if(procedure.isAPE(props.auctionsMy.sellingMethod)){
        return [
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'payment', name: 'Плата за лот'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if(procedure.isAPD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'payment', name: 'Плата за лот'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    return [
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
            {value: 'other', name: 'Інші платежі'}
        ]
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i) => {
                let block = <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                <div >
                                    {i.bankName}
                                </div>
                                <div style={{marginRight: '0', color: '#92929D'}}>
                                    {`${i.currency} - 
                                    ${i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null
                                    /*i.accountIdentifications[i.accountIdentifications.length - 1].id}*/}`}
                                </div>
                            </div>
                return {value: i.id, name: block}
            }
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        bankAccountZU: state.start.bankAccountZU,
        bankAccount: state.start.bankAccount,
        createBankAccountZU: state.start.createBankAccountZU,
        createBankAccountZUtimestamp: state.start.createBankAccountZUtimestamp,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    openBankWindows
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)