import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {viewStringValue} from '../../api/valueToString'
import {TranslationsUk} from "../../redux/translations.uk";

const ProcedureStartValue = (props) => {
    let tmp = ''
    if(props.auction !== null){
        if(props.auction.value !== null){
            tmp = viewStringValue(props.auction.value)
        }
    }

    let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }

    return (
        <div className={styles.lotStartPriceBlock}>
            <p>{TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.value?.legalName ?? "Початкова ціна продажу лоту"}</p>
            <p className={styles.lotStartPrice}>{tmp}</p>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedureStartValue)