import React from 'react';
import styles from './windowAnswer.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../redux/actions/questions/questions.js';
import {setPopUpAnswerAuction} from '../../../redux/actions/auctions/auctions.js';

import ButtonAnswer from './buttonAnswer/buttonAnswer.js';

const WindowAnswer = ( props ) => {
    let tmp;
                    tmp = props.auctionsMy.questions.map(
                        (r) => {
                            let tmpAnswer;
                            if(r.answer !== null){
                                tmpAnswer = <div className={styles.answerWrap_line}><span>Відповідь:</span> {r.answer}</div>
                            }else{
                                tmpAnswer = <ButtonAnswer
                                    itemAnswer={r.id}
                                />
                            }
                            return (
                                <div className={styles.answerWrap}>
                                    <div className={styles.answerWrap_line}><span>Заголовок:</span> {r.title}</div>
                                    <div className={styles.answerWrap_line}><span>Текст:</span> {r.description}</div>
                                    {tmpAnswer}
                                </div>
                            )
                        }
                    )

    if(tmp === null){
        tmp = 'Наразі немає жодного запитання від учасників'
    }else if(tmp.length === 0){
        tmp = 'Наразі немає жодного запитання від учасників'
    }
    return (
        <>
            <div className={styles.answerBlock}>
                {tmp}
            </div>
            {/*chatForm(props)*/}
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        auctionsGetMy: state.start.auctionsGetMy,
        questionCreate: state.start.questionCreate,
    }
}

const mapDispatchToProps = {
    answerTitle,
    answerDiscription,
    setPopUpAnswerAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAnswer)
