import React from 'react';

import {connect} from 'react-redux';

import RenewablesItem from "./renewablesMultiAwards/RenewablesItem";

//@todo refactor components below
import Timber from '../../createAuction/windowItems/timber/timber.js';
import Railway from '../../createAuction/windowItems/railway/railway.js';
import Subsoil from '../../createAuction/windowItems/subsoil/subsoil.js';
import Dgf from '../../createAuction/windowItems/dgf/dgf.js';
import BasicSell from '../../createAuction/windowItems/bsebsd/bsebsd'
import Alienation from '../../createAuction/windowItems/ale/ale'
import CommSell from '../../createAuction/windowItems/commSell/commSell';
import BRESell from '../../createAuction/windowItems/BRE/BRE.js';
import BRWSell from '../../createAuction/windowItems/BRW/BRW.js';
import NLE from '../../createAuction/windowItems/nlenld/nlenld'
import LAE from '../../createAuction/windowItems/lae/lae'
import LAW from '../../createAuction/windowItems/law/law'
import LAP from '../../createAuction/windowItems/lap/lap'
import LPE from '../../createAuction/windowItems/lpe/bsebsd'
import CLE from '../../createAuction/windowItems/CLE/bsebsd'
import RLE from '../../createAuction/windowItems/RLE/bsebsd'
import APE from '../../createAuction/windowItems/ape/bsebsd'
import LLE from '../../createAuction/windowItems/lle/bsebsd'
import LRE from '../../createAuction/windowItems/lre/bsebsd'
import procedure from "../../../redux/procedure";

const WindowItems = (props) => {
    switch (true) {
        case props.auctionsMy === null:
            return null
        case procedure.isTIE(props.auctionsMy.sellingMethod):
            return <Timber/> //@deprecated
        case procedure.isSUD(props.auctionsMy.sellingMethod):
        case procedure.isSUE(props.auctionsMy.sellingMethod):
            return <Subsoil/> //@todo refactor
        case procedure.isRCD(props.auctionsMy.sellingMethod):
        case procedure.isRCE(props.auctionsMy.sellingMethod):
            return <Railway/> //@todo refactor
        case procedure.isGFE(props.auctionsMy.sellingMethod):
        case procedure.isGFD(props.auctionsMy.sellingMethod):
            return <Dgf/> //@todo refactor

        case procedure.isLLE(props.auctionsMy.sellingMethod):
        case procedure.isLLD(props.auctionsMy.sellingMethod):
        case procedure.isLLP(props.auctionsMy.sellingMethod):
            return <LLE/> //@todo refactor

        case procedure.isLRE(props.auctionsMy.sellingMethod):
        case procedure.isLSE(props.auctionsMy.sellingMethod):
        case procedure.isLSP(props.auctionsMy.sellingMethod):
            return <LRE/> //@todo refactor

        case procedure.isBSD(props.auctionsMy.sellingMethod):
        case procedure.isBSE(props.auctionsMy.sellingMethod):
            return <BasicSell/> //@todo refactor
        case procedure.isALE(props.auctionsMy.sellingMethod):
            return <Alienation/> //@todo refactor
        case procedure.isCSD(props.auctionsMy.sellingMethod):
        case procedure.isCSE(props.auctionsMy.sellingMethod):
            return <CommSell/> //@todo refactor
        // if(isAAE(props.auctionsMy.sellingMethod))
        case procedure.isBRD(props.auctionsMy.sellingMethod):
        case procedure.isBRE(props.auctionsMy.sellingMethod):
            return <BRESell/> //@todo refactor
        case procedure.isBRW(props.auctionsMy.sellingMethod):
            return <BRWSell/> //@todo refactor
        case procedure.isRLE(props.auctionsMy.sellingMethod):
        case procedure.isRLP(props.auctionsMy.sellingMethod):
        case procedure.isRLD(props.auctionsMy.sellingMethod):
            return <RLE/> //@todo refactor
        case procedure.isCLE(props.auctionsMy.sellingMethod):
        case procedure.isCLP(props.auctionsMy.sellingMethod):
        case procedure.isCLD(props.auctionsMy.sellingMethod):
            return <CLE/> //@todo refactor
        case procedure.isSPE(props.auctionsMy.sellingMethod):
        case procedure.isSPD(props.auctionsMy.sellingMethod):
            return <LPE/> //@todo refactor
        case procedure.isNLE(props.auctionsMy.sellingMethod):
        case procedure.isNLD(props.auctionsMy.sellingMethod):
            return <NLE/> //@todo refactor
        case procedure.isLAE(props.auctionsMy.sellingMethod):
            return <LAE/> //@todo refactor
        case procedure.isLAW(props.auctionsMy.sellingMethod):
            return <LAW/> //@todo refactor
        case procedure.isLAP(props.auctionsMy.sellingMethod):
            return <LAP/> //@todo refactor
        case procedure.isLPE(props.auctionsMy.sellingMethod):
            return <LPE/> //@todo refactor

        case procedure.isAPE(props.auctionsMy.sellingMethod):
        case procedure.isAPD(props.auctionsMy.sellingMethod):
            return <APE/> //@todo refactor

        case procedure.isREM(props.auctionsMy.sellingMethod):
            return <RenewablesItem/>
        default :
            return null
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

export default connect(mapStateToProps, null)(WindowItems)
