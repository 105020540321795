import {createBodyTIE} from './createBodyTIE'
import {createBodySUE} from './createBodySUE'
import {createBodySUD} from './createBodySUD'
import {createBodyRCE} from './createBodyRCE'
import {createBodyRCD} from './createBodyRCD'
import {createBodyLRE} from './createBodyLRE'
import {createBodyLSE} from './createBodyLSE'
import {createBodyLSP} from './createBodyLSP'
import {createBodyBSE} from './createBodyBSE'
import {createBodyBSD} from './createBodyBSD'
import {createBodyALE} from './createBodyALE'
import {createBodyCSE} from './createBodyCSE'
import {createBodyCSD} from './createBodyCSD'
import {createBodyBRE} from './createBodyBRE'
import {createBodyBRD} from './createBodyBRD'
import {createBodyBRW} from './createBodyBRW'

import {createBodyRLE} from './createBodyRLE'
import {createBodyRMA} from "./createBodyRMA";

export function createBodyOld(data){
    console.log("VSE TUT")
    switch(true){
        case data.base.sellingMethod.startsWith("timber-english"):
            return createBodyTIE(data)
        case data.base.sellingMethod.startsWith("subsoil-english"):
            return createBodySUE(data)
        case data.base.sellingMethod.startsWith("subsoil-dutch"):
        case data.base.sellingMethod.startsWith("subsoil-english-fast-auction-manual-qualification")://@todo check this condition
            return createBodySUD(data)
        case data.base.sellingMethod.startsWith("railwayCargo-english"):
            return createBodyRCE(data)
        case data.base.sellingMethod.startsWith("railwayCargo-dutch"):
            return createBodyRCD(data)
        case data.base.sellingMethod.startsWith('landRental-english'):
            return createBodyLRE(data)
        case data.base.sellingMethod.startsWith('landSell-english'):
            return createBodyLSE(data)
        case data.base.sellingMethod.startsWith('landSell-priorityEnglish'):
            return createBodyLSP(data)
        case data.base.sellingMethod.startsWith('basicSell-english'):
            return createBodyBSE(data)
        case data.base.sellingMethod.startsWith('basicSell-dutch'):
            return createBodyBSD(data)
        case data.base.sellingMethod.startsWith('alienation-english'):
            return createBodyALE(data)
        case data.base.sellingMethod.startsWith('arrestedAssets-english'):
            return createBodyBSE(data)
        case data.base.sellingMethod.startsWith('commercialSell-english'):
            return createBodyCSE(data)
        case data.base.sellingMethod.startsWith('commercialSell-dutch'):
            return createBodyCSD(data)
        case data.base.sellingMethod.startsWith('bankRuptcy-english'):
            return createBodyBRE(data)
        case data.base.sellingMethod.startsWith('bankRuptcy-dutch'):
            return createBodyBRD(data)
        case data.base.sellingMethod.startsWith('bankRuptcy-withoutAuction'):
            return createBodyBRW(data)
        case data.base.sellingMethod.startsWith('regulationsPropertyLease-english'):
        case data.base.sellingMethod.startsWith('commercialPropertyLease-english'):
            return createBodyRLE(data)
        case data.base.sellingMethod.startsWith("regulationsPropertyLease-dutch"):
        case data.base.sellingMethod.startsWith("commercialPropertyLease-dutch"):
        case data.base.sellingMethod.startsWith("renewables"):
        case data.base.sellingMethod.startsWith("renewables-multiAwards"):
            return createBodyRMA(data)
        case data.base.sellingMethod.startsWith("dgf-english"):
        case data.base.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case data.base.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case data.base.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            return data
        default:
            return data
    }
}