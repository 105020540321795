import React from 'react';

import { deleteItem } from '../utils';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import 'date-fns';

import AucLotItem from './aucLotItem/aucLotItem';
import {clearDataLot} from "../clearDataItem";

const ItemsList = ( props ) => {
    let tmpArr = null;
    if(props.auctionsMy !== null && props.auctionsMy.items !== null){
            tmpArr = props.auctionsMy.items.map(
                (i) => {
                    return <AucLotItem 
                                data={i} 
                                edit={async () => {
                                                clearDataLot(props)
                                                props.setEdit(i)
                                                props.changeAuctionsItemsSwitchWindow(2);
                                                props.changeCreateItemAuctionId(props.auctionsMy.id)
                                                await combain(props, i)
                                                props.edit(true)
                                }}
                                del={ ()=> {
                                                props.changeLoader(true)
                                                deleteItem(props, props.auctionsMy.id, i.id)
                                                // props.edit(true)
                                }}
                                props={props}
                            />
                }
            )
    }
    return tmpArr
}

function addStatusBaseClasif(props, i){
    let tmpL = `${i.classification.id[0]}${i.classification.id[1]}`
    props.setDataAddress(tmpL, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
}

async function combain(props, i){
    await addStatusBaseClasif(props, i)
    await uploadDataLot(props, i)
}

function uploadDataLot(props, i){
    let tmp = i.classification
    props.setDataAddress(i.description, 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')

    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    if(i.address !== null){
        props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
        
        props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
        props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
        props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
        props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
        props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
        props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
    }
    if(i.location !== null){
        props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
        props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    }
    if(tmp !== null){
        if(tmp.id !== null && tmp.id !== 'null'){
            if(
                tmp.id === '07121000-3' ||
                tmp.id === '07221000-4' ||
                (tmp.id[0] === '0' && tmp.id[1] === '4')
            ){
                dataRealEstate(props, i)
            }else if(
                (tmp.id[0] === '0' && tmp.id[1] === '6') ||
                (
                    tmp.id[0] === '0' &&
                    tmp.id[1] === '7' &&
                    tmp.id[2] === '1' &&
                    tmp.id[3] === '2' &&
                    tmp.id[4] === '3'
                ) ||
                (
                    tmp.id[0] === '0' &&
                    tmp.id[1] === '7' &&
                    tmp.id[2] === '2' &&
                    tmp.id[3] === '2' &&
                    tmp.id[4] === '3'
                )
            ){
                dataLandProperty(props, i)
            }else if(
                tmp.id === '07126000-8' ||
                tmp.id === '07226000-9' ||
                (tmp.id[0] === '3' && tmp.id[1] === '4')
            ){
                dataVehicle(props, i)
            }else if(
                tmp.id === '07122000-0' ||
                tmp.id === '07222000-1' ||
                (tmp.id[0] === '0' && tmp.id[1] === '5')
            ){
                dataJointPropertyComplex(props, i)
            }else{
                dataOtherPropertyNew(props, i)
            }
        }
    }
}

function dataRealEstate(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
    props.setDataAddress('realEstate', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
}

function dataVehicle(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
    props.setDataAddress('vehicle', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
}

function dataJointPropertyComplex(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
    props.setDataAddress('jointPropertyComplex', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
}

function dataOtherPropertyNew(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_LAND_RENTAL_ID')
    props.setDataAddress(null, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    if(i.registrationDetails !== null){
        props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS')
        props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID')
        props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE')
    }
    props.setDataAddress(i.quantity, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    if(i.hasOwnProperty('unit') === true){
        if(i.unit !== null){
            if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
        }
    }
}

function dataLandProperty(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_LAND_RENTAL_ID')
    props.setDataAddress('otherProperty', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        classificationsBseBsd: state.start.classificationsBseBsd,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
    /** */
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
