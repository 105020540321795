import procedure from "../../redux/procedure";

export function getAuctionsStatus(sellingMethod, status) {
    status = status ?? null
    let auctionStatusList = {
        "active_rectification": "Редагування доступне",
        "active_tendering": "Прийняття заяв на участь",
        "active_auction": "Аукціон",
        "qualification": "Перевірка документів учасників",
        "active_qualification": "Очікується опублікування протоколу",
        "active_awarded": "Очікується підписання договору",
        "pending_payment": "Очікується оплата",
        "pending_admission": "Аукціон завершено. Очікується виконання умов договору",
        "cancelled": "Аукціон відмінено",
        "unsuccessful": "Аукціон не відбувся",
        "complete": "Аукціон завершено",
    }

    // eslint-disable-next-line default-case
    switch (true) {
        case procedure.isREM(sellingMethod):
            auctionStatusList.qualification = "Перевірка документів учасників"
            auctionStatusList.active_qualification = "Очікується оприлюднення протоколу та підписання договору"
            auctionStatusList.cancelled = "Аукціон скасовано"
            break
        case procedure.isSUE(sellingMethod) :
        case procedure.isSUD(sellingMethod):
            auctionStatusList.pending_payment = "Очікується сплата до бюджету"
            break
        case procedure.isLLE(sellingMethod):
        case procedure.isLLD(sellingMethod):
        case procedure.isLLP(sellingMethod):
            auctionStatusList.complete = "Аукціон завершено. Договір підписано"
            break
        case procedure.isBRE(sellingMethod):
        case procedure.isBRD(sellingMethod):
        case procedure.isBRW(sellingMethod):
            auctionStatusList.active_awarded = "Очікується підписання акту/договору"
            auctionStatusList.complete = "Аукціон завершено. Акт/договір підписано"
            break
        case procedure.isLRE(sellingMethod):
        case procedure.isLSE(sellingMethod):
        case procedure.isLSP(sellingMethod):
        case procedure.isLAE(sellingMethod):
        case procedure.isLAP(sellingMethod):
            auctionStatusList.active_auction = "Земельні торги"
            auctionStatusList.active_qualification = "Очікується оприлюднення протоколу"
            auctionStatusList.active_awarded = "Очікується підписання договору/акту"
            auctionStatusList.cancelled = "Торги скасовано"
            auctionStatusList.unsuccessful = "Торги не відбулись"
            auctionStatusList.complete = "Торги завершено. Договір/акт підписано"
            break
        case procedure.isLAW(sellingMethod):
            auctionStatusList.active_awarded = "Очікується підписання акту"
            auctionStatusList.complete = "Викуп завершено. Акт підписано"
            auctionStatusList.cancelled = "Викуп скасовано"
            break
        case procedure.isAPE(sellingMethod):
        case procedure.isAPD(sellingMethod):
            auctionStatusList.active_qualification = "Очікується оприлюднення протоколу"
            auctionStatusList.active_awarded = "Очікується підписання акту/договору"
            auctionStatusList.complete = "Аукціон завершено. Акт/договір підписано"
            break
        case procedure.isRLE(sellingMethod):
        case procedure.isRLD(sellingMethod):
        case procedure.isCLE(sellingMethod):
        case procedure.isCLD(sellingMethod):
        case procedure.isNLE(sellingMethod):
        case procedure.isNLD(sellingMethod):
        case procedure.isSPE(sellingMethod):
        case procedure.isSPD(sellingMethod):
        case procedure.isLPE(sellingMethod):
            auctionStatusList.complete = "Аукціон завершено. Договір підписано"
            break
        case procedure.isCLP(sellingMethod):
        case procedure.isRLP(sellingMethod):
            auctionStatusList.cancelled = "Аукціон скасовано"
            break
    }

    if (status === null || status?.length === 0) return "Чернетка"
    return auctionStatusList.hasOwnProperty(status) ? auctionStatusList[status] : status;
}

export function getAuctionsPeriodName(sellingMethod, periodName) {
    let auctionPeriodList = {
        "start": "Аукціон оголошено",
        "rectificationPeriod": "Період редагування",
        "tenderPeriod": "Період подання пропозицій",
        "questionPeriod": "Період запитань та відповідей",
        "enquiryPeriod": "Період відповідей",
        "auctionPeriod": "Період аукціону",
        "verificationPeriod": "Період верифікації документів",
        "qualificationPeriod": "Період кваліфікації",
        "transferPriorityPeriod": "Період кваліфікації",
    }
    // eslint-disable-next-line default-case
    switch (sellingMethod) {
        case procedure.isLAE(sellingMethod):
        case procedure.isLAW(sellingMethod):
        case procedure.isLAP(sellingMethod):
            auctionPeriodList.start = "Торги оголошено"
            break
        case procedure.isCLP(sellingMethod):
        case procedure.isRLP(sellingMethod):
            auctionPeriodList.questionPeriod = "Період запитань"
            break
    }

    return auctionPeriodList.hasOwnProperty(periodName) ? auctionPeriodList[periodName] : periodName;
}

export function getAuctionsAwardPeriodName(sellingMethod, periodName) {
    let auctionAwardPeriodList = {
        "signingPeriod": "Період підписання протоколу та договору",
        "admissionPeriod": "Період прийняття рішення щодо набуття статусу переможця",
        "verificationPeriod": "Період підписання протоколу",
    }
    // eslint-disable-next-line default-case
    switch (sellingMethod) {
        case procedure.isLAE(sellingMethod):
            // auctionAwardPeriodList.start = "Торги оголошено"
            break
        case procedure.isCLP(sellingMethod):
        case procedure.isRLP(sellingMethod):
            auctionAwardPeriodList.signingPeriod ="Період підписання договору"
    }

    return auctionAwardPeriodList.hasOwnProperty(periodName) ? auctionAwardPeriodList[periodName] : periodName;
}

export function getAuctionsPeriodsOld(type, status) {
    switch (status) {
        case "start":
            if (procedure.isLAE(type) || procedure.isLAW(type) || procedure.isLAP(type)) return "Торги оголошено"
            return "Аукціон оголошено"
        case "active_rectification":
            return "Період редагування"
        case "active_tendering":
            return "Період подання пропозицій"
        case "questionPeriod":
            if (procedure.isLAE(type) || procedure.isLAW(type) || procedure.isLAP(type)) return "Період запитань"
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Період запитань та відповідей"
            return "Період уточнень"
        case "transferPriorityPeriod":
            return "Період передачі переважного права"
        case "active_auction":
            if (procedure.isLAE(type) || procedure.isLAW(type) || procedure.isLAP(type)) return "Період аукціону"
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Період аукціону"
            return "Період аукціону"
        case "active_qualification":
            if (procedure.isLAE(type) || procedure.isLAW(type) || procedure.isLAP(type)) return "Очікується оприлюднення протоколу"
            if (procedure.isLPE(type)) return "Період кваліфікації"
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Період кваліфікації"
            if (procedure.isREM(type)) return "Очікується оприлюднення протоколу та підписання договору"
            return "Очікується публікація протоколу"
        case "active_awarded":
            if (procedure.isLAE(type) || procedure.isLAW(type)) return "Очікується підписання акту/договору"
            if (procedure.isLAP(type)) return "Період підписання акту"
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Період кваліфікації"
            return "Очікується підписання договору"

        case "pending_admission":
            return "Період публікації рішення про викуп"
        case "auctionEnd":
            return "Завершення торгів"
        case "pending_payment":
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Очікується оплата"
            return getAuctionsStatus(type, status)
        case "cancelled":
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Аукціон скасовано"
            return getAuctionsStatus(type, status)
        case "unsuccessful":
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Аукціон не відбувся"
            return getAuctionsStatus(type, status)
        case "complete":
            if (procedure.isAPE(type) || procedure.isAPD(type)) return "Аукціон завершено. Акт/договір підписано"
            return getAuctionsStatus(type, status)
        default:
            return getAuctionsStatus(type, status)
    }
}