import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'
import TextFull from '../../elements/viewData/textFull'
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import procedure from "../../../redux/procedure";

const BanksGroupe = (props) => {

    if (props.auctionId !== null) {
        switch (true) {
            //@todo enable for all??
            case props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english"):
            case props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
            case props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            case props.auctionId.sellingMethod.startsWith("landRental-english"):
            case props.auctionId.sellingMethod.startsWith("landSell-english"):
            case props.auctionId.sellingMethod.startsWith("landSell-priorityEnglish"):
            case props.auctionId.sellingMethod.startsWith("basicSell-english"):
            case props.auctionId.sellingMethod.startsWith("basicSell-dutch"):
            case props.auctionId.sellingMethod.startsWith("commercialSell-english"):
            case props.auctionId.sellingMethod.startsWith("commercialSell-dutch"):
            case props.auctionId.sellingMethod.startsWith("bankRuptcy-english"):
            case props.auctionId.sellingMethod.startsWith("bankRuptcy-dutch"):
            case props.auctionId.sellingMethod.startsWith("bankRuptcy-withoutAuction"):
            case props.auctionId.sellingMethod.startsWith("regulationsPropertyLease-english"):
            case props.auctionId.sellingMethod.startsWith("regulationsPropertyLease-dutch"):
            case props.auctionId.sellingMethod.startsWith("commercialPropertyLease-english"):
            case props.auctionId.sellingMethod.startsWith("commercialPropertyLease-dutch"):
            case props.auctionId.sellingMethod.startsWith("smallPrivatization-english"):
            case props.auctionId.sellingMethod.startsWith("smallPrivatization-dutch"):
            case props.auctionId.sellingMethod.startsWith("nonperformingLoans-english"):
            case props.auctionId.sellingMethod.startsWith("nonperformingLoans-dutch"):
            case props.auctionId.sellingMethod.startsWith("largePrivatization-english"):
            case props.auctionId.sellingMethod.startsWith("armaProperty-english"):
            case props.auctionId.sellingMethod.startsWith("armaProperty-dutch"):
            case props.auctionId.sellingMethod.startsWith("landArrested-english"):
            case props.auctionId.sellingMethod.startsWith("landArrested-withoutAuction"):
            case props.auctionId.sellingMethod.startsWith("landArrested-priorityEnglish"):
            case procedure.isCLP(props.auctionId.sellingMethod):
            case procedure.isRLP(props.auctionId.sellingMethod):
                if (
                    props.auctionId.privateData.isOwner === true ||
                    props.auctionId.status === 'active_qualification' ||
                    props.auctionId.status === 'active_awarded' ||
                    props.auctionId.status === 'pending_payment' ||
                    props.auctionId.status === 'pending_admission' ||
                    props.auctionId.status === 'cancelled' ||
                    props.auctionId.status === 'unsuccessful' ||
                    props.auctionId.status === 'complete'
                ) {
                    return (
                        <SlimmerBlock
                            title={`Інформація про Банківські рахунки`}
                            content={viewBanksAccounts(props)}
                        />
                    )
                }
                break;

            case props.auctionId.sellingMethod.startsWith("renewables-multiAwards"):
                return (
                    <SlimmerBlock
                        title={`Інформація щодо банківської гарантії`}
                        content={viewBankGuaranteeDetails(props)}
                    />
                )
        }
    }
    return null
}

function viewBanksAccounts(props) {
    if (props.auctionId.hasOwnProperty('bankAccounts') === true) {
        return props.auctionId.bankAccounts.map(
            (e) => {
                return (
                    <>
                        <TextHalf
                            title='Тип банківських рахунків'
                            data={strType(e.accountType)}
                        />
                        {viewBankPercent(e)}
                        {viewBankHolderType(e)}
                        {mapAccounts(e)}
                    </>
                )
            }
        )
    }
}

function viewBankGuaranteeDetails(props) {
    if (props.auctionId.hasOwnProperty('bankGuaranteeDetails') === true) {
        return (
            <>
                <TextFull
                    title='Інформація щодо банківської гарантії *'
                    data={props.auctionId.bankGuaranteeDetails.uk_UA}
                />
            </>
        )
    }
}

function accountsHolder(data) {
    if (data.hasOwnProperty('accountHolder') === true && data.accountHolder !== null) {
        if (data.accountHolder.hasOwnProperty('name') === true && data.accountHolder.name !== null) {
            return (
                <>
                    <div className={styles.bankItemTitle}>Реквізити одержувача</div>
                    <TextHalf
                        title='Найменування одержувача'
                        data={strType(data.accountHolder.name.uk_UA)}
                    />
                    <TextHalf
                        title='Ідентифікатор'
                        data={strType(data.accountHolder.identifier.scheme)}
                    />
                    <TextHalf
                        title='Ідентифікатор одержувача'
                        data={strType(data.accountHolder.identifier.id)}
                    />
                    <TextHalf
                        title='Повна юридична назва організації'
                        data={strType(data.accountHolder.identifier.legalName.uk_UA)}
                    />
                </>
            )
        }
    }
    return null
}

function viewBankPercent(data) {
    if (data !== null) {
        if (data.hasOwnProperty('percent')) {
            if (data.percent !== null) {
                return (
                    <TextHalf
                        title='Відсоток'
                        data={data.percent}
                    />
                )
            }
        }
    }
    return null
}

function viewBankHolderType(data) {
    if (data !== null) {
        if (data.hasOwnProperty('holderType')) {
            if (data.holderType !== null) {
                return (
                    <TextHalf
                        title="Тип власника об'єкта"
                        data={holderType(data.holderType)}
                    />
                )
            }
        }
    }
    return null
}

function holderType(data) {
    if (data === "balanceHolder") {
        return "Балансоутримувач"
    } else if (data === "landlord") {
        return "Орендодавець"
    } else if (data === "budget") {
        return "Бюджет"
    }
    return null
}

function mapAccounts(data) {
    if (data.accounts !== null) {
        if (data.accounts.length > 0) {

            return data.accounts.map(
                (e) => {
                    let tmpDesc = null
                    if (e.hasOwnProperty('description') === true) {
                        if (e.description !== null) {
                            if (e.description.uk_UA !== null) {
                                tmpDesc = <TextFull
                                    title='Опис'
                                    data={e.description.uk_UA}
                                />
                            }
                        }
                    }
                    return (
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockTitle}>
                                <h3>Банківські рахунки</h3>
                            </div>
                            {(e.hasOwnProperty('bankName') === true && e.bankName !== null) ? <TextHalf
                                title='Назва банку'
                                data={e.bankName.uk_UA}
                            /> : null}
                            <TextHalf
                                title='Валюта'
                                data={strCurrency(e.currency)}
                            />
                            {tmpDesc}
                            {mapAccountIdentifications(e.accountIdentifications)}

                            {accountsHolder(e)}
                        </div>
                    )
                }
            )
        }
    }
    return null
}

function mapAccountIdentifications(data) {
    if (data !== null) {
        if (data.length > 0) {
            return data.map(
                (e) => {
                    return (
                        <div className={styles.bankItem}>
                            <div className={styles.bankItemTitle}>
                                Банківські реквізити
                            </div>
                            <div className={styles.bankItemContent}>
                                {data.hasOwnProperty('description') === true ? <TextHalf
                                    title='Опис до банківських реквізитів'
                                    data={e.description.uk_UA}
                                /> : null}
                                <TextHalf
                                    title={`Ідентифікатор: ${e.scheme}`}
                                    data={e.id}
                                />
                            </div>
                        </div>
                    )
                }
            )
        }
    }
    return null
}

function strType(data) {
    switch (data) {
        case 'registrationFee':
            return 'Реєстраційний внесок'
        case 'guarantee':
            return 'Гарантійний внесок'
        case 'securityDeposit':
            return 'Забезпечувальний депозит'
        case 'improvementCompensation':
            return "Компенсація невід’ємних поліпшень"
        case 'other':
            return 'Інші платежі'
        case 'advancePayment':
            return 'Авансовий внесок'
        case 'lease':
            return 'Орендні платежі'
        case 'payment':
            return 'Плата за лот'
        case 'preparationPayment':
            return 'Плата за підготовку лота'
        case 'organizer':
            return 'Організатор'
        default:
            return data
    }
}

function strCurrency(data) {
    switch (data) {
        case 'UAH':
            return 'гривні'
        case 'USD':
            return 'долари'
        case 'EUR':
            return 'євро'
        default:
            return data
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(BanksGroupe)
