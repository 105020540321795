import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import ProcedureStartValue from '../../../../sidebarRightViewProcedureStartValue';
import ProcedureImage from '../../../../sidebarRightViewProcedureImage'
import ProcedureType from '../../../../sidebarRightViewProcedureType'
import ProcedureStatus from '../../../../sidebarRightViewProcedureStatus'
import DateBlock from '../../../../sidebarRightViewProcedureDate'
import ProcedurePriorityEnglish from '../../../../sidebarRightViewProcedureLinkPriorityEnglish'
import ProcedureComplite from '../../../../sidebarRightViewProcedureComplite'

const SidebarRightBlockOld = () => {
    return (
        <div className={styles.sideBlock}>
            <ProcedureStartValue />
            <ProcedureImage />
            <ProcedureType />
            <ProcedureStatus />
            <DateBlock />
            <ProcedurePriorityEnglish />
            <ProcedureComplite />
        </div>
    )
}

export default connect(null, null)(SidebarRightBlockOld)
