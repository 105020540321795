import React from 'react';
import style from './popupContractAppruve.module.css';

import { connect } from 'react-redux';

import {
  auctionsGetAwards,
  auctionsGetAwardsId,
  changeAwardRejectPopUpStatus,
  changeAwardErrorPopUpStatus,
  changeAwardRejectTitle,
  changeAwardRejectDescription,
  changeAwardRejectDocumentType
} from '../../../../redux/actions/awards/awards.js';

import {
  changeContractApprowePopUpStatus,
  changeContractErrorPopUpStatus,
  setContractTitle,
  setContractDescription,
  setContractContractNumber,
  setContractDateFrom,
  setContractDateTill,
  setContractStartDate,
  setContractEndDate,
  setContractCurrency,
  setContractAmount,
  setContractDateSigned,
  setContractDocTitle,
  setContractDocType,
  setContractDocDescription,
  setContractDatePaid,
  setContractContractTimeAddedDateFrom,
  setContractContractTimeAddedDateTill
} from '../../../../redux/actions/contracts/contracts.js';

import { setUniversalError, changeLoader } from '../../../../redux/actions.js';

import { contractsAuctionIdGet } from '../../../../redux/actions/contracts/contracts.js';

import {
  togetherApiPopUpOnOff,
  togetherApiDocTitle,
  togetherApiDocDescription,
  togetherApiDocType,
  togetherApiDocLang,
  togetherApiDocFileName,
  togetherApiECP
} from '../../../../redux/togetherApi.js';
import { ecpApiPass, ecpApiList, changeCA } from '../../../../redux/ecpApi.js';

import {
  changeDocumentId,
  changeDocumentTitle,
  changeDocumentDescription,
  changeDocumentLang,
  changeDocumentFileName,
  changeDocumentType
} from '../../../../redux/actions/documents/documents.js';

import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputDate from '../../../elements/inputs/inputDate';

import { setDataAddress } from '../../../../redux/createAddress';
import { getUpdateDataAuction } from '../../../product/updateData';
import {
  isTIE,
  isLLE,
  isLLD,
  isLLP,
  isLRE,
  isLSE,
  isLSP,
  isBSE,
  isBSD,
  isALE,
  isCSE,
  isCSD,
  isBRE,
  isBRD,
  isBRW,
  isSPE,
  isSPD,
  isLPE
} from '../../../../redux/procedure';


//Очиска полей при закрытии попапа

const ContractOther = (props) => {
  if (props.timberContractPut.title !== '' && props.auctions.status !== 'signed') {
    approweContract(props);
  }
  return (
    <>
      <div className={style.wrapMain}>
        <div className={style.wrap}>
          <div
            className={style.close}
            onClick={() => {
              props.setContractTitle('');
              props.setContractDescription('');
              props.setContractContractNumber('');
              props.setContractDateFrom('');
              props.setContractDateTill('');
              props.setContractContractTimeAddedDateFrom('');
              props.setContractContractTimeAddedDateTill('');
              props.setContractStartDate('');
              props.setContractEndDate('');
              props.setContractCurrency(null);
              props.setContractAmount(null);
              props.setContractDateSigned('');
              props.setContractDocTitle('');
              props.setContractDocType('null');
              props.setContractDocDescription('');
              props.changeContractApprowePopUpStatus(null);
            }}
          >
            ✕
          </div>

          <div className={style.popup_main_doc}>
            <div className={style.dataContracts}>
              <div className={style.dataContractsTitle}>Дані договору</div>
              {viewDataContract(props)}
            </div>
          </div>
          <div className={style.bottomPart}></div>
        </div>
      </div>
    </>
  );
};

/////////////////////////////////////////
function viewDataContract(props) {
  let tmpButton = null;
  if (props.auctions.saleCondition === 'yes') {
    if (
      props.timberContractPut.title !== '' &&
      props.timberContractPut.description !== '' &&
      props.timberContractPut.contractNumber !== '' &&
      props.timberContractPut.contractTime.dateFrom !== '' &&
      props.timberContractPut.contractTime.dateTill !== '' &&
      props.timberContractPut.contractTotalValue.amount !== null &&
      props.timberContractPut.dateSigned !== ''
    ) {
      if (props.auctions.privateData.isOwner) {
        tmpButton = (
          <div
            className={style.btnDone}
            onClick={() => {
              updateContractData(props);
            }}
          >
            Зберегти
          </div>
        );
      }
    }
  } else {
    if (
      props.timberContractPut.title !== '' &&
      props.timberContractPut.description !== '' &&
      props.timberContractPut.contractNumber !== '' &&
      props.timberContractPut.contractTotalValue.amount !== null &&
      props.timberContractPut.dateSigned !== ''
    ) {
      if (props.auctions.privateData.isOwner) {
        tmpButton = (
          <div
            className={style.btnDone}
            onClick={() => {
              updateContractData(props);
            }}
          >
            Зберегти
          </div>
        );
      }
    }
  }

  let tmpContractTitle;
  if (props.timberContractPut.title === '' || props.timberContractPut.title === null) {
    tmpContractTitle = (
      <InputText
        value={props.timberContractPut.title.uk_UA ?? props.timberContractPut.title}
        label='Назва договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_TITLE')}
        reqiured={true}
      />
    );
  } else {
    tmpContractTitle = (
      <InputText
        value={props.timberContractPut.title.uk_UA ?? props.timberContractPut.title}
        label='Назва договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_TITLE')}
        reqiured={true}
      />
    );
  }
  let tmpContractDescription;
  if (props.timberContractPut.description === '' || props.timberContractPut.description === null) {
    tmpContractDescription = (
      <TextArea
        value={props.timberContractPut.description.uk_UA ?? props.timberContractPut.description}
        label='Опис договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_DESCRIPTION')}
        reqiured={true}
      />
    );
  } else {
    tmpContractDescription = (
      <TextArea
        value={props.timberContractPut.description.uk_UA ?? props.timberContractPut.description}
        label='Опис договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_DESCRIPTION')}
        reqiured={true}
      />
    );
  }
  let tmpContractNumber;
  if (props.timberContractPut.contractNumber === '' || props.timberContractPut.contractNumber === null) {
    tmpContractNumber = (
      <InputText
        value={props.timberContractPut.contractNumber}
        label='Номер договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_CONTRACT_NUMBER')}
        reqiured={true}
      />
    );
  } else {
    tmpContractNumber = (
      <InputText
        value={props.timberContractPut.contractNumber}
        label='Номер договору'
        onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_CONTRACT_NUMBER')}
        reqiured={true}
      />
    );
  }

  let tmpContractTimeStartDate = null;
  let dataContractTimeStartDate = {};
  dataContractTimeStartDate.targetType = 'SET_CONTRACT_START_DATE';
  dataContractTimeStartDate.target = props.timberContractPut.contractTime.dateFrom;
  dataContractTimeStartDate.typePopUp = 'contracts';
  dataContractTimeStartDate.functions = props.setContractStartDate;
  dataContractTimeStartDate.label = `Строк виконання умов приватизації Період з`;
  if (
    isBSE(props.popUpContractApprowe.sellingMethod) ||
    isBSD(props.popUpContractApprowe.sellingMethod) ||
    isALE(props.popUpContractApprowe.sellingMethod) ||
    isCSE(props.popUpContractApprowe.sellingMethod) ||
    isCSD(props.popUpContractApprowe.sellingMethod) ||
    isBRE(props.popUpContractApprowe.sellingMethod) ||
    isBRD(props.popUpContractApprowe.sellingMethod) ||
    isBRW(props.popUpContractApprowe.sellingMethod)
  ) {
    dataContractTimeStartDate.necessarily = false;
  } else {
    dataContractTimeStartDate.necessarily = true;
  }
  dataContractTimeStartDate.time = true;
  if (!isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod)) {
    if (
      props.timberContractPut.contractTime.dateFrom === '' ||
      props.timberContractPut.contractTime.dateFrom === null
    ) {
      tmpContractTimeStartDate = (
        <div className={style.groupElem}>
          <InputDate
            data={{
              label: dataContractTimeStartDate.label,
              target: dataContractTimeStartDate.target,
              reqiured: dataContractTimeStartDate.necessarily,
              time: dataContractTimeStartDate.time
            }}
            onChange={(e) => {
              props.setContractStartDate(e);
            }}
          />
        </div>
      );
    } else {
      let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime();
      let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime();
      if (tmpTimerStart < tmpTimerSigned) {
        tmpContractTimeStartDate = (
          <div className={style.groupElem}>
            <InputDate
              data={{
                label: dataContractTimeStartDate.label,
                target: dataContractTimeStartDate.target,
                reqiured: dataContractTimeStartDate.necessarily,
                time: dataContractTimeStartDate.time
              }}
              onChange={(e) => {
                props.setContractStartDate(e);
              }}
            />
          </div>
        );
      } else {
        tmpContractTimeStartDate = (
          <div className={style.groupElem}>
            <InputDate
              data={{
                label: dataContractTimeStartDate.label,
                target: dataContractTimeStartDate.target,
                reqiured: dataContractTimeStartDate.necessarily,
                time: dataContractTimeStartDate.time
              }}
              onChange={(e) => {
                props.setContractStartDate(e);
              }}
            />
          </div>
        );
      }
    }
  }
  let tmpContractTimeEndDate = null;
  let dataContractTimeEndDate = {};
  dataContractTimeEndDate.targetType = 'SET_CONTRACT_END_DATE';
  dataContractTimeEndDate.target = props.timberContractPut.contractTime.dateTill;
  dataContractTimeEndDate.typePopUp = 'contracts';
  dataContractTimeEndDate.functions = props.setContractEndDate;
  dataContractTimeEndDate.label = `Строк виконання умов приватизації Період по`;
  if (
    isBSE(props.popUpContractApprowe.sellingMethod) ||
    isBSD(props.popUpContractApprowe.sellingMethod) ||
    isALE(props.popUpContractApprowe.sellingMethod) ||
    isCSE(props.popUpContractApprowe.sellingMethod) ||
    isCSD(props.popUpContractApprowe.sellingMethod) ||
    isBRE(props.popUpContractApprowe.sellingMethod) ||
    isBRD(props.popUpContractApprowe.sellingMethod) ||
    isBRW(props.popUpContractApprowe.sellingMethod) ||
    isSPE(props.popUpContractApprowe.sellingMethod) ||
    isSPD(props.popUpContractApprowe.sellingMethod) ||
    isLPE(props.popUpContractApprowe.sellingMethod)
  ) {
    dataContractTimeEndDate.necessarily = false;
  } else {
    dataContractTimeEndDate.necessarily = true;
  }
  dataContractTimeEndDate.time = true;
  if (!isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod)) {
    if (
      props.timberContractPut.contractTime.dateTill === '' ||
      props.timberContractPut.contractTime.dateTill === null
    ) {
      tmpContractTimeEndDate = (
        <div className={style.groupElem}>
          <InputDate
            data={{
              target: props.timberContractPut.contractTime.dateTill,
              label: dataContractTimeEndDate.label,
              reqiured: dataContractTimeEndDate.necessarily,
              time: dataContractTimeEndDate.time
            }}
            onChange={(e) => {
              props.setContractEndDate(e);
            }}
          />
        </div>
      );
    } else {
      let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime();
      let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime();
      if (tmpTimerStart > tmpTimerEnd) {
        tmpContractTimeEndDate = (
          <div className={style.groupElem}>
            <InputDate
              data={{
                target: props.timberContractPut.contractTime.dateTill,
                label: dataContractTimeEndDate.label,
                reqiured: dataContractTimeEndDate.necessarily,
                time: dataContractTimeEndDate.time
              }}
              onChange={(e) => {
                props.setContractEndDate(e);
              }}
            />
          </div>
        );
      } else {
        tmpContractTimeEndDate = (
          <div className={style.groupElem}>
            <InputDate
              data={{
                target: props.timberContractPut.contractTime.dateTill,
                label: dataContractTimeEndDate.label,
                reqiured: dataContractTimeEndDate.necessarily,
                time: dataContractTimeEndDate.time
              }}
              onChange={(e) => {
                props.setContractEndDate(e);
              }}
            />
          </div>
        );
      }
    }
  }

  let tmpContractTimeAddedDateFrom;
  let dataContractTimeAddedFromDate = {
    target: props.timberContractPut.contractTimeAdded.dateFrom,
    label: `Строк дії договору Період з`,
    reqiured: true,
    time: true
  };
  tmpContractTimeAddedDateFrom = (
    <div className={style.groupElem}>
      <InputDate
        data={dataContractTimeAddedFromDate}
        onChange={(e) => {
          props.setContractContractTimeAddedDateFrom(e);
        }}
      />
    </div>
  );
  let tmpContractTimeAddedDateTill;
  let dataContractTimeAddedTillDate = {
    target: props.timberContractPut.contractTimeAdded.dateTill,
    label: `Строк дії договору Період по`,
    reqiured: true,
    time: true
  };
  tmpContractTimeAddedDateTill = (
    <div className={style.groupElem}>
      <InputDate
        data={dataContractTimeAddedTillDate}
        onChange={(e) => {
          props.setContractContractTimeAddedDateTill(e);
        }}
      />
    </div>
  );

  let tmpContractTimeDateFrom = null;
  let dataContractTimeFromDate = {
    target: props.timberContractPut.contractTime.dateFrom,
    label: `Дата передплати`,
    reqiured: true,
    time: true
  };
  if (
    !isLLE(props.popUpContractApprowe.sellingMethod) &&
    !isLLD(props.popUpContractApprowe.sellingMethod) &&
    !isLLP(props.popUpContractApprowe.sellingMethod)
  ) {
    if (
      props.timberContractPut.contractTime.dateFrom === '' ||
      props.timberContractPut.contractTime.dateFrom === null
    ) {
      tmpContractTimeDateFrom = (
        <div className={style.groupElem}>
          <InputDate
            data={dataContractTimeFromDate}
            onChange={(e) => {
              props.setContractDateFrom(e);
            }}
          />
        </div>
      );
    } else {
      let tmpTimerFrom = new Date(props.timberContractPut.contractTime.dateFrom).getTime();
      let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime();
      let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime();
      if (tmpTimerFrom < tmpTimerSigned || tmpTimerEnd < tmpTimerFrom) {
        tmpContractTimeDateFrom = (
          <div className={style.dateTill}>
            <InputDate
              data={dataContractTimeFromDate}
              onChange={(e) => {
                props.setContractDateFrom(e);
              }}
            />
          </div>
        );
      } else {
        tmpContractTimeDateFrom = (
          <div className={style.groupElem}>
            <InputDate
              data={dataContractTimeFromDate}
              onChange={(e) => {
                props.setContractDateFrom(e);
              }}
            />
          </div>
        );
      }
    }
  }
  let tmpContractTimeDateSigned;
  let dataContractTimeSignedDate = {
    target: props.timberContractPut.dateSigned,
    label: `Дата підписання договору`,
    reqiured: true,
    time: true
  };
  if (props.timberContractPut.dateSigned === '' || props.timberContractPut.dateSigned === null) {
    tmpContractTimeDateSigned = (
      <div className={style.groupElem}>
        <InputDate
          data={dataContractTimeSignedDate}
          onChange={(e) => {
            props.setContractDateSigned(e);
          }}
        />
      </div>
    );
  } else {
    tmpContractTimeDateSigned = (
      <div className={style.groupElem}>
        <InputDate
          data={dataContractTimeSignedDate}
          onChange={(e) => {
            props.setContractDateSigned(e);
          }}
        />
      </div>
    );
  }

  let dataContractSumm = {
    target: props.timberContractPut.contractTotalValue.amount,
    label: `Заключна вартість договору`,
    reqiured: true
  };
  let tmpDogovorSumm = (
    <InputText
      value={dataContractSumm.target}
      label={dataContractSumm.label}
      onChange={(e) => props.setDataAddress(e, 'SET_CONTRACT_TOTAL_AMOUNT')}
      reqiured={dataContractSumm.reqiured}
    />
  );

  let dataContractValue = {
    targetType: 'SET_CONTRACT_VALUE_AMOUNT',
    target: props.timberContractPut.value.amount,
    functions: props.setContractAmount,
    label: `Вартість договору`,
    reqiured: false,
    disabledElem: true
  };
  let tmpDogovorValue = (
    <InputText
      value={dataContractValue.target}
      label={dataContractValue.label}
      onChange={(e) => props.setDataAddress(e, dataContractValue.targetType)}
      reqiured={dataContractValue.reqiured}
      disabled={dataContractValue.disabledElem}
    />
  );
  //let tmpDogovorValue = null

  let tmpForm;
  if (!isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod)) {
    tmpForm = (
      <div className={style.formLine}>
        <form
          className={style.formData}
          onSubmit={(e) => {
            handleSubmitDataContract(e, props);
          }}
          id='addFileForm'
        >
          {tmpContractTitle}
          {tmpContractDescription}
          <div className={style.baseWrap}>
            <div className={style.baseWrapHalf}>
              {tmpContractNumber}
              {tmpDogovorValue}
              {tmpDogovorSumm}
              {tmpContractTimeDateSigned}
            </div>
            <div className={style.baseWrapHalf}>
              {tmpContractTimeStartDate}
              {tmpContractTimeEndDate}
              {tmpContractTimeAddedDateFrom}
              {tmpContractTimeAddedDateTill}
            </div>
          </div>
          {tmpButton}
        </form>
      </div>
    );
  } else {
    tmpForm = (
      <div className={style.formLine}>
        <form
          className={style.formData}
          onSubmit={(e) => {
            handleSubmitDataContract(e, props);
          }}
          id='addFileForm'
        >
          {tmpContractTitle}
          {tmpContractDescription}
          <div className={style.baseWrap}>
            <div className={style.baseWrapHalf}>
              {tmpContractNumber}
              {tmpContractTimeDateSigned}
            </div>
            <div className={style.baseWrapHalf}>
              {tmpDogovorSumm}
              {tmpContractTimeDateFrom}
            </div>
          </div>
          {tmpButton}
        </form>
      </div>
    );
  }
  return tmpForm;
}

function approweContract(props) {
  if (props.popUpContractApprowe !== null) {
    switch (props.popUpContractApprowe.sellingMethod) {
      case 'subsoil-english':
      case 'subsoil-english-fast':
      case 'subsoil-english-fast-manual':
      case 'subsoil-english-initial-auction':
      case 'subsoil-english-initial-qualification':
        return (
          <div
            className={style.btnDone}
            onClick={() => {
              signedContract(props);
            }}
          >
            Підтвердити договір
          </div>
        );
    }
  }
}

async function signedContract(props) {
  await props.changeLoader(true);
  const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/approve/set_status/signed`;
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: props.token.access_token,
      'Content-Type': 'application/json'
    }
  }).catch(console.error);
  if (response === undefined || response === 'undefined') {
  } else {
    const json = await response.json();
    if (json.hasOwnProperty('message') === true) {
      let tmp = JSON.stringify({ title: 'Помилка підпису контракту', message: JSON.stringify(json) });
      props.setUniversalError(tmp);
      await props.changeLoader(false);
    } else {
      await getUpdateDataAuction(props, props.auctions.id);
      await props.changeLoader(false);
    }
  }
}

function handleSubmitDataContract(e, props) {
  e.preventDefault();
  updateContractData(props);
}

async function updateContractData(props) {
  await props.changeLoader(true);
  const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`;
  let body = {};
  body.title = props.timberContractPut.title;
  body.description = props.timberContractPut.description;
  body.contractNumber = props.timberContractPut.contractNumber;
  if (
    //Правка для запуска леса если ок убрать 03022022
    !isTIE(props.popUpContractApprowe.sellingMethod) &&
    !isLRE(props.popUpContractApprowe.sellingMethod) &&
    !isLSE(props.popUpContractApprowe.sellingMethod) &&
    !isLSP(props.popUpContractApprowe.sellingMethod) &&
    !isLLE(props.popUpContractApprowe.sellingMethod) &&
    !isLLD(props.popUpContractApprowe.sellingMethod) &&
    !isLLP(props.popUpContractApprowe.sellingMethod) &&
    !isBSE(props.popUpContractApprowe.sellingMethod) &&
    !isBSD(props.popUpContractApprowe.sellingMethod) &&
    !isALE(props.popUpContractApprowe.sellingMethod) &&
    !isCSE(props.popUpContractApprowe.sellingMethod) &&
    !isCSD(props.popUpContractApprowe.sellingMethod) &&
    !isBRE(props.popUpContractApprowe.sellingMethod) &&
    !isBRD(props.popUpContractApprowe.sellingMethod) &&
    !isBRW(props.popUpContractApprowe.sellingMethod) &&
    !isSPE(props.popUpContractApprowe.sellingMethod) &&
    !isSPD(props.popUpContractApprowe.sellingMethod) &&
    !isLPE(props.popUpContractApprowe.sellingMethod)
  ) {
    body.x_prepaymentDetailsDate = props.timberContractPut.contractTime.dateFrom;
    if (props.timberContractPut.xValueUAH.amount.indexOf(',') !== -1) {
      props.timberContractPut.xValueUAH.amount.replace(',', '.');
    } else {
    }
    body.x_prepaymentDetailsValue = {
      currency: 'UAH',
      amount: Number(props.timberContractPut.xValueUAH.stramount)
    };
  } else {
    if (
      isTIE(props.popUpContractApprowe.sellingMethod) || //Правка для запуска леса если ок убрать 03022022
      isBSE(props.popUpContractApprowe.sellingMethod) ||
      isBSD(props.popUpContractApprowe.sellingMethod) ||
      isALE(props.popUpContractApprowe.sellingMethod) ||
      isCSE(props.popUpContractApprowe.sellingMethod) ||
      isCSD(props.popUpContractApprowe.sellingMethod) ||
      isBRE(props.popUpContractApprowe.sellingMethod) ||
      isBRD(props.popUpContractApprowe.sellingMethod) ||
      isBRW(props.popUpContractApprowe.sellingMethod)
    ) {
      let stramount;
      if (
        props.timberContractPut.contractTotalValue !== null &&
        props.timberContractPut.contractTotalValue.hasOwnProperty('amount') &&
        props.timberContractPut.contractTotalValue.amount !== null
      ) {
        if (String(props.timberContractPut.contractTotalValue.amount).indexOf(',') !== -1) {
          stramount = String(props.timberContractPut.contractTotalValue.amount).replace(',', '.');
        } else {
          stramount = props.timberContractPut.contractTotalValue.amount;
        }
      } else {
        stramount = props.timberContractPut.contractTotalValue.amount;
      }
      body.contractTotalValue = {
        currency: 'UAH',
        amount: Number(stramount)
        /*'valueAddedTaxIncluded': true*/
      };
    } else {
      let stramount;
      if (String(props.timberContractPut.contractTotalValue.amount).indexOf(',') !== -1) {
        stramount = String(props.timberContractPut.contractTotalValue.amount).replace(',', '.');
      } else {
        stramount = props.timberContractPut.contractTotalValue.amount;
      }
      body.contractTotalValue = {
        currency: 'UAH',
        amount: Number(stramount)
      };
    }
  }
  if (props.auctions.saleCondition === 'yes') {
    if (
      props.timberContractPut.contractTime.dateFrom !== '' ||
      props.timberContractPut.contractTime.dateFrom !== null ||
      props.timberContractPut.contractTime.dateTill !== '' ||
      props.timberContractPut.contractTime.dateTill !== null
    ) {
      if (
        props.timberContractPut.contractTime.dateFrom !== '' &&
        props.timberContractPut.contractTime.dateFrom !== null
      ) {
        body.contractTime = {};
        body.contractTime.dateFrom = props.timberContractPut.contractTime.dateFrom;
      }
      if (
        props.timberContractPut.contractTime.dateTill !== '' &&
        props.timberContractPut.contractTime.dateTill !== null
      ) {
        if (body.hasOwnProperty('contractTime' !== true)) body.contractTime = {};
        body.contractTime.dateTill = props.timberContractPut.contractTime.dateTill;
      }
    }
  } else {
    if (
      (props.timberContractPut.contractTime.dateFrom !== '' ||
        props.timberContractPut.contractTime.dateFrom !== null) &&
      (props.timberContractPut.contractTime.dateTill !== '' || props.timberContractPut.contractTime.dateTill !== null)
    ) {
      body.contractTime = {};
      body.contractTime.dateFrom =
        props.timberContractPut.contractTime.dateFrom !== null && props.timberContractPut.contractTime.dateFrom !== ''
          ? props.timberContractPut.contractTime.dateFrom
          : null;
      body.contractTime.dateTill =
        props.timberContractPut.contractTime.dateTill !== null && props.timberContractPut.contractTime.dateTill !== ''
          ? props.timberContractPut.contractTime.dateTill
          : null;
    }
  }

  if (props.auctions.saleCondition === 'yes') {
    if (
      props.timberContractPut.contractTimeAdded.dateFrom !== '' ||
      props.timberContractPut.contractTimeAdded.dateFrom !== null ||
      props.timberContractPut.contractTimeAdded.dateTill !== '' ||
      props.timberContractPut.contractTimeAdded.dateTill !== null
    ) {
      if (
        props.timberContractPut.contractTimeAdded.dateFrom !== '' &&
        props.timberContractPut.contractTimeAdded.dateFrom !== null
      ) {
        body.contractTimeAdded = {};
        body.contractTimeAdded.dateFrom = props.timberContractPut.contractTimeAdded.dateFrom;
      }
      if (
        props.timberContractPut.contractTimeAdded.dateTill !== '' &&
        props.timberContractPut.contractTimeAdded.dateTill !== null
      ) {
        if (body.hasOwnProperty('contractTimeAdded' !== true)) body.contractTimeAdded = {};
        body.contractTimeAdded.dateTill = props.timberContractPut.contractTimeAdded.dateTill;
      }
    }
  } else {
    if (
      (props.timberContractPut.contractTimeAdded.dateFrom !== '' ||
        props.timberContractPut.contractTimeAdded.dateFrom !== null) &&
      (props.timberContractPut.contractTimeAdded.dateTill !== '' ||
        props.timberContractPut.contractTimeAdded.dateTill !== null)
    ) {
      body.contractTimeAdded = {};
      body.contractTimeAdded.dateFrom =
        props.timberContractPut.contractTimeAdded.dateFrom !== null &&
        props.timberContractPut.contractTimeAdded.dateFrom !== ''
          ? props.timberContractPut.contractTimeAdded.dateFrom
          : null;
      body.contractTimeAdded.dateTill =
        props.timberContractPut.contractTimeAdded.dateTill !== null &&
        props.timberContractPut.contractTimeAdded.dateTill !== ''
          ? props.timberContractPut.contractTimeAdded.dateTill
          : null;
    }
  }

  body.dateSigned = props.timberContractPut.dateSigned;
  const response = await fetch(endPoint, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      Authorization: props.token.access_token,
      'Content-Type': 'application/json'
    }
  }).catch(console.error);
  if (response === undefined || response === 'undefined') {
  } else {
    const json = await response.json();
    if (json.hasOwnProperty('message') === true) {
      let tmp = JSON.stringify({ title: 'Помилка оновлення данних контракту', message: `${json.message.message}` });
      if (json.message.hasOwnProperty('x_prepaymentDetails') === true) {
        if (json.message.x_prepaymentDetails.hasOwnProperty('date') === true) {
          if (
            json.message.x_prepaymentDetails.date ===
            'Value should be greater than auctionPeriod.endDate and less than now'
          ) {
            tmp = JSON.stringify({
              title: 'Помилка оновлення данних контракту',
              message: 'Дата контракту не правильно виставлена'
            });
          }
        }
      }
      props.setUniversalError(tmp);
      await props.changeLoader(false);
    } else {
      //Добавить очистку полей//
      props.setContractTitle('');
      props.setContractDescription('');
      props.setContractContractNumber('');
      props.setContractDateFrom('');
      props.setContractDateTill('');
      props.setContractStartDate('');
      props.setContractEndDate('');
      props.setContractCurrency(null);
      props.setContractAmount(null);
      props.setContractDateSigned('');
      props.changeContractApprowePopUpStatus(null);

      await getUpdateDataAuction(props, props.auctions.id);
      await props.changeLoader(false);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.start.token,
    //auctions: state.start.auctionsGet,
    auctions: state.start.auctionFull,
    langFlag: state.start.langFlag,
    createAuctionTab: state.start.createAuctionTab,
    createAuction: state.start.createAuction,
    createItems: state.start.createItems,
    auctionsMy: state.start.auctionsMy,
    auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
    classificationsGets: state.start.classificationsGet,
    unitsGet: state.start.unitsGet,
    userProfiles: state.start.userProfiles,
    classificationsTimber: state.start.classificationsTimber,
    classificationsEnergy: state.start.classificationsEnergy,
    auctionsList: state.start.auctionsGetMy,
    auctionAwards: state.start.auctionAwards,
    popUpAwardReject: state.start.popUpAwardReject,
    awardReject: state.start.awardReject,
    auction: state.start.auctionsGetId,
    contractAuctionIdGet: state.start.contractAuctionIdGet,
    popUpContractApprowe: state.start.popUpContractApprowe,
    popUpContractUniversal: state.start.popUpContractUniversal,
    timberContractPut: state.start.timberContractPut,

    togetherApiPage: state.start.togetherApiPage,
    ecpData: state.start.ecpData,

    createDocuments: state.start.createDocuments
  };
};

const mapDispatchToProps = {
  auctionsGetAwards,
  auctionsGetAwardsId,
  changeAwardRejectPopUpStatus,
  changeAwardErrorPopUpStatus,
  changeAwardRejectTitle,
  changeAwardRejectDescription,
  changeAwardRejectDocumentType,

  setUniversalError,
  changeContractApprowePopUpStatus,

  setContractDocTitle,
  setContractDocType,
  setContractDocDescription,

  changeContractErrorPopUpStatus,

  setContractTitle,
  setContractDescription,
  setContractContractNumber,
  setContractDateFrom,
  setContractDateTill,
  setContractStartDate,
  setContractEndDate,
  setContractCurrency,
  setContractAmount,
  setContractDateSigned,

  contractsAuctionIdGet,

  togetherApiPopUpOnOff,
  togetherApiDocTitle,
  togetherApiDocDescription,
  togetherApiDocType,
  togetherApiDocLang,
  togetherApiDocFileName,
  togetherApiECP,

  ecpApiPass,
  ecpApiList,
  changeCA,

  changeDocumentId,
  changeDocumentTitle,
  changeDocumentDescription,
  changeDocumentLang,
  changeDocumentFileName,
  changeDocumentType,

  changeLoader,

  setContractDatePaid,
  setDataAddress,

  setContractContractTimeAddedDateFrom,
  setContractContractTimeAddedDateTill
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractOther);
