import React, { useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmountBreBrd';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';
import BankAccount from '../../bankAccount/bankAccount';

import { disabledElement } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils'; 
import { createBody } from '../lib/createBody';
import { setTenderAttemptListBREBRD } from '../lib/utils';

import {editAuc, createAuc, editAucAwardPatch} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import LocationOrg from '../../../../elems/localization/locationOrg';

const AuctionBRW = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const [auctionBank] = useState(null)


    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : null)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    // const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    // const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    // const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    // const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount,'discountPercent') ? data.discount.discountPercent : null)
    // const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount,'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    // const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    // const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    // const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(
        checkProp(data, 'valueAddedTaxCharged')
        ? data.valueAddedTaxCharged === true
            ? data.valueAddedTaxCharged : false
        : false
    )
    //const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)

    const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    const [isTaxInvoiceProvided, setIsTaxInvoiceProvided] = useState(
        checkProp(data, 'isTaxInvoiceProvided')
        ? data.isTaxInvoiceProvided !== null
            ? data.isTaxInvoiceProvided : false
        : false
    )
    const [economicCourtName, setEconomicCourtName] = useState(checkProp(data, 'economicCourtName') ? isMongo(data.economicCourtName) : null)
    const [bankruptcyCaseNumber, setBankruptcyCaseNumber] = useState(checkProp(data, 'bankruptcyCaseNumber') ? isMongo(data.bankruptcyCaseNumber) : null)
    // const [sellingMethodType, setSellingMethodType] = useState(checkProp(data, 'sellingMethodType') ? isMongo(data.sellingMethodType) : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])

    const [debtorInformationName, setDebtorInformationName] = useState((checkProp(data, 'debtorInformation') &&  checkProp(data.debtorInformation, 'name')) ? isMongo(data.debtorInformation.name) : null)
    const [representativeInfo] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'representativeInfo')) ? data.debtorInformation.representativeInfo : null)
    
    const [contactPointName, setContactPointName] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'name')) ? isMongo(data.debtorInformation.contactPoint.name) : null)
    const [contactPointEmail, setContactPointEmail] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'email')) ? data.debtorInformation.contactPoint.email : null)
    const [contactPointTelephone, setContactPointTelephone] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'telephone')) ? data.debtorInformation.contactPoint.telephone : null)
    const [contactPointFaxNumber, setContactPointFaxNumber] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'faxNumber')) ? data.debtorInformation.contactPoint.faxNumber : null)
    const [contactPointUrl, setContactPointUrl] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'url')) ? data.debtorInformation.contactPoint.url : null)

    const [identifierLegalName, setIdentifierLegalName] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'legalName')) ? isMongo(data.debtorInformation.identifier.legalName) : null)
    const [identifierScheme, setIdentifierScheme] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'scheme')) ? data.debtorInformation.identifier.scheme : null)
    const [identifierId, setIdentifierId] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'id')) ? data.debtorInformation.identifier.id : null)

    const [address, setAddress] = useState(
        (checkProp(data, 'debtorInformation')
        && checkProp(data.debtorInformation, 'address')
    ) ? data.debtorInformation.address : null)








    const [representativeCertificateNumber, setRepresentativeCertificateNumber] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'representativeCertificateNumber')) ? data.sellingEntity.representativeCertificateNumber : null)
    const [representativeCertificateDate, setRepresentativeCertificateDate] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'representativeCertificateDate')) ? data.sellingEntity.representativeCertificateDate : null)

    const [titleAward, setTitleAward] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'title')) ? isMongo(data.awards[0].title) : null)
    const [descriptionAwards, setDescriptionAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'description')) ? isMongo(data.awards[0].description) : null)
    //const [statusAwards, setStatusAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'status')) ? data.awards[0].status : null)
    
    const [valueAwards, setValueAwards] = useState(
        (checkProp(data, 'awards') && checkProp(data.awards[0], 'value'))
        ? data.awards[0].value
        : null)
    
    // const [, setNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'name')) ? isMongo(data.awards[0].buyers[0].name) : null)
    const [identifierLegalNameAwards, setIdentifierLegalNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'legalName')) ? isMongo(data.awards[0].buyers[0].identifier.legalName) : null)
    const [identifierSchemeAwards, setIdentifierSchemeAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'scheme')) ? data.awards[0].buyers[0].identifier.scheme : null)
    const [identifierIdAwards, setIdentifierIdAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'id')) ? data.awards[0].buyers[0].identifier.id : null)
    
    const [addressAwards, setAddressAwards] = useState(
        (checkProp(data, 'awards')
        && checkProp(data.awards[0], 'buyers')
        && checkProp(data.awards[0].buyers[0], 'address')
    ) ? data.awards[0].buyers[0].address : null)
    const [contactPointNameAwards, setContactPointNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'name')) ? isMongo(data.awards[0].buyers[0].contactPoint.name) : null)
    const [contactPointEmailAwards, setContactPointEmailAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'email')) ? data.awards[0].buyers[0].contactPoint.email : null)
    const [contactPointTelephoneAwards, setContactPointTelephoneAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'telephone')) ? data.awards[0].buyers[0].contactPoint.telephone : null)
    const [contactPointFaxNumberAwards, setContactPointFaxNumberAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'faxNumber')) ? data.awards[0].buyers[0].contactPoint.faxNumber : null)
    const [contactPointUrlAwards, setContactPointUrlAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'url')) ? data.awards[0].buyers[0].contactPoint.url : null)
    const [contactRepresentativeInfoAwards, setContactRepresentativeInfoAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'representativeInfo')) ? data.awards[0].buyers[0].representativeInfo : null)
    

    useEffect(() => {
        if(checkProp(data, 'awards') && checkProp(data.awards[0], 'value')){
            setValueAwards(data.awards[0].value)
        }
    }, [])

    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: accessDetails === null || accessDetails === ''
            ? null
            : {uk_UA: accessDetails},
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        /*auctionPeriod: {
            startDate: startDate
        },*/
        sellingMethod: data.sellingMethod,
        // x_documentRequirements: {
        //     uk_UA: x_documentRequirements
        // },
        x_additionalInformation: x_additionalInformation === null || x_additionalInformation === ""
            ? null
            : {uk_UA: x_additionalInformation},
        // minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        /*minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },*/
        previousAuctionId: previousAuctionId,
        // discount: {
        //     discount: discount,
        //     discountPercent: discountPercent,
        //     previousAuctionValue:{
        //         currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
        //         amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
        //         valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
        //     }
        // },
        bankAccounts: bankAccounts,
        restrictions: restrictions === null || restrictions === ''
            ? null
            : {
                uk_UA: restrictions
            },
        isTaxInvoiceProvided: isTaxInvoiceProvided,
        economicCourtName: {
            uk_UA: economicCourtName
        },
        bankruptcyCaseNumber: {
            uk_UA: bankruptcyCaseNumber
        },
        // sellingMethodType: {
        //     uk_UA: sellingMethodType
        // },
        debtorInformation: {
            name: debtorInformationName,
            identifier: {
                scheme: identifierScheme,
                id: identifierId,
                legalName: {
                    uk_UA: identifierLegalName
                }
            },
            /*address: {
                countryName: {
                    uk_UA: addressCountryName
                },
                region: {
                    uk_UA: addressRegion
                },
                locality: {
                    uk_UA: addressLocality
                },
                streetAddress: {
                    uk_UA: addressStreetAddress
                },
                postalCode: addressPostalCode
            },*/
            address: address,
            representativeInfo: representativeInfo,
            contactPoint: {
                name: {
                    uk_UA: contactPointName
                },
                email: contactPointEmail,
                telephone: contactPointTelephone,
                faxNumber: contactPointFaxNumber,
                url: contactPointUrl
            }
        },
        sellingEntity: {
            representativeCertificateNumber: representativeCertificateNumber,
            representativeCertificateDate: representativeCertificateDate
        }
    }

    let requestBodyAward = {
        title: {uk_UA: titleAward},
        description: {uk_UA: descriptionAwards},
        /*status: statusAwards,*/
        value: {
            currency: checkProp(valueAwards,'currency') ? valueAwards.currency : null,
            amount: checkProp(valueAwards,'amount') ? +valueAwards.amount : null,
        },
        buyers: [{
            /*name: {
                uk_UA:identifierLegalNameAwards,
            },*/
            identifier: {
                scheme: identifierSchemeAwards,
                id: identifierIdAwards,
                legalName: {
                    uk_UA: identifierLegalNameAwards
                }
            },
            address: addressAwards,
            representativeInfo: contactRepresentativeInfoAwards,
            contactPoint: {
                name: {
                    uk_UA: contactPointNameAwards
                },
                email: contactPointEmailAwards,
                telephone: contactPointTelephoneAwards,
                faxNumber: contactPointFaxNumberAwards,
                url: contactPointUrlAwards
            }
        }]
    }

    function disabledInput(auction, cod, type){
        if(
            auction.status === 'complete' ||
            auction.status === 'cancelled' ||
            auction.status === 'unsuccessful'
        ){
            return true
        }
        if(
            auction.status === 'active_auction' ||
            auction.status === 'active_qualification' ||
            auction.status === 'pending_payment' ||
            auction.status === 'active_awarded'
        ){
            if(!type){
                return false
            }else{
                return true
            }
        }
        return disabledElement(auction, cod)
    }

    function disabledInputAward(auction){
        if(
            auction.status === 'complete' ||
            auction.status === 'cancelled' ||
            auction.status === 'unsuccessful'
        ){
            return true
        }
        return false
    }


    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{
                                setPreviousAuctionId(e)
                            }}
                            disabled={disabledInput(auction, '01', true)}
                            required
                        />
                    </div>
                    {/*
                        discount 
                        ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                                required
                                disabled={disabledInput(auction, '01', true)}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true,
                                        auctionType: 'brd'
                                    }}
                                    onChange={(e) => {
                                        setPreviousAuctionValue(e)
                                        if(discount !== null
                                            && discount === true
                                            && discountPercent !== null
                                            && discountPercent !== ''
                                            && e.amount !== null
                                            && e.amount !== ''
                                        ){
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(e.amount) - (Number(e.amount)/100)*Number(discountPercent),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                            if(Number(tmpDisValue.amount) <= 20000){
                                                setRegistrationFee({registrationFee, amount: 17})
                                            }else if(Number(tmpDisValue.amount) <= 50000){
                                                setRegistrationFee({registrationFee, amount: 119})
                                            }else if(Number(tmpDisValue.amount) <= 200000){
                                                setRegistrationFee({registrationFee, amount: 340})
                                            }else if(Number(tmpDisValue.amount) <= 1000000){
                                                setRegistrationFee({registrationFee, amount: 510})
                                            }else if(Number(tmpDisValue.amount) > 1000000){
                                                setRegistrationFee({registrationFee, amount: 1700})
                                            }
                                        }
                                    }}
                                    disabled={disabledInput(auction, '01', true)}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{
                                        if(Number(e) <= 100){
                                            setDiscountPercent(e)
                                            if(discount !== null
                                                && discount === true
                                                && e !== null
                                                && e !== ''
                                                && previousAuctionValue.amount !== null
                                                && previousAuctionValue.amount !== ''
                                            ){
                                                let tmpDisValue = {
                                                    currency: value.currency,
                                                    amount: Number(previousAuctionValue.amount) - (Number(previousAuctionValue.amount)/100)*Number(e),
                                                    addTax: value.valueAddedTaxIncluded
                                                }
                                                setValue(tmpDisValue)
                                                if(Number(tmpDisValue.amount) <= 20000){
                                                    setRegistrationFee({registrationFee, amount: 17})
                                                }else if(Number(tmpDisValue.amount) <= 50000){
                                                    setRegistrationFee({registrationFee, amount: 119})
                                                }else if(Number(tmpDisValue.amount) <= 200000){
                                                    setRegistrationFee({registrationFee, amount: 340})
                                                }else if(Number(tmpDisValue.amount) <= 1000000){
                                                    setRegistrationFee({registrationFee, amount: 510})
                                                }else if(Number(tmpDisValue.amount) > 1000000){
                                                    setRegistrationFee({registrationFee, amount: 1700})
                                                }
                                            }
                                        }
                                    }}
                                    disabled={disabledInput(auction, '01', true)}
                                    required
                                />
                            </div>
                        </>
                        : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                            disabled={disabledInput(auction, '01', true)}
                        >
                            Застосувати знижку
                        </Checkbox>
                    */}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }

    async function combineUpdate(){
        let tmp
        let bodyObj = {
            base: requestBody,
        }
        if(auction.id !== null && auction.id !== ''){
            tmp = createBody(bodyObj, JSON.parse(auctionBank))
            //if(Object.keys(tmp).length !== 0){
            if(auction.status === null){
                await editAucAwardPatch(props.updateAuc, requestBodyAward)
                await editAuc(props.updateAuc, tmp)
            }else{
                await editAucAwardPatch(props.updateAuc, requestBodyAward)
                //await editAucPatch(props.updateAuc, tmp)
            }
            //}
        }else{
            tmp = createBody(bodyObj)
            createAuc(props.updateAuc, tmp)
        }
    }

    return (
        <>
            <Input //"Номер лота" 
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ 
                    setTitle(e)
                    setTitleAward(e)
                }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <TextArea 
                value={description}
                disabled={disabledInput(auction, '01', true)}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ 
                    setDescription(e)
                    setDescriptionAwards(e)
                }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.tenderAttempts.legalName, 
                    target: tenderAttempts,
                    
                    required: true,
                }}
                disabled={disabledInput(auction, '01', true)}
                onChange={(e) => { 
                        setTenderAttempts(e)
                    }
                }
                options={setTenderAttemptListBREBRD()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.accessDetails.legalName} 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Input //Обмеження"
                label='Обмеження' 
                value={restrictions}
                onChange={(e)=>{ setRestrictions(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Checkbox 
                value={isTaxInvoiceProvided}
                onChange={(e) => {
                    setIsTaxInvoiceProvided(e)
                }}
                disabled={disabledInput(auction, '01', true)}
                required
            >
                Податкова накладна надається?
            </Checkbox>
            <Input //Найменування господарського суду
                label='Найменування господарського суду' 
                value={economicCourtName}
                onChange={(e)=>{ setEconomicCourtName(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <Input //№ справи про банкрутство
                label='№ справи про банкрутство' 
                value={bankruptcyCaseNumber}
                onChange={(e)=>{ setBankruptcyCaseNumber(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про Організатора аукціону</div>
                <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                    label='№ свідоцтва про право на провадження діяльності арбітражного керуючого'
                    value={representativeCertificateNumber}
                    onChange={(e)=>{ setRepresentativeCertificateNumber(e) }}
                    required
                    disabled={disabledInput(auction, '01', true)}
                />
                <InputDate
                    data={{
                        label:'Дата видачі про право на провадження діяльності арбітражного керуючого',
                        target: representativeCertificateDate,
                        time: false
                    }}
                    onChange={ e => setRepresentativeCertificateDate(e) }
                    required
                    disabled={disabledInput(auction, '01', true)}
                />
            </div>
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про боржника</div>
                <Input //Повна юридична назва організації або ПІБ
                    label='Повна юридична назва організації або ПІБ'
                    value={identifierLegalName}
                    onChange={(e)=>{ setDebtorInformationName(e) }}
                    disabled='true'
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                    <Input //Ідентифікатори організації
                        label='Повна юридична назва організації'
                        value={identifierLegalName}
                        onChange={(e)=>{ setIdentifierLegalName(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Select 
                        data={{
                            label: 'Ідентифікатори організації',
                            target: identifierScheme,
                            
                            required: true,
                        }}
                        onChange={(e) => { setIdentifierScheme(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'ЄДРПОУ', value: 'UA-EDR'},
                            {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                            {name: 'Паспорт', value: 'UA-PASSPORT'},
                            {name: 'ID-карта', value: 'UA-ID-CARD'},
                            {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                        ]}
                        disabled={disabledInput(auction, '01', true)}
                    />
                    <Input //Код ЄДРПОУ або ІПН або паспорт
                        label='Код ЄДРПОУ або ІПН або паспорт'
                        value={identifierId}
                        onChange={(e)=>{ setIdentifierId(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                </div>
                <LocationOrg
                    address={address}
                    setAddress={setAddress}
                    dataText='Місцезнаходження боржника'
                    disabled={disabledInput(auction, '01', true)}
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={contactPointName}
                        onChange={(e)=>{ setContactPointName(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={contactPointEmail}
                        onChange={(e)=>{ setContactPointEmail(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={contactPointTelephone}
                        onChange={(e)=>{ setContactPointTelephone(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={contactPointFaxNumber}
                        onChange={(e)=>{ setContactPointFaxNumber(e) }}
                        disabled={disabledInput(auction, '01', true)}
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={contactPointUrl}
                        onChange={(e)=>{ setContactPointUrl(e) }}
                        disabled={disabledInput(auction, '01', true)}
                    />
                </div>
            </div>

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Рішення щодо кваліфікації учасників</div>
                <Input
                    label="Назва рішення щодо продажу майна зебезпеченому кредитору"
                    value={titleAward}
                    onChange={(e)=>{ setTitleAward(e) }}
                    disabled={disabledInputAward(auction, '01')}
                    required
                />
                <Input
                    label="Опис рішення щодо продажу майна зебезпеченому кредитору"
                    value={descriptionAwards}
                    onChange={(e)=>{ setDescriptionAwards(e) }}
                    disabled={disabledInputAward(auction, '01')}
                    required
                />
                {/*<Select 
                    data={{
                        label: 'Статус рішення щодо продажу майна зебезпеченому кредитору',
                        target: statusAwards,
                        
                        required: true,
                    }}
                    onChange={(e) => { setStatusAwards(e) }}
                    options={[
                        {name: 'Обрати', value: null},
                        {name: 'Очікується протокол про продаж', value: 'pending'},
                        {name: 'Очікується акт/договір про продаж майна (не обов\'язково)', value: 'active '},
                    ]}
                    disabled={disabledInput(auction, '01', true)}
                />*/}
                <div className={styles.costBlock}>
                    <InputAmount //"Гарантійний внесок"
                        title="Цінова пропозиція"
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                        data={{
                            amount: checkProp(valueAwards,'amount') ? valueAwards.amount : null,
                            currency: checkProp(valueAwards,'currency') ? valueAwards.currency : null,
                            auctionType: 'brd'
                        }}
                        onChange={(e) => { setValueAwards(e) }}
                        disabled={disabledInputAward(auction, '01')}
                    />
                </div>
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Інформація про учасника аукціону</div>
                    <Input //Повна юридична назва організації або ПІБ
                        label='Повна юридична назва організації або ПІБ'
                        value={identifierLegalNameAwards}
                        // onChange={(e)=>{ setNameAwards(e) }}
                        disabled='true'
                    />
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                        <Input //Ідентифікатори організації
                            label='Повна юридична назва організації'
                            value={identifierLegalNameAwards}
                            onChange={(e)=>{ 
                                setIdentifierLegalNameAwards(e)
                            }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                        <Select 
                            data={{
                                label: 'Ідентифікатори організації',
                                target: identifierSchemeAwards,
                                
                                required: true,
                            }}
                            onChange={(e) => { setIdentifierSchemeAwards(e) }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                {name: 'Паспорт', value: 'UA-PASSPORT'},
                                {name: 'ID-карта', value: 'UA-ID-CARD'},
                                {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                            ]}
                            disabled={disabledInputAward(auction, '01')}
                        />
                        <Input //Код ЄДРПОУ або ІПН або паспорт
                            label='Код ЄДРПОУ або ІПН або паспорт'
                            value={identifierIdAwards}
                            onChange={(e)=>{ setIdentifierIdAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                    </div>
                    <LocationOrg
                        address={addressAwards}
                        setAddress={setAddressAwards}
                        dataText='Місцезнаходження учасника'
                        disabled={disabledInputAward(auction, '01')}
                    />
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Контактна особа</div>
                        <Input //ПІБ
                            label='ПІБ'
                            value={contactPointNameAwards}
                            onChange={(e)=>{ setContactPointNameAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                        <Input //Адреса електронної пошти
                            label='Адреса електронної пошти'
                            value={contactPointEmailAwards}
                            onChange={(e)=>{ setContactPointEmailAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                        <Input //Номер телефону
                            label='Номер телефону'
                            value={contactPointTelephoneAwards}
                            onChange={(e)=>{ setContactPointTelephoneAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                        <Input //Номер факсу
                            label='Номер факсу'
                            value={contactPointFaxNumberAwards}
                            onChange={(e)=>{ setContactPointFaxNumberAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                        />
                        <Input //Веб адреса
                            label='Веб адреса'
                            value={contactPointUrlAwards}
                            onChange={(e)=>{ setContactPointUrlAwards(e) }}
                            disabled={disabledInputAward(auction, '01')}
                        />
                    </div>
                    <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                        label='Інформація щодо підтвердження повноважень'
                        value={contactRepresentativeInfoAwards}
                        onChange={(e)=>{ setContactRepresentativeInfoAwards(e) }}
                        disabled={disabledInputAward(auction, '01')}
                    />
                </div>
            </div>


            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InputAmount //"Стартова ціна лота"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(value,'amount') ? value.amount : null,
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded !== null ? value.valueAddedTaxIncluded : false : false,
                        auctionType: 'brw'
                    }}
                    onChange={(e) => {
                        setValue(e)
                        setValueAwards(e)
                        /*if(Number(e.amount) <= 20000){
                            setRegistrationFee({registrationFee, amount: 17})
                        }else if(Number(e.amount) <= 50000){
                            setRegistrationFee({registrationFee, amount: 119})
                        }else if(Number(e.amount) <= 200000){
                            setRegistrationFee({registrationFee, amount: 340})
                        }else if(Number(e.amount) <= 1000000){
                            setRegistrationFee({registrationFee, amount: 510})
                        }else if(Number(e.amount) > 1000000){
                            setRegistrationFee({registrationFee, amount: 1700})
                        }*/
                    }}
                    disabled={disabledInput(auction, '01', true)}
                    tax
                />
                {/*<InputAmount //"Гарантійний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                        auctionType: 'brd'
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    disabled={disabledInput(auction, '01', true)}
                />
                <InputAmount //"Розмір кроку аукціону"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                        auctionType: 'brd'
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={disabledInput(auction, '01', true)}
                />*/}
                {/*<InputAmount //"Реєстраційний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee,'amount') ? registrationFee.amount : null,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    disabled={disabledElement(auction, '01')}
                />*/}
                {/*<div style={{height: '20px'}}></div>*/}
                <Checkbox 
                        value={valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={disabledInput(auction, '01', true)}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>

            {/*<div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabledInput(auction, '11', true)}
                />
            </div>*/}

            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />


            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            combineUpdate()
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionBRW