import React from 'react';

import {connect} from 'react-redux';

import {isLRE, isLSE, isLSP} from '../../../../redux/procedure.js';

import TextHalf from '../../../elements/viewData/textHalf'
import {TranslationsUk} from "../../../../redux/translations.uk";

const TenderAttemptsString = (props) => {
    if (props?.auction?.tenderAttempts) {
        let procedureName = props.auction.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
        if (!TranslationsUk[props?.auction?.sellingMethodClear].hasOwnProperty(procedureName)) {
            procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
        }
        if (isLRE(props.auction.sellingMethod) || isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod)) {
            return (
                <TextHalf
                    title={TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.tenderAttempts?.legalName ?? "Порядковий номер аукціону"}
                    data={strTenderAttempts(props.auction.tenderAttempts)}
                />
            )

        }
        return (
            <TextHalf
                title={TranslationsUk[props?.auction?.sellingMethodClear][procedureName]?.tenderAttempts?.legalName ?? "Лот виставляється"}
                data={strTenderAttempts(props.auction.tenderAttempts)}
            />
        )
    }
    return null
}

function strTenderAttempts(data) {
    switch (data) {
        case 1:
            return 'Вперше'
        case 2:
            return 'Вдруге'
        case 3:
            return 'Втретє'
        case 4:
            return 'Вчетверте'
        case 5:
            return "Вп'яте"
        case 6:
            return 'Вшосте'
        case 7:
            return 'Всьоме'
        case 8:
            return 'Ввосьме'
        case 9:
            return "Вдев'яте"
        case 10:
            return "Вдесяте"
        default:
            return "Невідомо"
    }
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(TenderAttemptsString)