import React from 'react';
import styles from './contacts.module.css';

import {CONTACTS_TITLE_TOP} from '../../../model/lang/topMenu.js';
import {connect} from 'react-redux';

import SvgContacts from './contacts.svg';

const Contacts = (props) => {
    return (
        <div className={styles.wrapContact} >
            <a className={styles.korusne_link} href="https://www.dto.com.ua/helpful/presentations">
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img src={SvgContacts} alt=''/>
                        <div className={styles.korusne_title}>{CONTACTS_TITLE_TOP[props.langFlag]}</div>
                    </div>
                    <div className={styles.korusne_text}>(044) 490-11-91<br /> або напишіть нам у Viber (067) 599-11-91{/*(095) 747-08-88*/},<br /> telegram (063) 280-82-36</div>
                    <div className={styles.korusne_text}>info@dto.com.ua</div>
                </div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

export default connect(mapStateToProps, null)(Contacts)