import React from 'react';
import style from './paymentPopUp.module.css';

import {connect} from 'react-redux';

import {paymentPopup, paymentPopupCreatePdf, paymentPopupSetBank} from '../../../redux/paymentPopup.js';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {signImg} from './sign'
import {
    isLLE, isLLD, isLLP, isLRE, isLSE, isLSP,
    isBSE, isBSD, isALE, isCSE, isCSD, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isLPE, isLAE, isLAP, isAPE, isAPD, isNLD, isNLE
} from "../../../redux/procedure";
import {numberToString, textMonth} from "../../_helpers/helpers";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PaymentPopUp = (props) => {
    let tmp
    if(props.paymentPopupCreatePDF === false){
        tmp = viewDataBlock(props)
    }else{
        tmp = viewPdfBlock(props)
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.paymentPopup(null)
                            props.paymentPopupCreatePdf(false)
                            props.paymentPopupSetBank('null')
                        }
                    }
                >
                    X
                </div>
                {startPdf(props)}
                {tmp}
            </div>
        </div>
    )
}

function startPdf(props){
    if(props.paymentPopupGetData !== null){
        return (
            <div
                className={style.popUpTitle}
                onClick={
                    () => {
                        props.paymentPopupCreatePdf(true)
                    }
                }
            >
                Поповнення балансу
            </div>
        )
    }else{
        return (
            <div
                className={style.popUpTitle}
            >
                Поповнення балансу
            </div>
        )
    }
}

function viewDataBlock(props){
    let tmp = null
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        if(props.paymentPopupGetData !== null){
            tmp = toCalcSumm(props, props.paymentPopupGetData[0].bill) + toCalcSumm(props, props.paymentPopupGetData[1].bill)
        }

    }else{
        if(props.paymentPopupGetData !== null){
            tmp = toCalcSumm(props, props.paymentPopupGetData[0].bill)
        }
    }
    return (
        <>
            <div className={style.inputWrap}>
                <div>Сума поповнення</div>
                <div>
                    <input
                        type='text'
                        disabled
                        id='fixSumm'
                        value = {Number(tmp).toFixed(2)}
                    />
                </div>
            </div>
            <div className={style.inputWrap}>
                <div>Обрати банк</div>
                <div>
                    {inputBank(props)}
                </div>
            </div>
            <div
                className={style.popup_message_button}
                onClick={
                    ()=>{
                        if(props.paymentPopupGetData !== null){
                            if(
                                Number(props.paymentPopupSetBanks) === 1 ||
                                Number(props.paymentPopupSetBanks) === 2 ||
                                Number(props.paymentPopupSetBanks) === 3 ||
                                Number(props.paymentPopupSetBanks) === 4 ||
                                Number(props.paymentPopupSetBanks) === 5
                            ){
                                props.paymentPopupCreatePdf(true)
                            }
                        }
                    }
                }
            >
                Отримати рахунок
            </div>
        </>
    )
}

function inputBank(props){
    let arr = [
        <option key={0} selected default value='null'>Обрати</option>,
        <option key={1} value='1'>ПАТ КБ "ПРИВАТБАНК</option>,
        /*<option key={2} value='2'>АТ "УКРБУДІНВЕСТБАНК"</option>,*/
        <option key={3} value='3'>ПАТ "ПУМБ"</option>,
        <option key={4} value='4'>АТ "БАНК АЛЬЯНС"</option>,
        <option key={4} value='5'>АТ "КРИСТАЛБАНК"</option>
    ]
    if(
        props.auction.sellingMethod.startsWith('landRental-english')   ||
        props.auction.sellingMethod.startsWith('landSell-english')   ||
        props.auction.sellingMethod.startsWith('landSell-priorityEnglish')
    ){
        props.paymentPopupSetBank('1')
        return (
            <select
                disabled
                value={props.paymentPopupSetBanks}
                onChange={
                    (e) => {
                        props.paymentPopupSetBank(e.target.value)
                    }
                }
            >
                <option key={0} value='1'>ПАТ КБ "ПРИВАТБАНК</option>
            </select>
        )
    } else {
        if (props.paymentPopupGetData !== null) {
            if (props.paymentPopupGetData[0].bill.amount >= 100000) {
                if (props.paymentPopupSetBanks === null || props.paymentPopupSetBanks === "null") props.paymentPopupSetBank('5')
                arr = [
                    <option key={0} value='null'>Обрати</option>,
                    <option key={1} value='1'>ПАТ КБ "ПРИВАТБАНК</option>,
                    /*<option key={2} value='2'>АТ "УКРБУДІНВЕСТБАНК"</option>,*/
                    <option key={3} value='3'>ПАТ "ПУМБ"</option>,
                    <option key={4} default value='4'>АТ "БАНК АЛЬЯНС"</option>,
                    <option key={4} value='5'>АТ "КРИСТАЛБАНК"</option>
                ]
            }
        }
        return (
            <select
                value={props.paymentPopupSetBanks}
                onChange={
                    (e) => {
                        props.paymentPopupSetBank(e.target.value)
                    }
                }
            >
                {arr}
            </select>
        )
    }
}

function toCalcSumm(props, data){
    if(data !== null){
        return data.amount
    }else{
        return ''
    }
}

function viewPdfBlock(props){
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        return (
            <>
                {toCalcSumm(props, props.paymentPopupGetData[0].bill) > 0 &&
                    <div>
                        <div>Для Вас згенеровано документ на
                            суму {toCalcSumm(props, props.paymentPopupGetData[0].bill)} грн.
                            ({props.paymentPopupGetData[0].bill.billNumber})
                        </div>
                        <div
                            className={style.popup_message_button}
                            onClick={
                                () => {
                                    pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[0].bill)).download('name.pdf')
                                }
                            }
                        >
                            Завантажити
                        </div>
                    </div>
                }
                {toCalcSumm(props, props.paymentPopupGetData[1].bill) > 0 &&
                    <div>
                        <div>Для Вас згенеровано документ на
                            суму {toCalcSumm(props, props.paymentPopupGetData[1].bill)} грн.
                            ({props.paymentPopupGetData[1].bill.billNumber})
                        </div>
                        <div
                            className={style.popup_message_button}
                            onClick={
                                () => {
                                    pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[1].bill)).download('name.pdf')
                                }
                            }
                        >
                            Завантажити
                        </div>
                    </div>
                }
            </>
        )
    }else{
        return (
            <>
                <div>Для Вас згенеровано документ на суму {toCalcSumm(props, props.paymentPopupGetData[0].bill)} грн. ({props.paymentPopupGetData[0].bill.billNumber})</div>
                <div
                    className={style.popup_message_button}
                    onClick={
                        () => {
                            pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[0])).download('name.pdf')
                        }
                    }
                >
                    Завантажити
                </div>
            </>
        )
    }
}

function getFistElemJournalPDF(props, data){
    let tmp = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        content: [
            endJournal(props, data)
        ]
    }
    return tmp
}

function bankText(props){
    if(
        props.auction.sellingMethod === 'landRental-english' ||
        props.auction.sellingMethod === 'landRental-english-fast' ||
        props.auction.sellingMethod === 'landRental-english-fast-manual' ||
        props.auction.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landRental-english-initial-auction' ||
        props.auction.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auction.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auction.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auction.sellingMethod === 'landSell-english' ||
        props.auction.sellingMethod === 'landSell-english-fast' ||
        props.auction.sellingMethod === 'landSell-english-fast-manual' ||
        props.auction.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landSell-english-initial-auction' ||
        props.auction.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auction.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auction.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        return 'Банк: п/р UA503052990000026001016228049 в ПАТ КБ «ПРИВАТБАНК»'
    }else{
        if(Number(props.paymentPopupSetBanks) === 1){
            return 'Банк: п/р UA443052990000026007006219493 в ПАТ КБ «ПРИВАТБАНК»'
        }else if(Number(props.paymentPopupSetBanks) === 2){
            return 'Банк: п/р UA693803770000026007100660571 в АТ «УКРБУДІНВЕСТБАНК»'
        }else if(Number(props.paymentPopupSetBanks) === 3){
            return 'Банк: п/р UA593348510000000002600226265 в ПАТ «ПУМБ»'
        }else if(Number(props.paymentPopupSetBanks) === 4){
            return 'Банк: п/р UA703001190000026007112431002 в АТ "БАНК АЛЬЯНС"'
        }else if(Number(props.paymentPopupSetBanks) === 5){
            return 'Банк: п/р UA433390500000026005001136864 в АТ "КРИСТАЛБАНК"'
        }
    }
}

function dateText(){
    let tmp = new Date();
    return 'від «' + tmp.getDate() + '» ' + textMonth(tmp.getMonth()) + ' ' + tmp.getFullYear() + ' р.'
}

function dateTextEnd(){
    let tmp = new Date();
    let tmpNew = Date.now(tmp) + ((((1000 * 60) * 60) * 24) * 10)
    let tmpEndDate = new Date(tmpNew)
    let day = tmpEndDate.getDate()
    let month = tmpEndDate.getMonth() + 1
    if(Number(day) < 10) day = '0' + day
    if(Number(month) < 10) month = '0' + month
    return day + '.' + month + '.' + tmpEndDate.getFullYear() + ' р.'
}

///////////////////////////////////////////////
//Страница
///////////////////////////////////////////////
function endJournal(props, data){
    let tmpDogovor
    let tmpDogovorDodatok
    if(data !== null){
        if(
            isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
            isLRE(props.auction.sellingMethod) ||
            isALE(props.auction.sellingMethod) ||
            isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
            isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
            isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
            isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
            isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
            isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
            isLPE(props.auction.sellingMethod) ||
            isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
            isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
            isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
        ){
            tmpDogovor = 'Додаток № ' + data.billNumber;
            tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ' + data.agreementNumber;
        }else{
            tmpDogovor = 'Додаток № ' + data.bill.billNumber;
            tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ' + data.bill.agreementNumber;
        }
    }else{
        tmpDogovor = 'Додаток № ';
        tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ';
    }
    let tmpTypeDoc = ''
    let tmpHelpDoc = ''
    let tmpHelpDoc2 = ''
    let tmpHelpDoc3 = ''
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        switch (data.type) {
            case 1:
                tmpTypeDoc = `Оплата гарантійного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 2:
                tmpTypeDoc = `Оплата реєстраційного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 3:
                tmpTypeDoc = `Гарантійний внесок згідно з додатком № ${data.billNumber}`
                tmpHelpDoc = 'В призначенні платежу обов\'язково вказати номер додатку: ' + data.billNumber
                tmpHelpDoc2 = 'Оплата зараховується тільки від зазначеного в цьому додатку Учасника'
                tmpHelpDoc3 = 'Увага! Будь-яка особа, що сплачує за цим рахунком та реквізитами, вказаними у ньому, цим підтверджує, що вона діє в інтересах та за рахунок особи, зазначеної в цьому рахунку як «Учасник». Сплачені за цим рахунком грошові кошти будуть зараховані ТОВ «Держзакупівлі.Онлайн» на користь особи, зазначеної у цьому додатку в якості «Учасника»'
                break
            default:
                console.error("WRONG BILL TYPE")
        }
    }else{
        let type = data?.type ?? data?.bill?.type;
        switch (type) {
            case 1:
                tmpTypeDoc = `Оплата гарантійного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.bill.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 2:
                tmpTypeDoc = `Оплата реєстраційного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.bill.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 3:
                tmpTypeDoc = `Гарантійний внесок згідно з додатком № ${data.bill.billNumber}`
                tmpHelpDoc = 'В призначенні платежу обов\'язково вказати номер додатку: ' + data.bill.billNumber
                tmpHelpDoc2 = 'Оплата зараховується тільки від зазначеного в цьому додатку Учасника'
                tmpHelpDoc3 = 'Увага! Будь-яка особа, що сплачує за цим рахунком та реквізитами, вказаними у ньому, цим підтверджує, що вона діє в інтересах та за рахунок особи, зазначеної в цьому рахунку як «Учасник». Сплачені за цим рахунком грошові кошти будуть зараховані ТОВ «Держзакупівлі.Онлайн» на користь особи, зазначеної у цьому додатку в якості «Учасника»'
                break
            default:
                console.error("WRONG BILL TYPE")
        }
    }

    let tmpbillNumber
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        tmpbillNumber = data.billNumber
    }else{
        tmpbillNumber = data.bill.billNumber
    }

    let tmp = [
        {
            text: tmpDogovor,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpDogovorDodatok,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },


        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Отримувач платежу'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ТОВ "Держзакупівлі.Онлайн"'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ЄДРПОУ: 39008321'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Київ, вул. Воздвиженська, 56, 04071'
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: bankText(props)
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Платник податку на прибуток підприємств на загальних підставах'
                },
            ]
        },

        /*{
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Платник ПДВ. ІПН: 390083226562'
                },
            ]
        },*/
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ' '
                },
                {
                    width: '*',
                    fontSize:9,
                    text: ' '
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Учасник'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: props.userProfiles.organization.name
                },
            ]
        },

        {
            text: 'Рахунок-фактура № ' +  tmpbillNumber,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },

        {
            table: {
                width: [50, 350, 50, 100, 100],
                border:0,
                body: [
                    [
                        '№',
                        {
                            alignment:'center',
                            text: 'Назва'
                        },
                        'Кількість',
                        'Ціна без ПДВ',
                        'Сума без ПДВ'
                    ],
                    ['1', tmpTypeDoc, '1', toCalcSummDown(props, data), toCalcSummDown(props, data)]
                ]
            }
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Разом без ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: toCalcSummDown(props, data)
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: '0.00'
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Всього:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: toCalcSummDown(props, data)
                },
            ]
        },
        {
            text: numberToString(toCalcSummDown(props, data)),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: 'Рахунок дійсний до сплати до ' + dateTextEnd(),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            columns: [
                {
                    width: 215,
                    text: 'Директор ТОВ "Держзакупівлі.Онлайн"',
                    margin:[5, 50, 5, 5],
                },
                {
                    image: signImg(),
                    width: 200,
                    heighy: 150,
                },
                {
                    width: '*',
                    text: 'В.В.Кольга',
                    margin:[5, 50, 5, 5],
                },
            ]
        },
        {
            text: tmpHelpDoc,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc2,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc3,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
    ];
    return tmp
}

function toCalcSummDown(props, data){
    let tmpAmount = 0
    if(data !== null){
        if(
            isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
            isLRE(props.auction.sellingMethod) ||
            isALE(props.auction.sellingMethod) ||
            isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
            isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
            isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
            isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
            isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
            isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
            isLPE(props.auction.sellingMethod) ||
            isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
            isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
            isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
        ){
            tmpAmount = data.amount
        }else{
            tmpAmount = data.bill.amount
        }
        if(String(tmpAmount).indexOf('.') === true){
            return tmpAmount
        }else if(String(tmpAmount).indexOf(',') === true){
            return Number(tmpAmount).toFixed(2)
        }else{
            return Number(tmpAmount).toFixed(2)
        }
    }else{
        return ''
    }
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        paymentPopup: state.start.paymentPopup,
        paymentPopupCreatePDF: state.start.paymentPopupCreatePDF,
        paymentPopupGetData: state.start.paymentPopupGetData,
        paymentPopupSetBanks: state.start.paymentPopupSetBanks,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    paymentPopup,
    paymentPopupCreatePdf,
    paymentPopupSetBank,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopUp)