import React from 'react';
import styles from './procedurePeriodsBlock.module.css';

import {connect} from 'react-redux';

import {paymentPopup, paymentPopup1, paymentPopupGetData} from "../../../../../../redux/paymentPopup";
import {setUniversalError} from "../../../../../../redux/actions";
import procedure from "../../../../../../redux/procedure";
import {getDefaultHeaders} from "../../../../../_api/bidApi";

const ProcedureButtonsBlock = (props) => {
    let buttonsPay = props.auctionId.hasOwnProperty('registrationFee') ?
        viewBalanceRegistration(props) : viewBalanceGuarantee(props);
    return (
        <div>
            {buttonsPay}
            {viewIsFinAuth(props)}
            {buttonsAdmin(props)}
            {buttonsSign(props)}
        </div>
    )
}

function buttonsAdmin(props) {
    if (props.userProfiles !== null && props.auctionId !== null) {
        if (props.userProfiles.hasOwnProperty('isAdmin') === true) {
            if (props.userProfiles.isAdmin) {
                return (<div className={styles.balance}>
                    <a
                        className={styles.balanceBtn + ' ' + styles.Btn}
                        href={`${process.env.REACT_APP_END_POINT_BD}/uk/admin/app/baseauction/${props.auctionId.id}/show`}
                    >Модерація аукціону</a>
                </div>)
            }
        }
    }
    return null
}

function buttonsSign(props) {
    if (props.token === null && (props.auctionId.status === 'active_auction' || props.auctionId.status === 'active_tendering')) {
        return (<a
            href="https://www.dto.com.ua/registration/sellers?referer=true"
        >
            <div className={styles.balance + ' ' + styles.balanceBtn1}>
                <span>Зареєструйтесь та візьміть участь в аукціоні</span><br/>
                <span>або авторизуйтеся, якщо ви вже є учасником</span>
            </div>
        </a>)
    }
    return null
}

//////////////////////////////////////////////////
function viewBalanceGuarantee(props) {
    if (procedure.isSPD(props.auctionId.sellingMethod) || procedure.isREM(props.auctionId.sellingMethod)) return null
    if (props.userProfiles !== null) {
        if (props.userProfiles.canCreateBid === true) {
            return (<div className={styles.balance}>
                <h2>Поповнити баланс</h2>
                <p>На Вашому балансі {startCostAuction(props.userProfiles.balance)} грн.</p>
                <div
                    className={styles.balanceBtn + ' ' + styles.Btn}
                    onClick={() => {
                        getInvoiceData(props, 'guarantee')
                        props.paymentPopup(props.auctionId)
                    }}
                >Сформувати рахунки
                </div>
            </div>)
        }
    }
    return null
}

function startCostAuction(data) {
    let tmp = null
    let amount = null
    if (data !== null) {
        amount = String(data);
        if (amount !== null) {
            if (amount.indexOf('.') !== -1) {
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            } else {
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'
            }
        }
    }
    return tmp + ',' + amount
}

function viewBalanceRegistration(props) {
    if (props.userProfiles !== null) {
        if (props.userProfiles.canCreateBid === true) {
            return (<div className={styles.balance}>
                <h2>Поповнити баланс</h2>
                <p>На Вашому балансі {props.userProfiles.balance} грн.</p>
                <p>Сформуйте рахунки</p>
                <div
                    className={styles.balanceBtn + ' ' + styles.Btn}
                    onClick={() => {
                        getInvoiceData(props)
                        props.paymentPopup(props.auctionId)
                    }}
                >Сформувати рахунки
                </div>
            </div>)
        }
    }
    return null
}

async function getInvoiceData(props) {
    let endPoint
    switch (true) {
        case props.auctionId.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionId.sellingMethod.startsWith("railwayCargo-dutch"):
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/registration`;
            break
        default:
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/guarantee`

    }
    const response = await fetch(endPoint, {
        method: "GET", headers: getDefaultHeaders(props)
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
        console.error('response error!')
        //@todo refactor
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true && json.hasOwnProperty('bill') === false) {
            let tmp = JSON.stringify({title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        } else {
            let tmpArr = []
            tmpArr[0] = json
            if (props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") || props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") || props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish") || props.auctionId.sellingMethod.startsWith("landRental-english") || props.auctionId.sellingMethod.startsWith("landSell-english") || props.auctionId.sellingMethod.startsWith("landSell-priorityEnglish") || props.auctionId.sellingMethod.startsWith("timber-english") ||

                procedure.isBSE(props.auctionId.sellingMethod) || procedure.isBSD(props.auctionId.sellingMethod) ||
                procedure.isALE(props.auctionId.sellingMethod) || procedure.isCSE(props.auctionId.sellingMethod) ||
                procedure.isCSD(props.auctionId.sellingMethod) || procedure.isRLE(props.auctionId.sellingMethod) ||
                procedure.isRLD(props.auctionId.sellingMethod) || procedure.isCLE(props.auctionId.sellingMethod) ||
                procedure.isCLD(props.auctionId.sellingMethod) || procedure.isSPE(props.auctionId.sellingMethod) ||
                procedure.isSPD(props.auctionId.sellingMethod) || procedure.isLPE(props.auctionId.sellingMethod) ||
                procedure.isLAE(props.auctionId.sellingMethod) || procedure.isLAP(props.auctionId.sellingMethod) ||
                procedure.isAPE(props.auctionId.sellingMethod) || procedure.isAPD(props.auctionId.sellingMethod) ||
                procedure.isNLE(props.auctionId.sellingMethod) || procedure.isNLD(props.auctionId.sellingMethod)) {
                let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/registration`;
                const response2 = await fetch(endPoint2, {
                    method: "GET", headers: getDefaultHeaders(props)
                }).catch(console.error)
                if (response2 === undefined || response2 === 'undefined') {

                } else {
                    const json2 = await response2.json()
                    if (json2.hasOwnProperty('message') === true && json2.hasOwnProperty('bill') === false) {
                        let tmp = JSON.stringify({
                            title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json2)
                        })
                        props.setUniversalError(tmp)
                    } else {
                        tmpArr[1] = json2
                        props.paymentPopupGetData(tmpArr)
                    }
                }
            } else {
                props.paymentPopupGetData(tmpArr)
            }
        }
    }
}

function viewIsFinAuth(props) {
    if (props.userProfiles === null) return null
    if (props.userProfiles.isFinAuth === true) return null
    if (props.userProfiles?.organization?.identifier?.scheme === "UA-IPN") {
        return (<div className={styles.balance}>
            <h2>Пройти фінансову ідентифікацію</h2>
            <div>Для авторизації кабінету Вам необхідно направити на пошту info@dto.com.ua копії паспорту та ІПН
            </div>
        </div>)
    } else {
        return (<div className={styles.balance}>
            <h2>Пройти фінансову ідентифікацію</h2>
            <div
                className={styles.balanceBtn + ' ' + styles.Btn}
                onClick={() => {
                    getIsFinAuth(props)
                    props.paymentPopup1(props.auctionId)
                }}
            >Фінансова ідентифікація
            </div>
        </div>)
    }
}

async function getIsFinAuth(props) {
    let endPoint
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/finAuth`;

    const response = await fetch(endPoint, {
        method: "GET", headers: getDefaultHeaders(props)
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {

    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true && json.hasOwnProperty('bill') === false) {
            let tmp = JSON.stringify({title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        } else {
            let tmpArr = []
            tmpArr[0] = json
            props.paymentPopupGetData(tmpArr)
        }
    }
}


const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull, token: state.start.token, userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    paymentPopup, paymentPopup1, paymentPopupGetData, setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureButtonsBlock)