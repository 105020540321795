const thumbnailApi = {
    getDocumentThumbnailLink: function getDocumentThumbnailLink(document, width, height) {
        const isDgf = document?.url?.includes('dgf')
        let serviceUrl = isDgf ?
            process.env.REACT_APP_ENDPOINT_PROZORO_THUMBNAILS.replace(/^https:\/\//, "https://dgf-"):
            process.env.REACT_APP_ENDPOINT_PROZORO_THUMBNAILS
        let url = `${serviceUrl}/${document?._ds_scope ?? 'public'}/${document._ds_id}`
        if (width) url += `&w=${width}`
        if (height) url += `&h=${height}`
        return url
    }
}

export default thumbnailApi
