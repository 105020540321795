//@deprecated
export function clearDataLot(props){
    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')


    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
    props.setDataAddress('50.44395', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress('30.51589', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    
    //Очищаем Джоин проперти
    props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')

    props.setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER')
    props.setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE')
    props.setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS')
    props.setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY')
    //props.setDataAddress('HAR', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')

    //Очищаем землю
    props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')

    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    //props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')

    //Очищаем инше
    props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    //props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')

    //Очищаем недвигу
    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')

    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')

    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
    //Очищаем авто
    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
    
    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')

    props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
}