import React from 'react';
import style from './opisanie.module.css';

import {connect} from 'react-redux';

import Question from '../../../../product/chatForm/newQuestion.js';

import Title from '../../../../product/title/title'
import Tabs from '../../../../product/tabs/tabs'
import InformationAboutTheOrganizer from '../../../../product/informationAboutTheOrganizer/informationAboutTheOrganizer'
import Answer from '../../../../product/answerLot/answer.js';
import SidebarRightBlockOld from "../sidebarRightBlock/sidebarRightBlockOld";
import procedure from "../../../../../redux/procedure";
import SidebarRightBlock from "../sidebarRightBlock/sidebarRightBlock";

const ChatTab = (props) => {
    let tmp = null;
    let dateChatStart = null
    let dateChatEnd = null
    if(props.token !== null){
        if(props.userProfiles !== null){
            if(props.userProfiles.canCreateBid === true){
                if(props.auction !== null){
                    //for(let tm = 0; tm < props.auction.auctionPeriods.length; tm++){
                        if(props.auction.questionPeriod !== null) {
                            dateChatStart = new Date(props.auction.questionPeriod.startDate)
                            dateChatEnd = new Date(props.auction.questionPeriod.endDate)
                        }
                    //}
                    let tmpStart = null
                    let tmpEnd = null
                    if(dateChatStart !== null && dateChatEnd !== null) {
                        tmpStart = dateChatStart.getTime()
                        tmpEnd = dateChatEnd.getTime()
                        if(tmpStart < Date.now() && tmpEnd > Date.now()){
                            tmp = <Question />
                        }
                    }
                }
            }
        }
    }
    if(props.auction !== null){
        return (
            <>
                <div className={style.wrapNew}>
                    <div className={style.mainBlock}>
                        <Title />
                        <div className={style.aucProperties}>
                            <Tabs />
                            <InformationAboutTheOrganizer />
                            <Answer />
                            {tmp}
                        </div>
                    </div>
                    {procedure.isREM(props.auction.sellingMethod) || procedure.isRLP(props.auction.sellingMethod) ||procedure.isCLP(props.auction.sellingMethod)
                        ?
                        <SidebarRightBlock/>
                        :
                        <SidebarRightBlockOld/>
                    }
                </div>
            </>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        userProfiles: state.start.userProfiles,
    }
}

export default connect(mapStateToProps, null)(ChatTab)
