import React,{useState, useEffect} from 'react';
import styles from './item.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS, getValues, getValuesUA } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Localization from '../../../elems/localization/localization';
import Button from '../../../../elements/buttons/button';
import MultiSelect from '../../../../elements/inputs/multiSelect/inputMultiSelect';
import Checkbox from '../../../../elements/inputs/checkBox';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';

import {clearDataLot} from '../clearDataItem'

const LandItem = ( {data, classification, ...props} ) => {
    const [intendedUse] = useState(createArrData(data));
    const [intendedUseList, setIntendedUseList] = useState([]);
    /*const [leaseRuless, setLeaseRules] = useState({
        // intendedUse: [],
        //leaseDuration: data.leaseDuration, // Строк оренди P2Y11M
        intendedUseRestrictionMethod: data.intendedUseRestrictionMethod,
        intendedUseRestrictionDescription: data.intendedUseRestrictionDescription,
        additionalLeaseConditions: data.additionalLeaseConditions,
        //subleaseAllowed: data.subleaseAllowed
    });*/
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [unit] = useState('HAR')
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAddress] = useState(checkProp(data, 'address') ? data.address : null)

    const [bankRuptcyItemType] = useState(checkProp(data, 'bankRuptcyItemType') ? data.bankRuptcyItemType : null)
    const [limitedCirculationProperty, setLimitedCirculationProperty] = useState(checkProp(data, 'limitedCirculationProperty') ? data.limitedCirculationProperty : null)
    const [limitedCirculationPropertyDescription, setLimitedCirculationPropertyDescription] = useState(checkProp(data, 'limitedCirculationPropertyDescription') ? isMongo(data.limitedCirculationPropertyDescription) : null)

    const [cadastral, setCadastral] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'cadastralNumber') ? data.itemProps.cadastralNumber : null)
    const [landArea, setLandArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landArea') ? data.itemProps.landArea : null)
    const [ownershipType, setOwnershipType] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'ownershipType') ? data.itemProps.ownershipType : null)
    // const [encumbrances, setEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'encumbrances') ? data.itemProps.encumbrances : null)
    // const [jointOwnership, setJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'jointOwnership') ? data.itemProps.jointOwnership : null)
    // const [utilitiesAvailability, setUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'utilitiesAvailability') ? data.itemProps.utilitiesAvailability : null)
    const [landIntendedUse, setLandIntendedUse] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landIntendedUse') ? data.itemProps.landIntendedUse : null)
    const [specialLandPurpose, setSpecialLandPurpose] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'specialLandPurpose') ? data.itemProps.specialLandPurpose : null)
    const [encumbrances, setEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'encumbrances') ? data.itemProps.encumbrances : null)
    const [jointOwnership, setJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'jointOwnership') ? data.itemProps.jointOwnership : null)
    const [utilitiesAvailability, setUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'utilitiesAvailability') ? data.itemProps.utilitiesAvailability : null)


    const [hasEncumbrances, setHasEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasEncumbrances') ? data.itemProps.hasEncumbrances : false)
    const [hasJointOwnership, setHasJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasJointOwnership') ? data.itemProps.hasJointOwnership : false)
    const [hasUtilitiesAvailability, setHasUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasUtilitiesAvailability') ? data.itemProps.hasUtilitiesAvailability : true)


    const [additionalClassifications, setAdditionalClassifications] = useState(checkProp(data, 'additionalClassifications') ? data.additionalClassifications : [])


    let requestBody = {
            description: {
                uk_UA: description,
            },
            classification: {
                id: classification,
                scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
                description: {
                    uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
                }
            },
            additionalClassifications: additionalClassifications,
            unit: {
                code: unit,
                name: {
                    uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
                }
            },
            quantity: +quantity,
            address: address,
            location: location,
            itemProps: {
                landArea: +landArea,
                cadastralNumber: cadastral,
                ownershipType: ownershipType,
                // jointOwnership: jointOwnership === null ? 'немає' : jointOwnership,
                // encumbrances: encumbrances === null ? 'noEncumbrances' : encumbrances,
                // utilitiesAvailability: utilitiesAvailability === null ? 'немає' : utilitiesAvailability,
                landIntendedUse: landIntendedUse,
                specialLandPurpose: specialLandPurpose,
                hasEncumbrances: hasEncumbrances === true ? true : false,
                hasJointOwnership: hasJointOwnership === true ? true : false,
                hasUtilitiesAvailability: hasUtilitiesAvailability === true ? true : false,

            },
            // commercialSellItemType: null
            // additionalClassifications: null,
            // registrationDetails: null,
            bankRuptcyItemType: bankRuptcyItemType,
            limitedCirculationProperty: limitedCirculationProperty,
            limitedCirculationPropertyDescription: {
                uk_UA: limitedCirculationPropertyDescription
            }

    }
    function fHasEncumbrances(requestBody){
        if(!hasEncumbrances) return requestBody
        requestBody.itemProps.encumbrances = encumbrances
        return requestBody
    }
    function fHasJointOwnership(requestBody){
        if(!hasJointOwnership) return requestBody
        requestBody.itemProps.jointOwnership = jointOwnership
        return requestBody
    }
    function fHasUtilitiesAvailability(requestBody){
        if(!hasUtilitiesAvailability) return requestBody
        requestBody.itemProps.utilitiesAvailability = utilitiesAvailability
        return requestBody
    }

    useEffect( () => {
        async function getintendedUseList() {
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`)
            //await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/kvtspz`)
            .then( (response) => {if (response.status == 200) return response.json()})
            .then( data => setIntendedUseList(data))
        }
        if (intendedUseList.length === 0) getintendedUseList()
    }, []);

    function createArrData(data){
        if(checkProp(data, 'additionalClassifications')){
            let tmp = data.additionalClassifications.filter(
                (e) => {
                    return e.id !== "QB23-5"
                }
            )
            .map(
                (e) => {
                    return e.id
                }
            )
            return tmp
        }
        return null
    }
    //useEffect(() => {
    //}, [])

    // if(props.auctionsMy.restrictions !== null && props.auctionsMy.restrictions !== ''){ //TODO: Обмеження з ауку всюди
    //     if(props.createItems.landRental.itemProps.encumbrances === null || props.createItems.landRental.itemProps.encumbrances === '' || props.createItems.landRental.itemProps.encumbrances === 'null'){
    //         props.setDataAddress(props.auctionsMy.restrictions, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
    //     }
    // }

    function viewButtons(props){
        if(
            props.auctionsMy.items === null ||
            props.auctionsMy.items.length === 0
        ){
            if(
                description !== null && description !== '' && landArea !== null && cadastral !== null
            ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        createItemZU(props, tmp, intendedUseList)
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
            }
        }else{
                if(
                    description !== null && description !== '' && landArea !== null && cadastral !== null
                ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        if(
                                            !checkProp(data, 'id')
                                        ){
                                            createItemZU(props, tmp, intendedUseList)
                                        }else{
                                            saveItemZU(props, tmp, data.id, intendedUseList)
                                        }
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
                }
        }
    }

    return (
        <>
            <MultiSelect
                label="Додатковий класифікатор KVTSPZ"
                value={intendedUse}
                options={intendedUseList}
                dictionary={true}
                setUpLevel={setAdditionalClassifications}
            />
            {/*{viewAdditionalClassifications()}
            <Select
                data={{
                    label: "Додатковий класифікатор KVTSPZ",
                    target: additionalClassification
                }}
                onChange={(event) => {
                    let tmp = additionalClassifications
                    tmp.push(JSON.parse(event))
                    setAdditionalClassification(JSON.parse(event))
                    //setAdditionalClassifications(null)
                    setAdditionalClassifications(tmp)
                }}
                options={addClassificationIdOptionDop(props)}
            />*/}
            <TextArea
                value={description}
                disabled={blokedInput(props)}
                label="Опис об'єкта"
                required
                onChange={(event)=>{
                                setDescription(event)
                        }}
            />

            {/*<Select
                data={{
                    label: "Тип активу",
                    target: bankRuptcyItemType,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setBankRuptcyItemType(e) }}
                options={[
                    {value: 'assets', name: 'Майно'},
                    {value: 'claimRights', name: 'Права вимоги'},
                ]}
            />*/}
            <Select
                data={{
                    label: "Обмежене в обороті майно",
                    target: limitedCirculationProperty,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setLimitedCirculationProperty(e) }}
                options={[
                    {value: true, name: 'Так'},
                    {value: false, name: 'Ні'},
                ]}
            />
            {limitedCirculationProperty ? <Input
                label="Обмеження для майна з обмеженим оборотом *"
                value={limitedCirculationPropertyDescription}
                onChange={(e)=>{ setLimitedCirculationPropertyDescription(e) }}
            /> : null}

            <div className={styles.semiTitle}>Характеристики земельної ділянки</div>
            <Input
                label="Площа ділянки, га"
                value={landArea}
                onChange={(event)=>{
                    if(Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLandArea(tmp)
                        setQuantity(tmp)
                        // props.setDataAddress(tmp, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                        // props.setDataAddress(tmp, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                    }
                }}
                disabled={blokedInput(props)}
            />
            <Input
                label="Кадастровий номер"
                value={cadastral}
                onChange={(event)=>{
                    setCadastral(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                }}
                required
                error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                descr={'1234567980:12:123:1234'}
                disabled={blokedInput(props)}
            />
            <Select
                data={{
                    label: "Оберіть тип власності",
                    target: ownershipType,
                }}
                onChange={(event) => {
                    setOwnershipType(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                }}
                options={[
                    {value: "state", name: 'Державна'},
                    {value: "private", name: 'Приватна '},
                    {value: "municipal", name: 'Комунальна'},
                    {value: "compatible", name: 'Сумісна'},
                ]}
            />
            
            <div className={styles.blokInputs}>
                <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <Checkbox 
                    value={hasEncumbrances}
                    onChange={setHasEncumbrances}
                >
                    Присутні обтяження
                </Checkbox>
                <Select
                    data={{
                        label: "Інформація про наявні обтяження",
                        target: encumbrances,
                    }}
                    onChange={(event) => {
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                    }}
                    options={[
                        //{value: "noEncumbrances", name: 'Обтяження відсутні'},
                        {value: "arrest", name: 'Арешт нерухомого майна '},
                        {value: "collateral", name: 'Податкова застава'},
                        {value: "restraintsOnAlienation", name: 'Заборона відчуження'},
                        {value: "otherEncumbrances", name: 'Інші обтяження'},
                    ]}
                    disabled={hasEncumbrances ? false : true}
                />
                {/*<Input
                    label="Інформація про наявні обтяження"
                    value={encumbrances}
                    onChange={(event)=>{
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasEncumbrances ? false : true}
                />*/}
                <Checkbox 
                    value={hasJointOwnership}
                    onChange={setHasJointOwnership}
                >
                    Наявні співвласники
                </Checkbox>
                <Input
                    label="Інформація про співвласників"
                    value={jointOwnership}
                    onChange={(event)=>{
                        setJointOwnership(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasJointOwnership ? false : true}
                />
                <Checkbox 
                    value={hasUtilitiesAvailability}
                    onChange={setHasUtilitiesAvailability}
                    disabled
                >
                    Інженерні мережі наявні
                </Checkbox>
                <Input
                    label="Інформація про інженерні мережі"
                    value={utilitiesAvailability}
                    onChange={(event)=>{
                        setUtilitiesAvailability(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                    }}
                    error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasUtilitiesAvailability ? false : true}
                    required
                />
            </div>

            <Input
                label="Поточне використання земельної ділянки"
                value={landIntendedUse}
                onChange={(event)=>{
                    setLandIntendedUse(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
                }}
                // error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                disabled={blokedInput(props)}
            />
            <Input
                label="Цільове призначення земельної ділянки"
                value={specialLandPurpose}
                onChange={(event)=>{
                    setSpecialLandPurpose(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
                }}
                required
                // error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                disabled={blokedInput(props)}
            />
            <Localization address={address} location={location} setLocation={setLocation} setAddress={setAddress} />
            {viewButtons(props)}
        </>
    )
}

function blokedInput(){

    return null
}

function redBlock(amount){
    if(amount === null || amount === '' || amount === 'null'){
        return 'error'
    }
    return null
}

async function createItemZU(props, requestBody, intendedUseList){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            await createItemCS(props, requestBody, intendedUseList)
        }
    }
}

async function saveItemZU(props, requestBody, itemId, intendedUseList){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId, intendedUseList)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationCVZU: state.start.classificationCVZU,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandItem)