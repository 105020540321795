import React,{useState} from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';
import thumbnailApi from "../_api/thumbnailsApi";

const SidebarRightViewProcedureImage = (props) => {
    const [imagePopup, setImagePopup] = useState(styles.noDisplay)
    const [img, setImg] = useState(0)

    function togglePopup(){
        if(imagePopup === styles.noDisplay) {
            setImagePopup(styles.imageWrapperBg)
        } else {
            setImagePopup(styles.noDisplay)
            setImg(0)
        }
    }

    function getIllustration(data) {
        let images = []
        if(data !== null){
            for (let key in data.documents){
                if (data.documents[key].documentType.indexOf('illustration') !== -1) {
                    if (data.documents[key]?.format?.startsWith('image') === true) {
                        images.push(data.documents[key])
                    }
                } 
            }
            return images.sort((v1, v2) => v1.index - v2.index)
        }
        return null
    }

    function procedureImage(data) {
        if(data !== null){
            let images = getIllustration(data)
            if (data.hasOwnProperty('documents') && images.length > 0) {
                function forward() {
                    if (img === images.length - 1) {
                        setImg(0)
                    } else {
                        setImg(img + 1)
                    }
                }
                
                function backward() {
                    if (img === 1) {
                        setImg(0)
                    } else {
                        setImg(img - 1)
                    }
                }
                
                return (
                    <>
                        <div className={styles.sidelotImage}
                            onClick={togglePopup}>
                            <img src={thumbnailApi.getDocumentThumbnailLink(images[0], 150, 150)}  alt={images[0]?.title?.uk_UA}/>
                        </div>
                        <div className={imagePopup}>
                                {img !== 0 ? <div className={styles.imageArrow}
                                    onClick={backward}>&#10094;</div>
                                : <div className={styles.imageArrowPlaceholder}></div>}
                            <div className={styles.imageWrapper}>
                                <div className={styles.popUpClose} onClick={togglePopup}>&times;</div>
                                <div className={styles.image}>
                                    <img src={thumbnailApi.getDocumentThumbnailLink(images[img],1200, 800)} alt={images[img]?.title?.uk_UA}/>
                                </div>
                            </div>
                                {images.length > 1 && img < images.length - 1
                                ? <div className={styles.imageArrow} onClick={forward}>&#10095;</div>
                                : <div className={styles.imageArrowPlaceholder}></div>}
                        </div>
                    </>
                )
            } else {
                return (<div className={styles.sidelotImage}></div>)
            }
        }
        return null
    }
    

    return (procedureImage(props.auctionId))
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(SidebarRightViewProcedureImage)