import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import {isGFE, isGFD, isLRE, isLSE, isLSP} from '../../../../redux/procedure.js';

import {viewStringValue} from '../../../../api/valueToString.js'
import {TranslationsUk} from "../../../../redux/translations.uk";

const ValueString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.value === null) return null
    let tmp = viewStringValue(props.auctionId.value)

    let procedureName = props.auctionId.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    if (!TranslationsUk[props?.auctionId?.sellingMethodClear].hasOwnProperty(procedureName)) {
        procedureName = String(procedureName).charAt(0).toLowerCase() + String(procedureName).slice(1);
    }

    return (
        <TextFull
            title={TranslationsUk[props?.auctionId?.sellingMethodClear][procedureName]?.value?.legalName ?? typeTitleText(props)}
            data={tmp}
        />
    )
}

function typeTitleText(props){
    if(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)){
        return 'Стартова ціна'
    }
    if(isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)) return 'Початкова ціна продажу лоту'
    return 'Стартова ціна лота'
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ValueString)