import React from 'react';
import styles from './basicSell.module.css';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { setAuctionCategory } from '../../../../redux/actions/support.js';
import { auctionsSearch, auctionsSearchCount, changeCurentPage } from '../../../../redux/actions/auctions/auctions.js';
import { changeMyCurentPage } from '../../../../redux/actions.js';
import { setDataAddress } from '../../../../redux/createAddress';

const BasicSell = ({
  langFlag,
  aucyionsPath,
  search,
  token,
  auctionsSearch,
  setAuctionCategory,
  changeCurentPage,
  changeMyCurentPage,
  setDataAddress,
  clearSearch
}) => {
  let active = null;
  if (search.category === 'basicSell-english') {
    active = styles.active;
  }
  return (
    <div className={styles.aukpoisk_second_elem}>
      <Link
        onClick={() => {
          changeMyCurentPage(0);
          setAuctionCategory('basicSell-all');
          setDataAddress('realEstate, vehicle, jointPropertyComplex, land, otherProperty', 'SEARCH_ITEM_TYPE');
          changeCurentPage(0);
          let dataSearch = {};
          dataSearch.order = search.order;
          dataSearch.region = search.region;
          dataSearch.category = 'basicSell-all';
          dataSearch.priceCurrency = search.priceCurrency;
          dataSearch.priceFrom = search.priceFrom;
          dataSearch.priceTo = search.priceTo;
          dataSearch.measures = search.measures;
          dataSearch.measuresFrom = search.measuresFrom;
          dataSearch.measuresTo = search.measuresTo;
          dataSearch.itemType = 'realEstate, vehicle, jointPropertyComplex, land, otherProperty';
          if (search.status === null || search.status === '') {
            dataSearch.status = null;
          } else {
            dataSearch.status = search.status;
          }
          let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: langFlag,
            params: '?offset=0'
          };
          if (token !== null) {
            let header = {
              Authorization: token.access_token,
              'Content-Type': 'application/json'
            };
            auctionsSearch(data, header, dataSearch);
          } else {
            let header = {
              'Content-Type': 'application/json'
            };
            auctionsSearch(data, header, dataSearch);
          }
          clearSearch();
        }}
        to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-all')}`}
      >
        <div className={styles.base + ' ' + active}>
          <svg
            version='1.1'
            id='Capa_1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 512 512'
            xmlSpace='preserve'
          >
            <g>
              <g>
                <path
                  d='M506.57,92.315L262.982,0.776c-1.552-0.776-3.879-0.776-5.43,0L5.43,92.315C2.327,93.091,0,96.194,0,99.297v72.921
			c0,3.103,2.327,6.206,4.655,6.982l11.636,4.655v224.97c0,3.103,1.552,6.206,4.655,6.982l235.83,95.418
			c1.552,0.776,3.879,0.776,5.43,0l227.297-87.661c3.103-0.776,4.655-3.879,4.655-6.982v-230.4l12.412-4.655
			c3.103-1.552,4.654-3.879,4.654-6.982V99.297c0.776,0,0.776,0,0.776,0C512,96.194,509.673,93.091,506.57,92.315z M252.121,492.606
			L31.806,403.394V190.061l220.315,84.558V492.606z M252.121,258.327L15.515,167.564v-56.63l236.606,92.315V258.327z
			 M259.879,189.285l-230.4-89.212l230.4-83.782l222.642,83.782C397.188,134.206,341.333,156.703,259.879,189.285z M479.418,411.152
			l-211.782,81.455V275.394l211.782-83.782V411.152z M496.485,168.339c-19.394,7.758-205.576,81.455-228.849,90.764v-55.855
			l228.849-92.315V168.339z'
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </div>
        <div className={styles.aukpoisk_second_elem_text}>Базовий продаж</div>
      </Link>
      <div className={styles.aukpoisk_second_elem_1}>
        <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-english')}`}>
          <div
            className={styles.subMenu}
            onClick={() => {
              changeMyCurentPage(0);
              setAuctionCategory('basicSell-english');
              setDataAddress('otherProperty', 'SEARCH_ITEM_TYPE');
              changeCurentPage(0);
              let dataSearch = {};
              dataSearch.order = search.order;
              dataSearch.region = search.region;
              dataSearch.category = 'basicSell-english';
              dataSearch.priceCurrency = search.priceCurrency;
              dataSearch.priceFrom = search.priceFrom;
              dataSearch.priceTo = search.priceTo;
              dataSearch.measures = search.measures;
              dataSearch.measuresFrom = search.measuresFrom;
              dataSearch.measuresTo = search.measuresTo;
              dataSearch.itemType = 'realEstate, vehicle, jointPropertyComplex, land, otherProperty';
              if (search.status === null || search.status === '') {
                dataSearch.status = null;
              } else {
                dataSearch.status = search.status;
              }
              let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: langFlag,
                params: '?offset=0'
              };
              if (token !== null) {
                let header = {
                  Authorization: token.access_token,
                  'Content-Type': 'application/json'
                };
                auctionsSearch(data, header, dataSearch);
              } else {
                let header = {
                  'Content-Type': 'application/json'
                };
                auctionsSearch(data, header, dataSearch);
              }
              clearSearch();
            }}
          >
            Базовий продаж. Англієць
          </div>
        </Link>
        <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-dutch')}`}>
          <div
            className={styles.subMenu}
            onClick={() => {
              changeMyCurentPage(0);
              setAuctionCategory('basicSell-dutch');
              setDataAddress('realEstate, vehicle, jointPropertyComplex, land, otherProperty', 'SEARCH_ITEM_TYPE');
              changeCurentPage(0);
              let dataSearch = {};
              dataSearch.order = search.order;
              dataSearch.region = search.region;
              dataSearch.category = 'basicSell-dutch';
              dataSearch.priceCurrency = search.priceCurrency;
              dataSearch.priceFrom = search.priceFrom;
              dataSearch.priceTo = search.priceTo;
              dataSearch.measures = search.measures;
              dataSearch.measuresFrom = search.measuresFrom;
              dataSearch.measuresTo = search.measuresTo;
              dataSearch.itemType = 'realEstate, vehicle, jointPropertyComplex, land, otherProperty';
              if (search.status === null || search.status === '') {
                dataSearch.status = null;
              } else {
                dataSearch.status = search.status;
              }
              let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: langFlag,
                params: '?offset=0'
              };
              if (token !== null) {
                let header = {
                  Authorization: token.access_token,
                  'Content-Type': 'application/json'
                };
                auctionsSearch(data, header, dataSearch);
              } else {
                let header = {
                  'Content-Type': 'application/json'
                };
                auctionsSearch(data, header, dataSearch);
              }
              clearSearch();
            }}
          >
            Базовий продаж. Голандець
          </div>
        </Link>
      </div>
    </div>
  );
};

function getUrlFiltersParam(search, type) {
  const params = new URLSearchParams();

  const mappings = {
    orderBy: 'orderBy',
    status: 'status',
    region: 'region',
    priceCurrency: 'priceCurrency',
    priceFrom: 'priceFrom',
    priceTo: 'priceTo',
    measures: 'measures',
    measuresFrom: 'measuresFrom',
    measuresTo: 'measuresTo',
    textSting: 'keyword',
    selectTypeKeyword: 'selectTypeKeyword'
  };

  Object.entries(mappings).forEach(([key, param]) => {
    if (search[key] !== null) {
      params.append(param, search[key]);
    }
  });

  if (search.itemType !== null) {
    params.append('itemType', 'realEstate, vehicle, jointPropertyComplex, land, otherProperty');
  }
  if (search.priceFromBag !== false) {
    params.append('priceFromBag', 'true');
  }
  if (search.priceToBag !== false) {
    params.append('priceToBag', 'true');
  }

  params.append('category', type);

  return `?${params.toString()}`;
}

const mapStateToProps = (state) => {
  const { langFlag, aucyionsPath, search, token, searchCount } = state.start;
  return {
    langFlag: langFlag,
    aucyionsPath: aucyionsPath,
    search: search,
    token: token,
    searchCount: searchCount
  };
};

const mapDispatchToProps = {
  auctionsSearch,
  auctionsSearchCount,
  setAuctionCategory,
  changeCurentPage,
  changeMyCurentPage,
  setDataAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicSell);
