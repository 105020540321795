import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import ProcedureStartValue from '../../../../sidebarRightViewProcedureStartValue';
import ProcedureImage from '../../../../sidebarRightViewProcedureImage'
import ProcedureType from '../../../../sidebarRightViewProcedureType'
import ProcedureStatus from '../../../../sidebarRightViewProcedureStatus'
import ProcedurePriorityEnglish from '../../../../sidebarRightViewProcedureLinkPriorityEnglish'
import ProcedureComplete from '../../../../sidebarRightViewProcedureComplite'
import ProcedureButtonsBlock from "./components/procedureButtonsBlock";
import ProcedurePeriodsBlock from "./components/procedurePeriodsBlock";

const SidebarRightBlock = () => {
    return (
        <div className={styles.sideBlock}>
            <ProcedureStartValue />{/* @todo refactor*/}
            <ProcedureImage />{/* @todo refactor*/}
            <ProcedureType />{/* @todo refactor*/}
            <ProcedureStatus />{/* @todo refactor*/}
            <ProcedurePeriodsBlock />
            <ProcedureButtonsBlock />
            <ProcedurePriorityEnglish /> {/* @todo refactor*/}
            <ProcedureComplete /> {/* @todo refactor*/}
        </div>
    )
}

export default connect(null, null)(SidebarRightBlock)
